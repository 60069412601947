import React from 'react'
import {
  TextField,
  SimpleShowLayout,
  ReferenceField,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import InventoryItemCorrelationIdTitle from './inventory-item-correlation-id-lib'

const InventoryItemCorrelationIdShowActions = ({
  basePath,
  data,
  permissions,
}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const InventoryItemCorrelationIdShow = ({permissions, ...rest}) => (
  <Show
    title={<InventoryItemCorrelationIdTitle />}
    actions={
      <InventoryItemCorrelationIdShowActions
        permissions={permissions}
        {...rest}
      />
    }
    {...rest}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        reference="inventoryItems"
        source="inventoryItemId"
        label="Inventory Item"
        link="show">
        <TextField source="serialNumber" />
      </ReferenceField>
      <ReferenceField
        label="External Id Type"
        source="correlationIdTypeId"
        reference="correlationIdTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="correlationId" label="External Id" />
    </SimpleShowLayout>
  </Show>
)

export default InventoryItemCorrelationIdShow
