import React from 'react'
import {
  ReferenceField,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
} from 'react-admin'
import {Show, DateTimeField} from '../../components'
import {withRouter} from 'react-router'

const WorkEffortTitle = ({record}) => {
  return <span>Work Effort: {record ? `${record.id}` : ''}</span>
}

const WorkEffortShow = props => {
  return (
    <Show title={<WorkEffortTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Detail">
          <ReferenceField
            source="workEffortTypeId"
            reference="workEffortTypes"
            label="Work Effort Type"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="facilityId"
            reference="facilities"
            label="Performed At"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <DateTimeField label="Started" source="startedAt" />
          <DateTimeField label="Completed" source="completedAt" />
        </Tab>
        <Tab label="Worker Assignments" path="workerAssignments">
          <ReferenceManyField
            label="Worker Assignments"
            reference="workEffortWorkerAssignments"
            sort={{field: 'workerId', order: 'ASC'}}
            target="workEffortId">
            <Datagrid>
              <ReferenceField
                source="workerId"
                reference="parties"
                label="Worker"
                link="show">
                <TextField source="fullName" />
              </ReferenceField>
              <DateTimeField label="Started" source="startedAt" />
              <DateTimeField label="Completed" source="completedAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default withRouter(WorkEffortShow)
