import React from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  DateTimeInput,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'

const typeManufacturingConfigurationId = 1
const typeServiceConfigurationId = 2

const InventoryItemConfigurationCreate = props => {
  const {
    usedInInventoryItemId: inventoryItemIdString,
    replacedInventoryItemConfigurationId:
      replacedInventoryItemConfigurationIdString,
  } = parse(props.location.search)

  const usedInInventoryItemId = inventoryItemIdString
    ? parseInt(inventoryItemIdString, 10)
    : null
  const replacedInventoryItemConfigurationId =
    replacedInventoryItemConfigurationIdString
      ? parseInt(replacedInventoryItemConfigurationIdString, 10)
      : null

  const redirectUrl = usedInInventoryItemId
    ? `/inventoryItems/${usedInInventoryItemId}/show/configuration`
    : false

  const title =
    (replacedInventoryItemConfigurationIdString ? 'Replace' : 'Create') +
    ' Inventory Item Configuration'

  const initialValues = {
    fromDate: new Date(),
    usedInInventoryItemId,
    replacedInventoryItemConfigurationId,
    inventoryItemConfigurationTypeId: replacedInventoryItemConfigurationIdString
      ? typeServiceConfigurationId
      : typeManufacturingConfigurationId,
  }

  return (
    <Create title={title} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          source="inventoryItemConfigurationTypeId"
          label="Type"
          reference="inventoryItemConfigurationTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <ReferenceInput
          reference="inventoryItems"
          source="usedInInventoryItemId"
          label="Used in Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        {replacedInventoryItemConfigurationIdString && (
          <ReferenceInput
            reference="expandedInventoryItemConfigurations"
            source="replacedInventoryItemConfigurationId"
            label="Replacement For Item"
            filter={{
              usedInInventoryItemId: initialValues.usedInInventoryItemId,
            }}
            filterToQuery={searchText => ({serialNumber: searchText})}>
            <AutocompleteInput optionText="serialNumber" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  )
}

export default InventoryItemConfigurationCreate
