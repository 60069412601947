import {Grid, IconButton} from '@material-ui/core'
import {useRecordContext} from 'ra-core'
import React from 'react'
import {TimeRange} from '../../utils/telemetry-provider'
import {TelemetryChart} from './telemetry-chart'
import {NoData} from '..'
import {Loading} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import RefreshIcon from '@material-ui/icons/Refresh'
import {Duration} from 'luxon'
import {useChannels} from '../../utils/telemetry-channels'
import {DateTime} from 'luxon'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  textField: {
    width: 220,
  },
  switchControl: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  switchLabel: {
    fontSize: '0.75rem',
  },
}))

const timeRangeOptions = [
  {label: 'Last 5 minutes', minutes: 5},
  {label: 'Last 15 minutes', minutes: 15},
  {label: 'Last 30 minutes', minutes: 30},
  {label: 'Last 1 hour', minutes: 60},
  {label: 'Last 3 hours', default: true, minutes: 3 * 60},
  {label: 'Last 6 hours', minutes: 6 * 60},
]

function TimeRangeSelect({setTimeRange}) {
  const classes = useStyles()

  const handleChange = React.useCallback(
    event => {
      const minutes = event.target.value
      const beginTimeOffset = Duration.fromObject({minutes: minutes})
      setTimeRange(prev => new TimeRange(beginTimeOffset, prev.endTime))
    },
    [setTimeRange],
  )

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="time-range-select-label">Time-range</InputLabel>
      <Select
        labelId="time-range-select-label"
        defaultValue={180}
        onChange={handleChange}
        inputProps={{'aria-label': 'Time-range'}}>
        {timeRangeOptions.map(opt => (
          <MenuItem key={`${opt.label}_opt`} value={opt.minutes}>
            {opt.default ? <em>{opt.label}</em> : opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const dateTimePickerFormat = "yyyy-MM-dd'T'T"

function EndTimeSelect({setTimeRange}) {
  const classes = useStyles()
  const [custom, setCustom] = React.useState(false)
  const [dateTime, setDateTime] = React.useState(null)

  const handleSwitchChange = React.useCallback(
    event => {
      const checked = event.target.checked
      setTimeRange(
        prev => new TimeRange(prev.beginTimeOffset, checked ? dateTime : null),
      )
      setCustom(checked)
    },
    [dateTime, setTimeRange],
  )

  const handleDateTimeChange = React.useCallback(
    event => {
      const dt = DateTime.fromFormat(
        event.target.value,
        dateTimePickerFormat,
      ).toUTC()
      setTimeRange(prev => new TimeRange(prev.beginTimeOffset, dt))
      setDateTime(dt)
    },
    [setTimeRange],
  )

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className={classes.switchControl}>
      <FormControlLabel
        className={classes.switchLabel}
        control={
          <Switch size="small" onChange={handleSwitchChange} name="now" />
        }
        label={
          <FormLabel className={classes.switchLabel}>Custom end-time</FormLabel>
        }
      />
      <TextField
        type="datetime-local"
        onChange={handleDateTimeChange}
        className={classes.textField}
        inputProps={{'aria-label': 'End-time'}}
        disabled={!custom}
      />
    </FormControl>
  )
}

const autoRefreshOptions = [
  {label: 'Off', millis: 0},
  {label: '5s', millis: 5000},
  {label: '10s', millis: 10000},
  {label: '30s', millis: 30000},
  {label: '1m', millis: 60000},
  {label: '5m', millis: 300000},
]

function AutoRefreshSelect({autoRefresh, setAutoRefresh}) {
  const classes = useStyles()
  const handleChange = event => {
    setAutoRefresh(event.target.value)
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="auto-refresh-select-label">Auto-refresh</InputLabel>
      <Select
        labelId="auto-refresh-select-label"
        value={autoRefresh}
        onChange={handleChange}
        inputProps={{'aria-label': 'Auto-refresh frequency'}}>
        {autoRefreshOptions.map(opt => (
          <MenuItem key={`${opt.label}_opt`} value={opt.millis}>
            {opt.millis === 0 ? <em>{opt.label}</em> : opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function MetricMultiSelect({channels, channelNames, toggleShow}) {
  const classes = useStyles()
  const handleChange = event => {
    const selected = event.target.value
    for (const name of channelNames) {
      if (channels[name]) {
        const show = selected.includes(name)
        if (show !== channels[name].show) {
          toggleShow(name)
          return
        }
      }
    }
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="metric-multi-select-label">Metrics</InputLabel>
      <Select
        labelId="metric-multi-select-label"
        id="metric-multi-checkbox"
        multiple
        value={channelNames.filter(name => channels[name]?.show)}
        onChange={handleChange}
        input={<Input />}
        renderValue={selected =>
          selected.map(name => channels[name].label).join(', ')
        }
        MenuProps={MenuProps}>
        {channelNames
          .filter(name => channels[name])
          .map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={channels[name].show} />
              <ListItemText primary={channels[name].label} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

function TelemetryPanel(props) {
  const {serialNumber} = useRecordContext(props)
  const {
    channels,
    loading,
    empty,
    toggleShow,
    fetchAllChannels,
    autoRefresh,
    setAutoRefresh,
    setTimeRange,
    channelNames,
  } = useChannels({
    serialNumber,
  })
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start">
            <Grid item>
              <MetricMultiSelect
                channels={channels}
                toggleShow={toggleShow}
                channelNames={channelNames}
              />
            </Grid>
            <Grid item>
              <IconButton aria-label="refresh" onClick={fetchAllChannels}>
                <RefreshIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <AutoRefreshSelect
                autoRefresh={autoRefresh}
                setAutoRefresh={setAutoRefresh}
              />
            </Grid>
            <Grid item>
              <TimeRangeSelect setTimeRange={setTimeRange} />
            </Grid>
            <Grid item>
              <EndTimeSelect setTimeRange={setTimeRange} />
            </Grid>
          </Grid>
          {empty ? (
            <NoData />
          ) : (
            <TelemetryChart channels={channels} channelNames={channelNames} />
          )}
        </div>
      )}
    </div>
  )
}

export {TelemetryPanel}
