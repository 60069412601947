import PeopleIcon from '@material-ui/icons/People'
import PartyAssociationCreate from './party-association-create'
import PartyAssociationEdit from './party-association-edit'
import PartyAssociationList from './party-association-list'
import PartyAssociationShow from './party-association-show'

const resource = {
  options: {label: 'Party Associations'},
  create: PartyAssociationCreate,
  edit: PartyAssociationEdit,
  list: PartyAssociationList,
  show: PartyAssociationShow,
  icon: PeopleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('PartyAssociation')
      ? PartyAssociationCreate
      : null,
  }
}

export default supplier
