import React from 'react'
import {
  TextField,
  ReferenceField,
  Tab,
  BooleanField,
  TabbedShowLayout,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ShowButton,
} from 'react-admin'
import {Show} from '../../components'

const PartyAssociationTypeTitle = ({record}) => {
  return <span>Party Association Type: {record ? `${record.name}` : ''}</span>
}

const PartyAssociationTypeShow = props => (
  <Show title={<PartyAssociationTypeTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <ReferenceField
          source="superPartyAssociationTypeId"
          label="Super"
          reference="partyAssociationTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="fromPartyRoleTypeId"
          label="From Role Type"
          reference="partyRoleTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="toPartyRoleTypeId"
          label="To Role Type"
          reference="partyRoleTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField label="Abstract" source="abstract" />
      </Tab>
      <Tab label="Instances">
        <ReferenceManyField
          label="Instances"
          pagination={<Pagination />}
          perPage={10}
          reference="partyAssociations"
          target="partyAssociationTypeId">
          <Datagrid>
            <TextField source="id" label="Id" />
            <ReferenceField
              label="From Role"
              source="fromPartyRoleId"
              reference="expandedPartyRoles"
              link={record => `/partyRoles/${record.fromPartyRoleId}/show`}>
              <TextField source="fullName" />
            </ReferenceField>
            <ReferenceField
              label="To Role"
              source="toPartyRoleId"
              reference="expandedPartyRoles"
              link={record => `/partyRoles/${record.toPartyRoleId}/show`}>
              <TextField source="fullName" />
            </ReferenceField>
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default PartyAssociationTypeShow
