import React from 'react'
import {TextField, SimpleShowLayout} from 'react-admin'
import {Show} from '../../components'

const WorkEffortTypeTitle = ({record}) => {
  return <span>Work Effort Type: {record ? `${record.name}` : ''}</span>
}

const WorkEffortTypeShow = props => (
  <Show title={<WorkEffortTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
    </SimpleShowLayout>
  </Show>
)

export default WorkEffortTypeShow
