import React from 'react'
import {
  TextField,
  NumberField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {TestVariableTitle} from './test-variable-lib'

const TestVariableActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const TestVariableShow = props => {
  const {permissions} = props
  return (
    <Show
      title={<TestVariableTitle />}
      actions={<TestVariableActions permissions={permissions} />}
      {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <ReferenceField
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <ReferenceField
          label="Unit Of Measure"
          source="unitOfMeasureId"
          reference="unitOfMeasures"
          link="show">
          <TextField source="symbol" />
        </ReferenceField>
        <NumberField source="minimumValue" label="Minimum Value" />
        <NumberField source="maximumValue" label="Maximum Value" />
      </SimpleShowLayout>
    </Show>
  )
}

export default TestVariableShow
