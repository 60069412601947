import React from 'react'
import {
  SimpleForm,
  ReferenceField,
  TextInput,
  TextField,
  required,
} from 'react-admin'
import InventoryItemCorrelationIdTitle from './inventory-item-correlation-id-lib'
import {Edit, EditToolbar} from '../../components'

const InventoryItemCorrelationIdEdit = ({permissions, ...rest}) => {
  return (
    <Edit title={<InventoryItemCorrelationIdTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect="show">
        <TextInput disabled source="id" label="Id" />
        <ReferenceField
          reference="inventoryItems"
          source="inventoryItemId"
          label="Inventory Item"
          link={false}>
          <TextField source="serialNumber" />
        </ReferenceField>
        <ReferenceField
          source="correlationIdTypeId"
          label="External Id Type"
          reference="correlationIdTypes"
          link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextInput
          source="correlationId"
          label="External Id"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  )
}

export default InventoryItemCorrelationIdEdit
