import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
  useEditController,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {DeploymentItemTitle} from './deployment-item-lib'

const DeploymentItemEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/deployments/${record.deploymentId}/show/items`
  return (
    <Edit title={<DeploymentItemTitle />} {...rest}>
      <SimpleForm
        redirect={redirect}
        toolbar={
          <EditToolbar
            redirect={redirect}
            permissions={permissions}
            {...rest}
          />
        }>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="deployments"
          source="deploymentId"
          label="Deployment"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}
          disabled>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          validate={required()}
          disabled>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <TextInput source="alias" label="Alias" />
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
        <TextInput source="comment" label="Comment" fullWidth={true} />
      </SimpleForm>
    </Edit>
  )
}

export default DeploymentItemEdit
