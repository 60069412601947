import * as React from 'react'
import {useState} from 'react'
import PropTypes from 'prop-types'
import {useGetIdentity} from 'ra-core'
import {IconButton, Menu, Button, Avatar} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'

const defaultIcon = <AccountCircle />

const useStyles = makeStyles(theme => ({
  user: {},
  userButton: {
    textTransform: 'none',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}))

const AnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const UserMenu = props => {
  const [anchorEl, setAnchorEl] = useState(null)
  const {loaded, identity} = useGetIdentity()
  const classes = useStyles(props)

  const {icon = defaultIcon, logout} = props

  const open = Boolean(anchorEl)

  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <div className={classes.user}>
      {loaded && identity?.fullName ? (
        <Button
          className={classes.userButton}
          color="inherit"
          startIcon={
            identity.avatar ? (
              <Avatar
                className={classes.avatar}
                src={identity.avatar}
                alt={identity.fullName}
              />
            ) : (
              icon
            )
          }
          onClick={handleMenu}>
          {identity.fullName}
        </Button>
      ) : (
        <IconButton color="inherit" onClick={handleMenu}>
          {icon}
        </IconButton>
      )}
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        // Make sure the menu is display under the button and not over the appbar
        // See https://material-ui.com/components/menus/#customized-menus
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}>
        {logout}
      </Menu>
    </div>
  )
}

UserMenu.propTypes = {
  logout: PropTypes.element,
  icon: PropTypes.node,
}

export default UserMenu
