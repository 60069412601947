import React from 'react'
import {useGetOne, Labeled} from 'react-admin'
import {useFormState} from 'react-final-form'
import {Box, Typography} from '@material-ui/core'

const DeploymentTitle = ({record}) => {
  return <span>Deployment: {record ? `${record.name}` : ''}</span>
}

function makeDeploymentName(consignee, fromDate, qualifier) {
  if (fromDate && !fromDate.getDate) {
    // convert from iso date string
    fromDate = new Date(fromDate)
  }
  const dd =
    fromDate && fromDate.getDate ? ('0' + fromDate.getDate()).slice(-2) : '00'
  const mm =
    fromDate && fromDate.getDate
      ? ('0' + (fromDate.getMonth() + 1)).slice(-2)
      : '00'
  const yyyy =
    fromDate && fromDate.getDate ? '' + fromDate.getFullYear() : '0000'
  const fromDateText = `[${dd} ${mm} ${yyyy}]`
  return consignee + ' ' + fromDateText + (qualifier ? ' ' + qualifier : '')
}

const DeploymentNameField = ({nameRef}) => {
  const {values} = useFormState()
  const {data} = useGetOne('expandedPartyRoles', values.partyRoleId, {
    enabled: false,
  })
  const consignee = data ? data.preferredName : '[Consignee]'
  const name = makeDeploymentName(consignee, values.fromDate, values.qualifier)
  nameRef.current = name
  return (
    <Box ml="0.5em">
      <Labeled label="Name">
        <Typography>{name}</Typography>
      </Labeled>
    </Box>
  )
}

export {DeploymentTitle, DeploymentNameField}
