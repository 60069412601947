import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
  FunctionField,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {TestProcedurePartCoverageTitle} from './test-procedure-part-coverage-lib'
import {fullName} from '../part/part-lib'

const TestProcedurePartCoverageActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const TestProcedurePartCoverageShow = props => {
  const {permissions} = props
  return (
    <Show
      title={<TestProcedurePartCoverageTitle />}
      actions={<TestProcedurePartCoverageActions permissions={permissions} />}
      {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <ReferenceField
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Part"
          source="partId"
          reference="parts"
          link="show">
          <FunctionField render={fullName} />
        </ReferenceField>
        <DateTimeField label="From Date" source="fromDate" />
        <DateTimeField label="To Date" source="toDate" />
      </SimpleShowLayout>
    </Show>
  )
}

export default TestProcedurePartCoverageShow
