import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
  BooleanField,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {DeploymentItemWatchTitle} from './deployment-item-watch-lib'

const DeploymentItemWatchActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const DeploymentItemWatchShow = props => {
  const {permissions} = props
  return (
    <Show
      title={<DeploymentItemWatchTitle />}
      actions={<DeploymentItemWatchActions permissions={permissions} />}
      {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <ReferenceField
          reference="expandedDeploymentItemWatches"
          source="id"
          label="Deployment"
          link="show">
          <TextField source="deploymentName" />
        </ReferenceField>
        <ReferenceField
          reference="expandedDeploymentItemWatches"
          source="id"
          label="Inventory Item"
          link="show">
          <TextField source="serialNumber" />
        </ReferenceField>
        <ReferenceField
          reference="expandedDeploymentItemWatches"
          source="id"
          label="Alias"
          link="show">
          <TextField source="alias" />
        </ReferenceField>
        <ReferenceField
          reference="expandedDeploymentItemWatches"
          source="id"
          label="Party Role"
          link="show">
          <TextField source="fullName" />
        </ReferenceField>
        <BooleanField label="Flagged" source="flagged" />
      </SimpleShowLayout>
    </Show>
  )
}

export default DeploymentItemWatchShow
