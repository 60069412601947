import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import DeploymentPartyRoleCreate from './deployment-party-role-create'
import DeploymentPartyRoleEdit from './deployment-party-role-edit'
import DeploymentPartyRoleShow from './deployment-party-role-show'

const resource = {
  options: {label: 'Deployment Coordinators'},
  create: DeploymentPartyRoleCreate,
  edit: DeploymentPartyRoleEdit,
  show: DeploymentPartyRoleShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('DeploymentPartyRole')
      ? DeploymentPartyRoleCreate
      : null,
  }
}

export default supplier
