import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import PartAssociationTypeShow from './part-association-type-show'

const resource = {
  options: {label: 'Part Association Types'},
  show: PartAssociationTypeShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
