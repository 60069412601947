import React from 'react'
import {SimpleForm, TextInput, required} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {TestProcedureTitle} from './test-procedure-lib'

const TestProcedureEdit = ({permissions, ...rest}) => {
  return (
    <Edit title={<TestProcedureTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect="show">
        <TextInput disabled source="id" label="Id" />
        <TextInput source="name" label="Name" validate={required()} />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  )
}

export default TestProcedureEdit
