import PersonIcon from '@material-ui/icons/Person'
import PartyCreate from './party-create'
import PartyEdit from './party-edit'
import PartyList from './party-list'
import PartyShow from './party-show'

const resource = {
  options: {label: 'Parties'},
  list: PartyList,
  edit: PartyEdit,
  create: PartyCreate,
  show: PartyShow,
  icon: PersonIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('Party') ? PartyCreate : null,
  }
}

export default supplier
