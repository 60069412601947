import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  TextInput,
  TopToolbar,
  ExportButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'
import ShowProductInventoryItemDeploymentStatus from '../product-inventory-item-deployment-status/show-product-inventory-item-deployment-status'

const ProductInventoryItemCountByDeploymentStatusListActions = props => (
  <TopToolbar>
    {cloneElement(props.filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const ProductInventoryItemCountByDeploymentStatusFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="deploymentStatusId"
      label="Deployment Status"
      reference="deploymentStatusTypes"
      sort={{field: 'id', order: 'ASC'}}
      alwaysOn>
      <SelectInput source="name" />
    </ReferenceInput>
    <TextInput label="Search by Part Number" source="partNumber" />
    <TextInput label="Search by Revision" source="partRevision" />
  </Filter>
)

const ProductInventoryItemCountByDeploymentStatusList = props => (
  <List
    title="Product Inventory Items Count by Deployment Status"
    filters={<ProductInventoryItemCountByDeploymentStatusFilter />}
    actions={<ProductInventoryItemCountByDeploymentStatusListActions />}
    sort={{field: 'partNumber', order: 'ASC'}}
    bulkActionButtons={false}
    {...props}>
    <Datagrid>
      <TextField source="partNumber" label="Part Number" />
      <TextField source="partRevision" label="Revision" />
      <ReferenceField
        source="deploymentStatusId"
        label="Deployment Status"
        reference="deploymentStatusTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="count" label="Count" />
      <ShowProductInventoryItemDeploymentStatus />
    </Datagrid>
  </List>
)

export default ProductInventoryItemCountByDeploymentStatusList
