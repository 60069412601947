import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  DateField,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'

const PartyAssociationTitle = ({record}) => {
  return <span>Party Association: {record ? `${record.id}` : ''}</span>
}

const PartyAssociationActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const PartyAssociationShow = ({permissions, ...rest}) => (
  <Show
    title={<PartyAssociationTitle />}
    actions={<PartyAssociationActions permissions={permissions} {...rest} />}
    {...rest}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="From Role"
        source="fromPartyRoleId"
        reference="expandedPartyRoles"
        link={record => `/partyRoles/${record.fromPartyRoleId}/show`}>
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField
        label="Type"
        source="partyAssociationTypeId"
        reference="partyAssociationTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="To Role"
        source="toPartyRoleId"
        reference="expandedPartyRoles"
        link={record => `/partyRoles/${record.toPartyRoleId}/show`}>
        <TextField source="fullName" />
      </ReferenceField>
      <DateField label="From Date" source="fromDate" />
      <DateField label="To Date" source="toDate" />
    </SimpleShowLayout>
  </Show>
)

export default PartyAssociationShow
