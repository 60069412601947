import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {PartInput} from '../part/part-lib'
import {PartCorrelationIdTypeTitle} from './part-correlation-id-type-lib'

const PartCorrelationIdTypeEdit = ({permissions, ...rest}) => (
  <Edit title={<PartCorrelationIdTypeTitle />} {...rest}>
    <SimpleForm
      toolbar={<EditToolbar permissions={permissions} {...rest} />}
      redirect="show">
      <TextInput disabled source="id" label="Id" />
      <PartInput />
      <ReferenceInput
        source="correlationIdTypeId"
        label="External Id Type"
        reference="correlationIdTypes"
        validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export default PartCorrelationIdTypeEdit
