import React from 'react'
import {
  TextField,
  DateField,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  ShowButton,
  NumberField,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {PartListField} from './../part/part-lib'
import {ProductTitle} from './product-lib'
import {withRouter} from 'react-router'

const ProductActions = ({permissions, basePath, data, location}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) && location.pathname.endsWith('show') ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const ProductShow = ({permissions, ...rest}) => (
  <Show
    title={<ProductTitle />}
    actions={<ProductActions permissions={permissions} {...rest} />}
    {...rest}>
    <TabbedShowLayout>
      <Tab label="Detail">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <DateTimeField source="introducedDate" label="Introduced" />
        <DateTimeField source="discontinuedDate" label="Discontinued" />
        <NumberField source="appNodeLevel" label="Application Node Level"/>
      </Tab>
      <Tab label="Implemented By" path="parts">
        <ReferenceManyField
          reference="productPartAssociations"
          target="productId"
          sort={{field: 'fromDate', order: 'DESC'}}
          label="Parts">
          <Datagrid>
            <PartListField label="Part" />
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Usage" path="usage">
        <ReferenceManyField
          label="Including"
          reference="productComponents"
          sort={{field: 'fromDate', order: 'DESC'}}
          target="usedInProductId">
          <Datagrid>
            <ReferenceField
              label="Product"
              source="productId"
              reference="products"
              link="show">
              <TextField source="name" />
            </ReferenceField>
            <NumberField label="Quantity" source="quantity" />
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          label="Used In"
          reference="productComponents"
          sort={{field: 'fromDate', order: 'DESC'}}
          target="productId">
          <Datagrid>
            <ReferenceField
              label="Product"
              source="usedInProductId"
              reference="products"
              link="show">
              <TextField source="name" />
            </ReferenceField>
            <NumberField label="Quantity" source="quantity" />
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default withRouter(ProductShow)
