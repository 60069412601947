import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddInventoryItemConfigurationParameterButton = props => {
  const {record} = props
  return (
    <Button
      component={Link}
      type="button"
      variant="contained"
      size="medium"
      to={`/inventoryItemConfigurationParameters/create?inventoryItemId=${record.id}`}
      label="Add config parameter"
      title="Add config parameter">
      <AddCircleIcon />
    </Button>
  )
}

export default AddInventoryItemConfigurationParameterButton
