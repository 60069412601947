import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useRecordContext,
  BooleanInput,
  ReferenceInput,
  FormDataConsumer,
} from 'react-admin'
import {Edit, EditToolbar, Error, Loading} from '../../components'
import {PartInput} from '../part/part-lib'
import {InventoryItemTitle} from './inventory-item-lib'

import {gql, useQuery} from '@apollo/client'

const GET_NEXT_STATUS_TYPES = gql`
  query q($inventoryItemId: Int) {
    inventoryItemStatusTypeForInventoryItem(inventoryitemid: $inventoryItemId) {
      nodes {
        id
        name
      }
    }
  }
`

const InventoryItemStatusTypeSelect = props => {
  const record = useRecordContext(props)
  const {loading, error, data} = useQuery(GET_NEXT_STATUS_TYPES, {
    variables: {inventoryItemId: record.id},
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loading />
  if (error) return <Error errorMessage={`${error}`} />
  return (
    <SelectInput
      source="inventoryItemStatusTypeId"
      label="Status"
      choices={data.inventoryItemStatusTypeForInventoryItem.nodes}
      validate={required()}
    />
  )
}

const InventoryItemEdit = ({permissions, ...rest}) => (
  <Edit title={<InventoryItemTitle />} {...rest}>
    <SimpleForm
      toolbar={<EditToolbar permissions={permissions} {...rest} />}
      redirect="show">
      <TextInput disabled source="id" label="Id" />
      <TextInput
        source="serialNumber"
        label="Serial Number"
        validate={required()}
      />
      <FormDataConsumer>
        {({formData, ...rest}) =>
          formData.allowStatusCorrection ? (
            <ReferenceInput
              source="inventoryItemStatusTypeId"
              label="Status"
              reference="inventoryItemStatusTypes"
              validate={required()}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          ) : (
            <InventoryItemStatusTypeSelect />
          )
        }
      </FormDataConsumer>
      <BooleanInput
        source="allowStatusCorrection"
        label="Allow Status Correction"
        defaultValue={false}
      />
      <PartInput />
      <TextInput source="comment" label="Comment" />
    </SimpleForm>
  </Edit>
)

export default InventoryItemEdit
