import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddPartyAssociationButton = props => {
  const {record} = props
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      component={Link}
      to={`/partyAssociations/create?fromPartyRoleId=${record.id}`}
      label="Add association"
      title="Add association">
      <AddCircleIcon />
    </Button>
  )
}

export default AddPartyAssociationButton
