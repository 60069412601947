import React from 'react'
import {
  SimpleForm,
  TextInput,
  DateTimeInput,
  required,
  useEditController,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {DeploymentPartyRoleTitle} from './deployment-party-role-lib'

const DeploymentPartyRoleEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/deployments/${record.deploymentId}/show/deploymentPartyRoles`
  return (
    <Edit title={<DeploymentPartyRoleTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="deployments"
          source="deploymentId"
          label="Deployment"
          validate={required()}
          disabled>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="expandedPartyRoles"
          source="partyRoleId"
          label="Party Role"
          validate={required()}
          disabled>
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
        <TextInput source="comment" label="Comment" fullWidth={true} />
      </SimpleForm>
    </Edit>
  )
}

export default DeploymentPartyRoleEdit
