import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  ExportButton,
  DateField,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'

const PartyAssociationListActions = props => (
  <TopToolbar>
    {cloneElement(props.filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const PartyAssociationFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      source="partyAssociationTypeId"
      label="Seach by Type"
      reference="partyAssociationTypes"
      filter={{abstract: false}}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const PartyAssociationList = props => (
  <List
    title="All Party Associations"
    filters={<PartyAssociationFilter />}
    bulkActionButtons={false}
    actions={<PartyAssociationListActions />}
    {...props}>
    <Datagrid rowClick="show">
      <ReferenceField
        label="Type"
        source="partyAssociationTypeId"
        reference="partyAssociationTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="From Role"
        source="fromPartyRoleId"
        reference="expandedPartyRoles"
        link={record => `/partyRoles/${record.fromPartyRoleId}/show`}>
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField
        label="To Role"
        source="toPartyRoleId"
        reference="expandedPartyRoles"
        link={record => `/partyRoles/${record.toPartyRoleId}/show`}>
        <TextField source="fullName" />
      </ReferenceField>
      <DateField label="From Date" source="fromDate" />
    </Datagrid>
  </List>
)

export default PartyAssociationList
