class Principal {
  static allRoles = {
    external_viewer: [],
    external_operator: ['external_viewer'],
    external_admin: ['external_operator'],
    internal_viewer: ['external_viewer'],
    internal_operator: ['internal_viewer', 'external_operator'],
    internal_admin: ['internal_operator', 'external_admin'],
  }
  static of({username, allowedRoles, defaultRole, email, userId}) {
    return new Principal(username, allowedRoles, defaultRole, email, userId)
  }
  constructor(username, allowedRoles, defaultRole, email, userId) {
    this.username = username
    this.allowedRoles = allowedRoles ?? []
    this.defaultRole = defaultRole ?? allowedRoles?.[0]
    this.email = email
    this.userId = userId
  }
  hasRole(requiredRole) {
    if (!Principal.allRoles[requiredRole]) {
      throw new Error(`Required role ${requiredRole} does not exist`)
    }
    const roles = [...this.allowedRoles]
    while (roles.length > 0) {
      const role = roles.shift()
      if (role === requiredRole) {
        return true
      }
      roles.push(...Principal.allRoles[role])
    }
    return false
  }
}

export {Principal}
