import React from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  required,
} from 'react-admin'
import {Create, Error, Loading} from '../../components'
import {parse} from 'query-string'
import {gql, useQuery} from '@apollo/client'

const GET_CORRELATION_ID_TYPES = gql`
  query q($inventoryItemId: Int) {
    correlationIdTypeForInventoryItem(inventoryitemid: $inventoryItemId) {
      nodes {
        id
        name
      }
    }
  }
`

const InventoryItemCorrelationIdTitle = () => {
  return <span>Create Inventory Item External Id</span>
}

const InventoryItemCorrelationIdCreate = props => {
  const {inventoryItemId: inventoryItemIdString} = parse(props.location.search)
  const inventoryItemId = inventoryItemIdString
    ? parseInt(inventoryItemIdString, 10)
    : ''

  const redirectUrl = inventoryItemId
    ? `/inventoryItems/${inventoryItemId}/show`
    : false
  const initialValues = {inventoryItemId: inventoryItemId}

  const {loading, error, data} = useQuery(GET_CORRELATION_ID_TYPES, {
    variables: initialValues,
    fetchPolicy: 'network-only',
  })
  if (loading) return <Loading />
  if (error) return <Error errorMessage={`${error}`} />

  return (
    <Create title={<InventoryItemCorrelationIdTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Inventory Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <SelectInput
          source="correlationIdTypeId"
          label="External Id Type"
          choices={data.correlationIdTypeForInventoryItem.nodes}
          validate={required()}
        />
        <TextInput
          source="correlationId"
          label="External Id"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}

export default InventoryItemCorrelationIdCreate
