import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  NumberInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'

const TestVariableTitle = () => {
  return <span>Create Test Variable</span>
}

const TestVariableCreate = props => {
  const {testProcedureId: testProcedureIdString} = parse(props.location.search)
  const testProcedureId = testProcedureIdString
    ? parseInt(testProcedureIdString, 10)
    : ''
  const redirectUrl = testProcedureId
    ? `/testProcedures/${testProcedureId}/show/testVariables`
    : false
  const initialValues = {testProcedureId}
  return (
    <Create title={<TestVariableTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Name" validate={required()} />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
        <ReferenceInput
          reference="unitOfMeasures"
          source="unitOfMeasureId"
          label="Unit Of Measure"
          validate={required()}>
          <AutocompleteInput optionText="symbol" />
        </ReferenceInput>
        <NumberInput source="minimumValue" label="Minimum Value" />
        <NumberInput source="maximumValue" label="Maximum Value" />
      </SimpleForm>
    </Create>
  )
}

export default TestVariableCreate
