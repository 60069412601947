import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {DeploymentPartyRoleTitle} from './deployment-party-role-lib'

const DeploymentPartyRoleActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const DeploymentPartyRoleShow = props => {
  const {permissions} = props
  return (
    <Show
      title={<DeploymentPartyRoleTitle />}
      actions={<DeploymentPartyRoleActions permissions={permissions} />}
      {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <ReferenceField
          reference="deployments"
          source="deploymentId"
          label="Deployment"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="expandedDeploymentPartyRoles"
          source="id"
          label="Party Role"
          link={record => `/partyRoles/${record.partyRoleId}/show`}>
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField
          reference="expandedDeploymentPartyRoles"
          source="id"
          label="Party Role Type"
          link={false}>
          <TextField source="partyRoleTypeName" />
        </ReferenceField>
        <DateTimeField label="From Date" source="fromDate" />
        <DateTimeField label="To Date" source="toDate" />
        <TextField label="Comment" source="comment" />
      </SimpleShowLayout>
    </Show>
  )
}

export default DeploymentPartyRoleShow
