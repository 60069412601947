import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'
import {PartInput} from '../part/part-lib'

const TestProcedurePartCoverageTitle = () => {
  return <span>Create Test Procedure Part Coverage</span>
}

const TestProcedurePartCoverageCreate = props => {
  const {testProcedureId: testProcedureIdString} = parse(props.location.search)
  const testProcedureId = testProcedureIdString
    ? parseInt(testProcedureIdString, 10)
    : ''
  const redirectUrl = testProcedureId
    ? `/testProcedures/${testProcedureId}/show/partCoverages`
    : false
  const today = new Date()
  const initialValues = {fromDate: today, testProcedureId}
  return (
    <Create title={<TestProcedurePartCoverageTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <PartInput />
        <DateTimeInput
          source="fromDate"
          label="From Date"
          defaultValue={new Date()}
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
      </SimpleForm>
    </Create>
  )
}

export default TestProcedurePartCoverageCreate
