import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import WorkEffortShow from './work-effort-show'

const resource = {
  options: {label: 'Work Efforts'},
  show: WorkEffortShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
