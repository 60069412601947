import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  NumberField,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {fullName} from '../part/part-lib'
import {SupplierPartTitle} from './supplier-part-lib'

const SupplierPartActions = ({basePath, data, permissions}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const SupplierPartShow = ({permissions, ...rest}) => (
  <Show
    {...rest}
    title={<SupplierPartTitle />}
    actions={<SupplierPartActions permissions={permissions} {...rest} />}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="Supplier"
        source="partyRoleId"
        reference="expandedPartyRoles"
        link={record => `/partyRoles/${record.partyRoleId}/show`}>
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField
        label="Part"
        source="partId"
        reference="parts"
        link="show">
        <FunctionField render={fullName} />
      </ReferenceField>
      <TextField label="Supplier Part Number" source="supplierPartNumber" />
      <NumberField label="Safety Stock Quantity" source="safetyStockQuantity" />
      <NumberField label="Lead Time (Days)" source="leadTimeDays" />
      <TextField label="Comment" source="comment" />
      <DateTimeField label="From Date" source="fromDate" />
      <DateTimeField label="To Date" source="toDate" />
    </SimpleShowLayout>
  </Show>
)

export default SupplierPartShow
