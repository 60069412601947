import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import PartCreate from './part-create'
import PartEdit from './part-edit'
import PartList from './part-list'
import PartShow from './part-show'

const resource = {
  options: {label: 'Parts'},
  list: PartList,
  show: PartShow,
  create: PartCreate,
  edit: PartEdit,
  icon: DeveloperBoardIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('Part') ? PartCreate : null,
  }
}

export default supplier
