import {createTheme} from '@material-ui/core/styles'
import {defaultTheme} from 'react-admin'
import merge from 'lodash/merge'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#263238',
      light: '#4f5b62',
      dark: '#000a12',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ef6c00',
      light: '#ff9d3f',
      dark: '#b53d00',
      contrastText: '#000000',
    },
  },
  sidebar: {
    width: 240,
  },
  overrides: {
    RaReferenceField: {
      link: {
        color: '#4054B2',
      },
    },
    RaButton: {
      button: {
        marginRight: '8px',
      },
    },
    RaSaveButton: {
      button: {
        marginRight: '8px',
      },
    },
  },
})

const theme = merge({}, defaultTheme, customTheme)

export default theme
