import * as React from 'react'
import PropTypes from 'prop-types'
import {
  EditContextProvider,
  ResourceContextProvider,
  useEditController,
} from 'ra-core'
import EditView from './EditView'

const Edit = props => {
  const controllerProps = useEditController(props)
  const body = (
    <EditContextProvider value={controllerProps}>
      <EditView {...props} {...controllerProps} />
    </EditContextProvider>
  )
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  ) : (
    body
  )
}

Edit.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.any.isRequired,
  mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  resource: PropTypes.string,
  successMessage: PropTypes.string,
  title: PropTypes.node,
  transform: PropTypes.func,
  undoable: PropTypes.bool,
}

export default Edit
