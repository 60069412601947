import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {InventoryItemConfigurationTitle} from './inventory-item-configuration-lib'

const InventoryItemConfigurationShowActions = ({
  basePath,
  data,
  permissions,
}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const InventoryItemConfigurationShowLayout = props => (
  <SimpleShowLayout>
    <TextField source="id" label="Id" />
    <ReferenceField
      reference="inventoryItemConfigurationTypes"
      source="inventoryItemConfigurationTypeId"
      label="Type"
      link="show">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      reference="inventoryItems"
      source="inventoryItemId"
      label="Item"
      link="show">
      <TextField source="serialNumber" />
    </ReferenceField>
    <ReferenceField
      reference="inventoryItems"
      source="usedInInventoryItemId"
      label="Used in Item"
      link="show">
      <TextField source="serialNumber" />
    </ReferenceField>
    <DateTimeField label="From Date" source="fromDate" />
    <DateTimeField label="To Date" source="toDate" />
    <ReferenceField
      reference="expandedInventoryItemConfigurations"
      source="replacedInventoryItemConfigurationId"
      label="Replaced Inventory Item Configuration"
      link={record =>
        `/inventoryItemConfigurations/${record.replacedInventoryItemConfigurationId}/show`
      }>
      <TextField source="serialNumber" />
    </ReferenceField>
  </SimpleShowLayout>
)

const InventoryItemConfigurationShow = ({permissions, ...rest}) => (
  <Show
    title={<InventoryItemConfigurationTitle />}
    actions={
      <InventoryItemConfigurationShowActions
        permissions={permissions}
        {...rest}
      />
    }
    {...rest}>
    <InventoryItemConfigurationShowLayout permissions={permissions} />
  </Show>
)

export default InventoryItemConfigurationShow
