import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  TextField,
  useEditController,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {PartReleaseAssociationTitle} from './part-release-association-lib'
import {PartInput} from '../part/part-lib'

const PartReleaseAssociationEdit = ({permissions, ...rest}) => {
  const {record} = useEditController(rest)
  const redirect = record ? `/parts/${record.partId}/show` : 'show'
  return (
    <Edit title={<PartReleaseAssociationTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextField source="id" label="Id" disabled />
        <PartInput label="Part" source="partId" />
        <ReferenceInput
          label="Release"
          source="releaseId"
          reference="releases"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default PartReleaseAssociationEdit
