import React from 'react'
import {
  CircularProgress,
  Card,
  CardContent,
  Button,
  Chip,
  Typography,
} from '@material-ui/core'
import {
  DeleteButton,
  SaveButton,
  Toolbar,
  useRecordContext,
  Link,
  DateField,
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {useHistory} from 'react-router'
import get from 'lodash/get'

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  noData: {
    margin: theme.spacing(2),
  },
  chip: {margin: 4, cursor: 'inherit'},
}))

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation()

// Our handleClick does nothing as we wrap the children inside a Link but it is
// required by ChipField, which uses a Chip from material-ui.
// The material-ui Chip requires an onClick handler to behave like a clickable element.
const handleClick = () => {}

const ChipFieldLink = props => {
  const record = useRecordContext(props)
  const {link, source} = props
  const value = get(record, source)
  const classes = useStyles()
  return (
    <Link to={link(record)} onClick={stopPropagation}>
      <Chip className={classes.chip} onClick={handleClick} label={value} />
    </Link>
  )
}

const BackButton = props => {
  const history = useHistory()
  return (
    <Button
      color="primary"
      size="medium"
      variant="contained"
      type="button"
      startIcon={<ArrowBackIcon />}
      title="Go Back"
      onClick={() => history.goBack()}>
      Back
    </Button>
  )
}

const NoData = props => {
  const classes = useStyles()
  return (
    <Typography className={classes.noData} variant="body2">
      No results found
    </Typography>
  )
}

const Error = props => (
  <div
    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Card>
      <CardContent>{props.errorMessage}</CardContent>
    </Card>
  </div>
)

const Loading = props => (
  <div
    style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <CircularProgress />
  </div>
)

const valueOrDefault = (value, defaultValue) =>
  typeof value === 'undefined' ? defaultValue : value

const EditToolbar = props => {
  const {
    permissions,
    alwaysEnableSaveButton,
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    mutationMode,
    validating,
    ...rest
  } = props
  const classes = useStyles()

  // Use form pristine and validating to enable or disable the save button
  // if alwaysEnableSaveButton is undefined
  const disabled = !valueOrDefault(
    alwaysEnableSaveButton,
    !pristine && !validating,
  )

  return (
    <Toolbar className={classes.toolbar} {...rest}>
      <SaveButton
        size="medium"
        type="button"
        title="Save"
        handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
        disabled={disabled}
        invalid={invalid}
        redirect={redirect}
        saving={saving || validating}
        submitOnEnter={submitOnEnter}
      />
      {record &&
        typeof record.id !== 'undefined' &&
        permissions.canDelete(record) && (
          <DeleteButton
            size="medium"
            type="button"
            title="Delete"
            basePath={basePath}
            record={record}
            redirect={redirect}
            resource={resource}
            undoable={undoable}
            mutationMode={mutationMode}
          />
        )}
    </Toolbar>
  )
}

const DateTimeField = props => {
  return (
    <DateField
      {...props}
      options={{
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }}
    />
  )
}

DateTimeField.defaultProps = {addLabel: true}

export {
  Error,
  Loading,
  EditToolbar,
  BackButton,
  NoData,
  ChipFieldLink,
  DateTimeField,
}
