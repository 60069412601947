import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {isPerson, isOrganization, PartyTitle} from './party-lib'
import AddPartyRoleButton from './add-party-role-button'

const personFields = [
  <TextField source="givenName" label="Given Name" />,
  <TextField source="familyName" label="Family Name" />,
]

const organizationFields = <TextField source="purpose" label="Purpose" />

const PartyShowActions = ({basePath, data, permissions}) => {
  return (
    <ShowToolbar>
      {data && permissions.canEdit(data) ? (
        <EditButton
          basePath={basePath}
          record={data}
          type="button"
          variant="contained"
          size="medium"
          title="Edit"
        />
      ) : null}
      {data && permissions.canCreate('PartyRole') ? (
        <AddPartyRoleButton record={data} />
      ) : null}
    </ShowToolbar>
  )
}

const PartyShowLayout = props => {
  const {record} = props
  return (
    <SimpleShowLayout {...props}>
      <TextField source="id" label="Id" />
      <ReferenceField
        source="partyTypeId"
        label="Type"
        reference="partyTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="fullName" label="Full Name" />
      <TextField source="preferredName" label="Preferred Name" />
      {isPerson(record)
        ? personFields
        : isOrganization(record)
        ? organizationFields
        : null}
      <ReferenceManyField
        label="Roles"
        reference="partyRoles"
        target="forPartyId"
        link="show">
        <SingleFieldList linkType="show">
          <ReferenceField
            source="partyRoleTypeId"
            reference="partyRoleTypes"
            link={false}>
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
    </SimpleShowLayout>
  )
}

const PartyShow = ({permissions, ...rest}) => (
  <Show
    title={<PartyTitle />}
    actions={<PartyShowActions permissions={permissions} {...rest} />}
    {...rest}>
    <PartyShowLayout permissions={permissions} />
  </Show>
)

export default PartyShow
