import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddInventoryItemButton = ({partId, ...rest}) => {
  return (
    <Button
      component={Link}
      type="button"
      variant="contained"
      size="medium"
      to={`/inventoryItems/create?partId=${partId}`}
      label="Add inventory item"
      title="Add inventory item">
      <AddCircleIcon />
    </Button>
  )
}

export default AddInventoryItemButton
