import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  DateTimeInput,
  required,
  AutocompleteInput,
} from 'react-admin'
import {Create} from '../../components'
import {PartInput} from '../part/part-lib'
import {parse} from 'query-string'

const ProductPartAssociationCreateTitle = () => {
  return <span>Create Product Part Association</span>
}

const ProductPartAssociationCreate = props => {
  const {partId: partIdString} = parse(props.location.search)

  const partId = partIdString ? parseInt(partIdString, 10) : ''

  const redirect = partId ? `/parts/${partId}/show` : 'show'

  const initialValues = {
    fromDate: new Date(),
    partId,
  }

  return (
    <Create title={<ProductPartAssociationCreateTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirect}>
        <PartInput label="Part" source="partId" />
        <ReferenceInput
          label="Implements Product"
          source="productId"
          reference="products"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput
          label="From Date"
          source="fromDate"
          validate={required()}
        />
        <DateTimeInput label="To Date" source="toDate" />
      </SimpleForm>
    </Create>
  )
}

export default ProductPartAssociationCreate
