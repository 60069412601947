import React from 'react'
import {SimpleForm, TextInput, required} from 'react-admin'
import {Create} from '../../components'

const TestProcedureTitle = () => {
  return <span>Create Test Procedure</span>
}

const TestProcedureCreate = ({permissions, ...rest}) => {
  return (
    <Create title={<TestProcedureTitle />} {...rest}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Name" validate={required()} />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}

export default TestProcedureCreate
