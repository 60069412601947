import StoreIcon from '@material-ui/icons/Store'
import ProductComponentShow from './product-component-show'

const resource = {
  options: {label: 'Product Components'},
  show: ProductComponentShow,
  icon: StoreIcon,
}

const supplier = permissions => resource

export default supplier
