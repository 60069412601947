import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import SyncIcon from '@material-ui/icons/Sync'

export const ShowProductInventoryItemDeploymentStatus = props => {
  const {record} = props

  return !record.toDate ? (
    <Button
      component={Link}
      type="button"
      to={`/productInventoryItemDeploymentStatuses?partNumber=${record.partNumber}&partRevision=${record.partRevision}&deploymentStatusId=${record.deploymentStatusId}`}
      label="Show"
      title="Show Product Inventory Item Deployments">
      <SyncIcon />
    </Button>
  ) : null
}

export default ShowProductInventoryItemDeploymentStatus
