import * as React from 'react'
import PropTypes from 'prop-types'
import {
  ShowContextProvider,
  ResourceContextProvider,
  useShowController,
} from 'react-admin'
import ShowView from './ShowView'

const Show = props => {
  const controllerProps = useShowController(props)
  const body = (
    <ShowContextProvider value={controllerProps}>
      <ShowView {...props} {...controllerProps} />
    </ShowContextProvider>
  )
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  ) : (
    body
  )
}

Show.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  id: PropTypes.any.isRequired,
  resource: PropTypes.string,
  title: PropTypes.node,
}

export default Show
