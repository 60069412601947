import * as React from 'react'
import PropTypes from 'prop-types'
import {
  CreateContextProvider,
  ResourceContextProvider,
  useCreateController,
} from 'react-admin'
import CreateView from './CreateView'

const Create = props => {
  const controllerProps = useCreateController(props)
  const body = (
    <CreateContextProvider value={controllerProps}>
      <CreateView {...props} {...controllerProps} />
    </CreateContextProvider>
  )
  return props.resource ? (
    // support resource override via props
    <ResourceContextProvider value={props.resource}>
      {body}
    </ResourceContextProvider>
  ) : (
    body
  )
}

Create.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  resource: PropTypes.string,
  title: PropTypes.node,
  record: PropTypes.object,
  hasList: PropTypes.bool,
  successMessage: PropTypes.string,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  transform: PropTypes.func,
}

export default Create
