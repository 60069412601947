import {gql, useApolloClient} from '@apollo/client'
import {useCurrentPrincipal} from './current-principal'
import React from 'react'

const CURRENT_WORKER = gql`
  query {
    currentWorker {
      partyRoleId
      forPartyId
      fullName
      preferredName
      givenName
      familyName
      userName
    }
  }
`

function useCurrentWorker(initialWorker = {}) {
  const currentPrincipal = useCurrentPrincipal()
  const client = useApolloClient()
  const [currentWorker, setCurrentWorker] = React.useState(initialWorker)
  React.useEffect(() => {
    if (currentPrincipal.username !== currentWorker.userName) {
      client
        .query({query: CURRENT_WORKER, fetchPolicy: 'network-only'})
        .then(({data}) => setCurrentWorker(data.currentWorker))
    }
  }, [currentPrincipal, currentWorker, client])

  return currentWorker
}

export {CURRENT_WORKER, useCurrentWorker}
