import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {typeConsigneeId} from '../party-role/party-role-lib'
import {DeploymentTitle, DeploymentNameField} from './deployment-lib'

const DeploymentEdit = props => {
  const {permissions, ...rest} = props
  const nameRef = React.useRef('')
  const transform = data => ({
    ...data,
    name: nameRef.current,
  })
  return (
    <Edit title={<DeploymentTitle />} transform={transform} {...props}>
      <SimpleForm toolbar={<EditToolbar permissions={permissions} {...rest} />}>
        <TextInput disabled source="id" label="Id" />
        <DeploymentNameField nameRef={nameRef} />
        <ReferenceInput
          source="partyRoleId"
          reference="expandedPartyRoles"
          label="Consignee"
          filter={{partyRoleTypeId: typeConsigneeId}}
          validate={required()}
          disabled>
          <AutocompleteInput optionText="preferredName" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        <TextInput source="qualifier" label="Qualifier" />
        <DateTimeInput source="toDate" label="To Date" />
        <TextInput source="comment" label="Comment" fullWidth={true} />
      </SimpleForm>
    </Edit>
  )
}

export default DeploymentEdit
