import React from 'react'
import {
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  EditButton,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  FunctionField,
  Pagination,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {TestProcedureTitle} from './test-procedure-lib'
import AddTestProcedurePartCoverageButton from '../test-procedure-part-coverage/add-test-procedure-part-coverage-button'
import AddTestVariableButton from '../test-variable/add-test-variable-button'
import {fullName} from '../part/part-lib'

const TestProcedureActions = ({permissions, basePath, data, location}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) && location.pathname.endsWith('show') ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
    {data &&
    permissions.canCreate('TestProcedurePartCoverage') &&
    location.pathname.endsWith('partCoverages') ? (
      <AddTestProcedurePartCoverageButton
        record={data}
        type="button"
        variant="contained"
        size="medium"
      />
    ) : null}
    {data &&
    permissions.canCreate('TestVariable') &&
    location.pathname.endsWith('testVariables') ? (
      <AddTestVariableButton
        record={data}
        type="button"
        variant="contained"
        size="medium"
      />
    ) : null}
  </ShowToolbar>
)

const TestProcedureShow = props => {
  const {permissions, ...rest} = props
  return (
    <Show
      title={<TestProcedureTitle />}
      actions={<TestProcedureActions permissions={permissions} {...rest} />}
      {...props}>
      <TabbedShowLayout>
        <Tab label="Detail">
          <TextField source="id" label="Id" />
          <TextField label="Name" source="name" />
          <TextField label="Description" source="description" />
        </Tab>
        <Tab label="Part Coverages" path="partCoverages">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            label="Part Coverages"
            reference="testProcedurePartCoverages"
            sort={{field: 'partId', order: 'ASC'}}
            target="testProcedureId">
            <Datagrid>
              <ReferenceField
                label="Part"
                source="partId"
                reference="parts"
                link="show">
                <FunctionField render={fullName} />
              </ReferenceField>
              <DateTimeField label="From Date" source="fromDate" />
              <DateTimeField label="To Date" source="toDate" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Test Variables" path="testVariables">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            label="Test Variables"
            reference="testVariables"
            sort={{field: 'name', order: 'ASC'}}
            target="testProcedureId">
            <Datagrid>
              <TextField label="Name" source="name" />
              <TextField label="Description" source="description" />
              <ReferenceField
                label="Unit Of Measure"
                source="unitOfMeasureId"
                reference="unitOfMeasures"
                link="show">
                <TextField source="symbol" />
              </ReferenceField>
              <NumberField source="minimumValue" label="Minimum Value" />
              <NumberField source="maximumValue" label="Maximum Value" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Test Executions" path="testExecutions">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            label="Test Executions"
            reference="inventoryItemTestExecutions"
            sort={{field: 'executedAt', order: 'DESC'}}
            target="testProcedureId">
            <Datagrid>
              <ReferenceField
                reference="inventoryItems"
                source="inventoryItemId"
                label="Tested Item"
                link="show">
                <TextField source="serialNumber" />
              </ReferenceField>
              <DateTimeField source="executedAt" label="Executed At" />
              <BooleanField source="passed" label="Passed" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default TestProcedureShow
