import React from 'react'

const SidebarToggleContext = React.createContext()

function SidebarToggleProvider({initialSidebarOpen = true, children}) {
  const [open, setOpen] = React.useState(initialSidebarOpen)

  const toggleSidebar = () => {
    setOpen(prev => !prev)
  }

  const value = React.useMemo(() => [open, toggleSidebar], [open])

  return (
    <SidebarToggleContext.Provider value={value}>
      {children}
    </SidebarToggleContext.Provider>
  )
}

function useSidebarToggler() {
  const context = React.useContext(SidebarToggleContext)
  if (!context) {
    throw new Error(
      `useSidebarToggler must be used within a SidebarToggleProvider`,
    )
  }
  return context
}

export {SidebarToggleProvider, useSidebarToggler}
