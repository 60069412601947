import React from 'react'
import {
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  SimpleShowLayout,
} from 'react-admin'
import {Show} from '../../components'

const TestObservationTitle = ({record}) => {
  return <span>Test Observation: {record ? `${record.id}` : ''}</span>
}

const TestObservationShow = props => (
  <Show title={<TestObservationTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="Test Variable"
        source="testVariableId"
        reference="testVariables"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="value" label="Value" />
      <BooleanField source="passed" label="Passed" />
    </SimpleShowLayout>
  </Show>
)

export default TestObservationShow
