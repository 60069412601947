import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import DeploymentItemCreate from './deployment-item-create'
import DeploymentItemEdit from './deployment-item-edit'
import DeploymentItemShow from './deployment-item-show'

const resource = {
  options: {label: 'Deployment Items'},
  create: DeploymentItemCreate,
  edit: DeploymentItemEdit,
  show: DeploymentItemShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('DeploymentItem')
      ? DeploymentItemCreate
      : null,
  }
}

export default supplier
