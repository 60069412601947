import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin'
import {List} from '../../components'

const FacilityFilter = props => (
  <Filter {...props}>
    <TextInput source="name" label="Search by Name" alwaysOn />
    <ReferenceInput
      source="facilityTypeId"
      label="Search by FacilityType"
      reference="facilityTypes"
      filterToQuery={searchText => ({name: searchText})}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const FacilityList = props => (
  <List
    title="All Facilities"
    filters={<FacilityFilter />}
    bulkActionButtons={false}
    sort={{field: 'name', order: 'ASC'}}
    {...props}>
    <Datagrid rowClick="show">
      <TextField label="Name" source="name" />
      <TextField label="Description" source="description" />
      <ReferenceField
        source="facilityTypeId"
        reference="facilityTypes"
        label="Facility Type"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="partOfFacilityId"
        reference="facilities"
        label="Part Of"
        link="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default FacilityList
