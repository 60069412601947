import AuthenticatedApp from './authenticated-app'
import UnauthenticatedApp from './unauthenticated-app'
import {useAuth0} from '@auth0/auth0-react'
import {Loading, Error} from './components/lib'

function App() {
  const {isLoading, error, isAuthenticated} = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Error errorMessage={'Something went wrong'} />
  }

  return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

export default App
