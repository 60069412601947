import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import InventoryItemTestExecutionShow from './inventory-item-test-execution-show'

const resource = {
  options: {label: 'Inventory Item Test Execution'},
  show: InventoryItemTestExecutionShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
