import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
  useEditController,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {PartInput} from '../part/part-lib'
import {TestProcedurePartCoverageTitle} from './test-procedure-part-coverage-lib'

const TestProcedurePartCoverageEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/testProcedures/${record.testProcedureId}/show/partCoverages`
  return (
    <Edit title={<TestProcedurePartCoverageTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <PartInput />
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
      </SimpleForm>
    </Edit>
  )
}

export default TestProcedurePartCoverageEdit
