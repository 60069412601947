import WidgetsIcon from '@material-ui/icons/Widgets'
import InventoryItemStatusTypeShow from './inventory-item-status-type-show'

const resource = {
  options: {label: 'Inventory Status Types'},
  show: InventoryItemStatusTypeShow,
  icon: WidgetsIcon,
}

const supplier = () => resource

export default supplier
