import React from 'react'
import {
  TextField,
  ReferenceField,
  EditButton,
  ReferenceManyField,
  Datagrid,
  DateField,
  ShowButton,
  TabbedShowLayout,
  Tab,
  Pagination,
  FunctionField,
  BooleanField,
  NumberField,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {
  isConsignee,
  isSupplier,
  isWorker,
  isDeploymentPartyRole,
  PartyRoleTitle,
  isCustomer,
} from './party-role-lib'
import {fullName} from '../part/part-lib'
import AddPartyAssociationButton from '../party-association/add-party-association-button'
import AddDeploymentButton from '../deployment/add-deployment-button'
import {withRouter} from 'react-router'
import AddDeploymentItemWatchButton from '../deployment-item-watch/add-deployment-item-watch-button'

const PartyRoleShowActions = props => {
  const {basePath, permissions, data, location} = props
  return (
    <ShowToolbar>
      {data &&
      permissions.canEdit(data) &&
      location.pathname.endsWith('show') ? (
        <EditButton
          basePath={basePath}
          record={data}
          type="button"
          variant="contained"
          size="medium"
          title="Edit"
        />
      ) : null}
      {data &&
      permissions.canCreate('PartyAssociation') &&
      location.pathname.endsWith('associations') ? (
        <AddPartyAssociationButton record={data} />
      ) : null}
      {data &&
      isConsignee(data) &&
      permissions.canCreate('Deployment') &&
      location.pathname.endsWith('deployments') ? (
        <AddDeploymentButton record={data} />
      ) : null}
      {data &&
      isDeploymentPartyRole(data) &&
      permissions.canEdit(data) &&
      permissions.canCreate('DeploymentItemWatch') &&
      location.pathname.endsWith('watchItems') ? (
        <AddDeploymentItemWatchButton record={data} />
      ) : null}
    </ShowToolbar>
  )
}

const PartyRoleShowLayout = props => {
  const {record} = props
  return (
    <TabbedShowLayout {...props}>
      <Tab label="Detail">
        <TextField source="id" label="Id" />
        <ReferenceField
          source="forPartyId"
          label="Party"
          reference="parties"
          link="show">
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField
          source="partyRoleTypeId"
          label="Type"
          reference="partyRoleTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        {isWorker(record) ? (
          <TextField source="userName" label="Username" />
        ) : null}
        {isCustomer(record) ? (
          <NumberField source="hubSpotId" label="Hub Spot Id" />
        ) : null}
      </Tab>
      <Tab label="Associations" path="associations">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Belonging To"
          target="fromPartyRoleId"
          sort={{field: 'fromDate', order: 'DESC'}}
          reference="partyAssociations">
          <Datagrid>
            <ReferenceField
              label="Type"
              source="partyAssociationTypeId"
              reference="partyAssociationTypes"
              link="show">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="To Role"
              source="toPartyRoleId"
              reference="expandedPartyRoles"
              link={record => `/partyRoles/${record.toPartyRoleId}/show`}>
              <TextField source="fullName" />
            </ReferenceField>
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Including"
          target="toPartyRoleId"
          sort={{field: 'fromDate', order: 'DESC'}}
          reference="partyAssociations">
          <Datagrid>
            <ReferenceField
              label="Type"
              source="partyAssociationTypeId"
              reference="partyAssociationTypes"
              link="show">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="From Role"
              source="fromPartyRoleId"
              reference="expandedPartyRoles"
              link={record => `/partyRoles/${record.fromPartyRoleId}/show`}>
              <TextField source="fullName" />
            </ReferenceField>
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      {isSupplier(record) ? (
        <Tab label="Supplying" path="supplying">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            reference="supplierParts"
            target="partyRoleId"
            sort={{field: 'fromDate', order: 'DESC'}}
            label="Parts">
            <Datagrid>
              <ReferenceField
                label="Part"
                source="partId"
                reference="parts"
                link="show">
                <FunctionField render={fullName} />
              </ReferenceField>
              <TextField
                label="Supplier Part Number"
                source="supplierPartNumber"
              />
              <DateField label="From Date" source="fromDate" />
              <DateField label="To Date" source="toDate" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      ) : null}
      {isConsignee(record) ? (
        <Tab label="Deployments" path="deployments">
          <ReferenceManyField
            label="Deployments"
            reference="deployments"
            sort={{field: 'fromDate', order: 'DESC'}}
            target="partyRoleId">
            <Datagrid>
              <TextField label="Name" source="name" />
              <TextField label="Comment" source="comment" />
              <DateField label="From Date" source="fromDate" />
              <DateField label="To Date" source="toDate" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      ) : null}
      {isDeploymentPartyRole(record) ? (
        <Tab label="Watch Items" path="watchItems">
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            reference="expandedDeploymentItemWatches"
            target="partyRoleId"
            label="Watched Deployment Items">
            <Datagrid>
              <ReferenceField
                reference="deployments"
                source="deploymentId"
                label="Deployment"
                link="show">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                reference="inventoryItems"
                source="inventoryItemId"
                label="Item"
                link="show">
                <TextField source="serialNumber" />
              </ReferenceField>
              <TextField label="Alias" source="alias" />
              <BooleanField label="Flagged" source="flagged" />
              <ShowButton
                basePath="/deploymentItemWatches"
                resource="deploymentItemWatches"
              />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            pagination={<Pagination />}
            perPage={10}
            reference="deploymentPartyRoleDeploymentItems"
            target="partyRoleId"
            filter={{watched: false}}
            label="Available (Unwatched) Deployment Items">
            <Datagrid>
              <ReferenceField
                reference="deployments"
                source="deploymentId"
                label="Deployment"
                link="show">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                reference="inventoryItems"
                source="inventoryItemId"
                label="Item"
                link="show">
                <TextField source="serialNumber" />
              </ReferenceField>
              <TextField label="Alias" source="alias" />
              <BooleanField label="Flagged" source="flagged" />
              <ShowButton
                basePath="/deploymentItems"
                resource="deploymentItems"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      ) : null}
    </TabbedShowLayout>
  )
}

const PartyRoleShow = props => (
  <Show
    title={<PartyRoleTitle />}
    actions={<PartyRoleShowActions {...props} />}
    {...props}>
    <PartyRoleShowLayout />
  </Show>
)

export default withRouter(PartyRoleShow)
