import React from 'react'
import {
  TextField,
  ReferenceField,
  Tab,
  TabbedShowLayout,
  BooleanField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ShowButton,
} from 'react-admin'
import {Show} from '../../components'

const PartyRoleTypeTitle = ({record}) => {
  return <span>Party Role Type: {record ? `${record.name}` : ''}</span>
}

const PartyRoleTypeShow = props => (
  <Show title={<PartyRoleTypeTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <ReferenceField
          source="superPartyRoleTypeId"
          label="Super Type"
          reference="partyRoleTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="forPartyTypeId"
          label="For Party Type"
          reference="partyTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField label="Abstract" source="abstract" />
      </Tab>
      <Tab label="Instances">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Instances"
          reference="partyRoles"
          target="partyRoleTypeId">
          <Datagrid>
            <TextField source="id" label="Id" />
            <ReferenceField
              source="forPartyId"
              label="Party"
              reference="parties"
              link="show">
              <TextField source="fullName" />
            </ReferenceField>
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default PartyRoleTypeShow
