import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  NumberField,
  useShowController,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {fullName} from '../part/part-lib'
import {
  PartAssociationTitle,
  getPartAssociationTypeLabels,
} from './part-association-lib'

const PartAssociationActions = ({basePath, data, permissions}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const PartAssocationShowLayout = props => {
  const {record} = useShowController(props)
  const labels = getPartAssociationTypeLabels(record.partAssociationTypeId)
  return (
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="Type"
        source="partAssociationTypeId"
        reference="partAssociationTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label={labels.fromLabel}
        source="fromPartId"
        reference="parts"
        link="show">
        <FunctionField render={fullName} />
      </ReferenceField>
      <ReferenceField
        label={labels.toLabel}
        source="toPartId"
        reference="parts"
        link="show">
        <FunctionField render={fullName} />
      </ReferenceField>
      {labels.abbr === 'BOM' ? (
        <NumberField label="Quantity" source="quantity" />
      ) : null}
      <DateTimeField label="From Date" source="fromDate" />
      <DateTimeField label="To Date" source="toDate" />
    </SimpleShowLayout>
  )
}
const PartAssociationShow = props => {
  const {permissions, ...rest} = props
  return (
    <Show
      title={<PartAssociationTitle />}
      actions={<PartAssociationActions permissions={permissions} {...rest} />}
      {...props}>
      <PartAssocationShowLayout permissions={permissions} {...rest} />
    </Show>
  )
}

export default PartAssociationShow
