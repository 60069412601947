import * as React from 'react'
import {IconButton, Tooltip} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import {useSidebarToggler} from './sidebar-toggle-provider'

const useStyles = makeStyles(theme => ({
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
}))

const SidebarToggleButton = props => {
  const [sidebarOpen, toggleSidebar] = useSidebarToggler()
  const classes = useStyles(props)
  const {className} = props
  return (
    <Tooltip title="Open/Close Sidebar" enterDelay={500}>
      <IconButton color="inherit" onClick={toggleSidebar} className={className}>
        <MenuIcon
          classes={{
            root: sidebarOpen
              ? classes.menuButtonIconOpen
              : classes.menuButtonIconClosed,
          }}
        />
      </IconButton>
    </Tooltip>
  )
}

export default SidebarToggleButton
