import React from 'react'
import {SimpleForm, ReferenceInput, SelectInput, required} from 'react-admin'
import {Create} from '../../components/'
import {PartInput} from '../part/part-lib'
import {parse} from 'query-string'

const PartReleaseAssociationCreateTitle = () => {
  return <span>Create Part Release Association</span>
}

const PartReleaseAssociationCreate = ({permissions, ...rest}) => {
  const {partId: partIdString} = parse(rest.location.search)
  const partId = partIdString ? parseInt(partIdString, 10) : ''
  const redirect = partId ? `/parts/${partId}/show` : 'show'
  const initialValues = {
    partId,
  }
  return (
    <Create title={<PartReleaseAssociationCreateTitle />} {...rest}>
      <SimpleForm redirect={redirect} initialValues={initialValues}>
        <PartInput label="Part" source="partId" />
        <ReferenceInput
          label="Release"
          source="releaseId"
          reference="releases"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default PartReleaseAssociationCreate
