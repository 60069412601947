import * as React from 'react'
import {cloneElement, Children} from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {useResourceDefinition, useShowContext} from 'react-admin'
import {TitleForRecord} from '../layout'

const useStyles = makeStyles({
  root: {},
  card: {},
})

const ShowView = props => {
  const {
    actions,
    aside,
    children,
    classes: classesOverride,
    className,
    title,
    ...rest
  } = props
  const classes = useStyles(props)
  const {basePath, hasList, record, resource, version} = useShowContext(props)
  const {hasEdit} = useResourceDefinition(props)

  if (!children) {
    return null
  }

  return (
    <div
      className={classnames('show-page', classes.root, className)}
      {...sanitizeRestProps(rest)}>
      <Card className={classes.card}>
        <TitleForRecord title={title} record={record} />
        {record &&
          cloneElement(Children.only(children), {
            resource,
            basePath,
            record,
            version,
          })}
        {actions &&
          cloneElement(actions, {
            basePath,
            data: record,
            hasList,
            hasEdit,
            resource,
            //  Ensure we don't override any user provided props
            ...actions.props,
          })}
      </Card>
      {aside &&
        cloneElement(aside, {
          resource,
          basePath,
          record,
          version,
        })}
    </div>
  )
}

ShowView.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  title: PropTypes.any,
  version: PropTypes.node,
}

ShowView.defaultProps = {
  classes: {},
}

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  id = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  options = null,
  refetch = null,
  permissions = null,
  ...rest
}) => rest

export default ShowView
