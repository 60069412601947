import * as React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {usePermissions} from 'react-admin'

const useStyles = makeStyles({
  root: {},
  label: {
    fontSize: 14,
  },
  value: {
    fontSize: 14,
  },
})

function Dashboard() {
  const classes = useStyles()
  const {permissions} = usePermissions()

  return (
    <Card className={classes.root}>
      <CardHeader
        title={`Welcome to caboodle, ${permissions?.preferredName}`}
      />
      <CardContent>
        <Typography color="textSecondary">Name</Typography>
        <Typography>{permissions?.fullName}</Typography>
        <Typography color="textSecondary">username</Typography>
        <Typography>{permissions?.principal?.username}</Typography>
        <Typography color="textSecondary">email</Typography>
        <Typography>{permissions?.principal?.email}</Typography>
        <Typography color="textSecondary">Auth0 userId</Typography>
        <Typography>{permissions?.principal?.userId}</Typography>
        <Typography color="textSecondary">roles</Typography>
        <Typography>
          {permissions?.principal?.allowedRoles.join(', ')}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Dashboard
