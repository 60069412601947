import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {Principal} from './principal'

const claimsQn = process.env.REACT_APP_AUTH0_CLAIMS_QNAME

function useCurrentPrincipal() {
  const {isAuthenticated, user} = useAuth0()
  const [principal, setPrincipal] = React.useState(() => new Principal())
  React.useEffect(() => {
    if (isAuthenticated && user?.[claimsQn]) {
      setPrincipal(Principal.of(user[claimsQn]))
    } else {
      setPrincipal(new Principal())
    }
  }, [isAuthenticated, user])
  return principal
}

export {useCurrentPrincipal}
