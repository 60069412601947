import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddProductPartAssociationButton = ({record}) => {
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      component={Link}
      to={`/productPartAssociations/create?partId=${record.id}`}
      label={`Add product`}
      title={`Add product`}>
      <AddCircleIcon />
    </Button>
  )
}

export default AddProductPartAssociationButton
