import {Auth0ProviderWithHistory} from './auth0-context'
import {AuthenticatedApolloProvider} from './apollo-context'
import {BrowserRouter as Router} from 'react-router-dom'
import {ThemeProvider} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'

function AppProviders({children}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Auth0ProviderWithHistory>
          <AuthenticatedApolloProvider>{children}</AuthenticatedApolloProvider>
        </Auth0ProviderWithHistory>
      </Router>
    </ThemeProvider>
  )
}

export {AppProviders}
