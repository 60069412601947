import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import FacilityCreate from './facility-create'
import FacilityEdit from './facility-edit'
import FacilityList from './facility-list'
import FacilityShow from './facility-show'

const resource = {
  options: {label: 'Facilities'},
  create: FacilityCreate,
  edit: FacilityEdit,
  list: FacilityList,
  show: FacilityShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('Facility') ? FacilityCreate : null,
  }
}

export default supplier
