import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddPartReleaseAssociationButton = ({record}) => {
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      component={Link}
      to={`/partReleaseAssociations/create?partId=${record.id}`}
      label={`Add release`}
      title={`Add release`}>
      <AddCircleIcon />
    </Button>
  )
}

export default AddPartReleaseAssociationButton
