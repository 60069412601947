import WidgetsIcon from '@material-ui/icons/Widgets'
import InventoryItemLocationShow from './inventory-item-location-show'
import InventoryItemLocationEdit from './inventory-item-location-edit'
import InventoryItemLocationCreate from './inventory-item-location-create'

const resource = {
  options: {label: 'Inventory Item Configurations'},
  show: InventoryItemLocationShow,
  edit: InventoryItemLocationEdit,
  create: InventoryItemLocationCreate,
  icon: WidgetsIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('InventoryItemLocation')
      ? InventoryItemLocationCreate
      : null,
  }
}

export default supplier
