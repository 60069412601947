import * as React from 'react'
import {cloneElement} from 'react'
import {MenuItem, ListItemIcon, Tooltip} from '@material-ui/core'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {useSidebarToggler} from './sidebar-toggle-provider'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
  active: {
    color: theme.palette.primary.contrastText,
  },
  icon: {
    color: theme.palette.primary.contrastText,
    minWidth: theme.spacing(5),
    justifyContent: 'center',
  },
}))

const MenuItemLink = props => {
  const {className, primaryText, leftIcon, ...rest} = props
  const [sidebarOpen] = useSidebarToggler()
  const classes = useStyles(props)

  const renderMenuItem = () => {
    return (
      <MenuItem
        className={clsx(classes.root, className)}
        activeClassName={classes.active}
        component={NavLink}
        {...rest}>
        {leftIcon && (
          <ListItemIcon className={classes.icon}>
            {cloneElement(leftIcon, {
              titleAccess: primaryText,
            })}
          </ListItemIcon>
        )}
        {sidebarOpen ? primaryText : ''}
      </MenuItem>
    )
  }

  return sidebarOpen ? (
    renderMenuItem()
  ) : (
    <Tooltip title={primaryText} placement="right">
      {renderMenuItem()}
    </Tooltip>
  )
}

MenuItemLink.propTypes = {
  className: PropTypes.string,
  leftIcon: PropTypes.element,
  primaryText: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  sidebarOpen: PropTypes.bool,
}

export default MenuItemLink
