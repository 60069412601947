import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'
import {PartInput} from '../part/part-lib'

const InventoryItemTitle = () => {
  return <span>Create Inventory Item</span>
}

const InventoryItemCreate = props => {
  const {partId: partIdString} = parse(props.location.search)
  const partId = partIdString ? parseInt(partIdString, 10) : ''
  const redirect = partId ? `/parts/${partId}/show/inventory` : 'show'
  const initialValues = {partId, inventoryItemStatusTypeId: 1}
  return (
    <Create title={<InventoryItemTitle />} {...props}>
      <SimpleForm redirect={redirect} initialValues={initialValues}>
        <TextInput
          source="serialNumber"
          label="Serial Number"
          validate={required()}
        />
        <ReferenceInput
          source="inventoryItemStatusTypeId"
          label="Status"
          filter={{initialState: true}}
          reference="inventoryItemStatusTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <PartInput />
        <TextInput source="comment" label="Comment" />
      </SimpleForm>
    </Create>
  )
}

export default InventoryItemCreate
