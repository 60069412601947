import React from 'react'
import {Admin, Resource} from 'react-admin'
import MyLayout from './components/layout/layout'
import DashBoard from './pages/dashboard'
import partyType from './pages/party-type'
import party from './pages/party'
import partyRoleType from './pages/party-role-type'
import partyRole from './pages/party-role'
import partyAssociationType from './pages/party-association-type'
import partyAssociation from './pages/party-association'
import product from './pages/product'
import productComponent from './pages/product-component'
import productPartAssocation from './pages/product-part-association'
import supplierPart from './pages/supplier-part'
import partType from './pages/part-type'
import part from './pages/part'
import inventoryItemStatusType from './pages/inventory-item-status-type'
import inventoryItemConfigurationType from './pages/inventory-item-configuration-type'
import inventoryItem from './pages/inventory-item'
import partInventoryItem from './pages/part-inventory-item'
import productInventoryItem from './pages/product-inventory-item'
import productInventoryItemDeploymentStatus from './pages/product-inventory-item-deployment-status'
import productInventoryItemCountByDeploymentStatus from './pages/product-inventory-item-count-by-deployment-status'
import deployment from './pages/deployment'
import deploymentItem from './pages/deployment-item'
import deploymentPartyRole from './pages/deployment-party-role'
import deploymentItemWatch from './pages/deployment-item-watch'
import partAssociation from './pages/part-association'
import partAssociationType from './pages/part-association-type'
import partReleaseAssociation from './pages/part-release-association'
import inventoryItemConfiguration from './pages/inventory-item-configuration'
import inventoryItemConfigurationParameter from './pages/inventory-item-configuration-parameter'
import inventoryItemLocation from './pages/inventory-item-location'
import correlationIdType from './pages/correlation-id-type'
import partCorrelationIdType from './pages/part-correlation-id-type'
import inventoryItemCorrelationId from './pages/inventory-item-correlation-id'
import facility from './pages/facility'
import facilityType from './pages/facility-type'
import testProcedure from './pages/test-procedure'
import testProcedurePartCoverage from './pages/test-procedure-part-coverage'
import testVariable from './pages/test-variable'
import unitOfMeasure from './pages/unit-of-measure'
import inventoryItemTestExecution from './pages/inventory-item-test-execution'
import testObservation from './pages/test-observation'
import workEffort from './pages/work-effort'
import workEffortType from './pages/work-effort-type'
import {useAuthProvider} from './utils/auth-provider'
import {useDataProvider} from './utils/data-provider'
import {useTheme} from '@material-ui/core'

function App(props) {
  const dataProvider = useDataProvider()
  const authProvider = useAuthProvider()
  const theme = useTheme()
  return (
    dataProvider &&
    authProvider && (
      <Admin
        title="Caboodle"
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={DashBoard}
        layout={MyLayout}
        theme={theme}>
        {permissions => [
          <Resource name="partyTypes" {...partyType(permissions)} />,
          <Resource name="parties" {...party(permissions)} />,
          <Resource name="partyRoleTypes" {...partyRoleType(permissions)} />,
          <Resource name="partyRoleTypeForParties" />,
          <Resource name="partyRoles" {...partyRole(permissions)} />,
          <Resource name="organizationRoles" />,
          <Resource name="personRoles" />,
          <Resource name="expandedPartyRoles" />,
          <Resource
            name="partyAssociationTypes"
            {...partyAssociationType(permissions)}
          />,
          <Resource
            name="partyAssociations"
            {...partyAssociation(permissions)}
          />,
          <Resource name="products" {...product(permissions)} />,
          <Resource
            name="productComponents"
            {...productComponent(permissions)}
          />,
          <Resource
            name="productPartAssociations"
            {...productPartAssocation(permissions)}
          />,
          <Resource name="expandedProductPartAssociations" />,
          <Resource name="partTypes" {...partType(permissions)} />,
          <Resource name="parts" {...part(permissions)} />,
          <Resource name="expandedParts" />,
          <Resource name="partInventoryItemByStatusCounts" />,
          <Resource
            name="partAssociationTypes"
            {...partAssociationType(permissions)}
          />,
          <Resource
            name="partAssociations"
            {...partAssociation(permissions)}
          />,
          <Resource
            name="partReleaseAssociations"
            {...partReleaseAssociation(permissions)}
          />,
          <Resource name="expandedPartReleaseAssociations" />,
          <Resource name="releases" />,
          <Resource name="supplierParts" {...supplierPart(permissions)} />,
          <Resource
            name="inventoryItemStatusTypes"
            {...inventoryItemStatusType(permissions)}
          />,
          <Resource name="inventoryItemStatusTransitionTypes" />,
          <Resource name="expandedInventoryItemStatusTransitionTypes" />,
          <Resource
            name="inventoryItemConfigurationTypes"
            {...inventoryItemConfigurationType(permissions)}
          />,
          <Resource name="inventoryItems" {...inventoryItem(permissions)} />,
          <Resource name="inventoryItemAuditLogs" />,
          <Resource
            name="partInventoryItems"
            {...partInventoryItem(permissions)}
          />,
          <Resource
            name="productInventoryItems"
            {...productInventoryItem(permissions)}
          />,
          <Resource
            name="inventoryItemConfigurations"
            {...inventoryItemConfiguration(permissions)}
          />,
          <Resource name="expandedInventoryItemConfigurations" />,
          <Resource name="deployments" {...deployment(permissions)} />,
          <Resource name="deploymentItems" {...deploymentItem(permissions)} />,
          <Resource name="expandedDeploymentItems" />,
          <Resource
            name="deploymentPartyRoles"
            {...deploymentPartyRole(permissions)}
          />,
          <Resource name="expandedDeploymentPartyRoles" />,
          <Resource name="deploymentPartyRoleDeploymentItems" />,
          <Resource
            name="deploymentItemWatches"
            {...deploymentItemWatch(permissions)}
          />,
          <Resource name="expandedDeploymentItemWatches" />,
          <Resource
            name="correlationIdTypes"
            {...correlationIdType(permissions)}
          />,
          <Resource
            name="partCorrelationIdTypes"
            {...partCorrelationIdType(permissions)}
          />,
          <Resource name="expandedPartCorrelationIdTypes" />,
          <Resource
            name="inventoryItemCorrelationIds"
            {...inventoryItemCorrelationId(permissions)}
          />,
          <Resource name="expandedInventoryItemCorrelationIds" />,
          <Resource name="deploymentStatusTypes" />,
          <Resource
            name="productInventoryItemDeploymentStatuses"
            {...productInventoryItemDeploymentStatus(permissions)}
          />,
          <Resource
            name="productInventoryItemCountByDeploymentStatuses"
            {...productInventoryItemCountByDeploymentStatus(permissions)}
          />,
          <Resource name="inventoryItemConfigurationParameterTypes" />,
          <Resource
            name="inventoryItemConfigurationParameters"
            {...inventoryItemConfigurationParameter(permissions)}
          />,
          <Resource
            name="inventoryItemLocations"
            {...inventoryItemLocation(permissions)}
          />,
          <Resource name="testProcedures" {...testProcedure(permissions)} />,
          <Resource
            name="testProcedurePartCoverages"
            {...testProcedurePartCoverage(permissions)}
          />,
          <Resource name="testVariables" {...testVariable(permissions)} />,
          <Resource name="unitOfMeasures" {...unitOfMeasure(permissions)} />,
          <Resource
            name="inventoryItemTestExecutions"
            {...inventoryItemTestExecution(permissions)}
          />,
          <Resource
            name="testObservations"
            {...testObservation(permissions)}
          />,
          <Resource name="facilities" {...facility(permissions)} />,
          <Resource name="facilityTypes" {...facilityType(permissions)} />,
          <Resource name="workEfforts" {...workEffort(permissions)} />,
          <Resource name="workEffortTypes" {...workEffortType(permissions)} />,
          <Resource name="workEffortWorkerAssignments" />,
        ]}
      </Admin>
    )
  )
}

export default App
