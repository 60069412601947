import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  NumberField,
} from 'react-admin'
import {Show, DateTimeField} from '../../components'

const ProductComponentTitle = ({record}) => {
  return <span>Product Component: {record ? `${record.id}` : ''}</span>
}

const ProductComponentShow = props => (
  <Show title={<ProductComponentTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        source="productId"
        label="Component"
        reference="products"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="usedInProductId"
        label="Used in"
        reference="products"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="quantity" label="Quantity" />
      <DateTimeField source="fromDate" label="From" />
      <DateTimeField source="toDate" label="To" />
    </SimpleShowLayout>
  </Show>
)

export default ProductComponentShow
