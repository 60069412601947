import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PartyRoleTypeShow from './party-role-type-show'

const resource = {
  options: {label: 'Party Role Types'},
  show: PartyRoleTypeShow,
  icon: PersonAddIcon,
}

const supplier = () => resource

export default supplier
