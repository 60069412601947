import StoreIcon from '@material-ui/icons/Store'
import ProductInventoryItemList from './product-inventory-item-list'

const resource = {
  options: {label: 'Product Inventory Items'},
  list: ProductInventoryItemList,
  icon: StoreIcon,
}

const supplier = permissions => resource

export default supplier
