import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import TestObservationShow from './test-observation-show'

const resource = {
  options: {label: 'Test Observations'},
  show: TestObservationShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
