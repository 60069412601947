import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  AutocompleteInput,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {FacilityTitle} from './facility-lib'

const FacilityEdit = props => {
  const {permissions, ...rest} = props
  return (
    <Edit title={<FacilityTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar permissions={permissions} {...rest} />}>
        <TextInput disabled source="id" label="Id" />
        <TextInput source="name" label="Name" validate={required()} />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
        <ReferenceInput
          source="facilityTypeId"
          label="Facility Type"
          reference="facilityTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="partOfFacilityId"
          reference="facilities"
          label="Part Of">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default FacilityEdit
