import React from 'react'
import {required, TextInput} from 'react-admin'

const typePersonId = 2
const typeOrganizationId = 3

function isPerson(record) {
  return record && record.partyTypeId === typePersonId
}

function isOrganization(record) {
  return record && record.partyTypeId === typeOrganizationId
}

const PartyTitle = ({record}) => {
  return <span>Party: {record ? `${record.fullName}` : ''}</span>
}

const personInputs = [
  <TextInput source="givenName" label="Given Name" validate={required()} />,
  <TextInput source="familyName" label="Family Name" validate={required()} />,
]

const organizationInputs = (
  <TextInput source="purpose" label="Purpose" validate={required()} />
)

export {
  isPerson,
  isOrganization,
  typePersonId,
  typeOrganizationId,
  PartyTitle,
  personInputs,
  organizationInputs,
}
