const policies = {}

const denyAllPolicy = {
  canCreate: () => false,
  canEdit: () => false,
  canDelete: () => false,
}

const defaultPolicy = {
  canCreate: permissions => permissions.hasRole('internal_operator'),
  canEdit: permissions => permissions.hasRole('internal_operator'),
  canDelete: permissions => permissions.hasRole('internal_operator'),
}

policies['PartyType'] = {
  ...denyAllPolicy,
}

policies['Party'] = {
  ...defaultPolicy,
  canEdit: (permissions, record) => {
    return (
      defaultPolicy.canEdit(permissions, record) ||
      (permissions.hasRole('external_viewer') &&
        record.id === permissions.partyId)
    )
  },
}

policies['PartyRoleType'] = {
  ...denyAllPolicy,
}

policies['PartyRole'] = {
  ...defaultPolicy,
  canEdit: (permissions, record) => {
    return (
      defaultPolicy.canEdit(permissions, record) ||
      (permissions.hasRole('external_viewer') &&
        record?.forPartyId === permissions.partyId)
    )
  },
}

// TODO: BV allow edit only if
// 1) user hasRole internal_operator (or above) OR
// 2) user hasRole external_admin AND deploymentItem.deployment.partyRole.party in user org hierarchy
// 3) user hasRole external_operator AND deploymentItem.deployment.deploymentOperator.party == user
policies['DeploymentItem'] = {
  ...defaultPolicy,
  canEdit: permissions => permissions.hasRole('external_operator'),
}

// TODO: BV allow create/edit/delete only if
// 1) user hasRole internal_operator (or above) OR
// 2) user hasRole external_admin AND deploymentItem.deployment.partyRole.party in user org hierarchy
// 3) user hasRole external_operator AND deploymentItem.deployment.deploymentOperator.party == user
policies['DeploymentItemWatch'] = {
  canCreate: permissions => permissions.hasRole('external_operator'),
  canDelete: permissions => permissions.hasRole('external_operator'),
  canEdit: permissions => permissions.hasRole('external_operator'),
}

function getPolicyForType(typeName) {
  return policies[typeName] ?? defaultPolicy
}

function getPolicyForRecord(record) {
  let typeName = ''
  if (typeof record === 'string') {
    typeName = record
  } else if (record?.__typename) {
    typeName = record.__typename
  }
  return getPolicyForType(typeName)
}

class Permissions {
  constructor(principal, worker) {
    this.principal = principal
    this.worker = worker
  }
  hasRole(requiredRole) {
    return this.principal.hasRole(requiredRole)
  }
  get partyId() {
    return this.worker.forPartyId
  }
  get partyRoleId() {
    return this.worker.partyRoleId
  }
  get userName() {
    return this.principal.username
  }
  get fullName() {
    return this.worker.fullName
  }
  get preferredName() {
    return this.worker.preferredName
  }
  canCreate(record) {
    return getPolicyForRecord(record).canCreate(this)
  }
  canEdit(record) {
    return getPolicyForRecord(record).canEdit(this, record)
  }
  canDelete(record) {
    return getPolicyForRecord(record).canDelete(this, record)
  }
}

export {Permissions}
