import StoreIcon from '@material-ui/icons/Store'
import ProductPartAssociationShow from './product-part-association-show'
import ProductPartAssociationEdit from './product-part-association-edit'
import ProductPartAssociationCreate from './product-part-association-create'

const resource = {
  options: {label: 'Product Associations'},
  create: ProductPartAssociationCreate,
  edit: ProductPartAssociationEdit,
  show: ProductPartAssociationShow,
  icon: StoreIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('ProductPartAssociation')
      ? ProductPartAssociationCreate
      : null,
  }
}

export default supplier
