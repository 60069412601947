import StoreIcon from '@material-ui/icons/Store'
import InventoryItemCreate from './inventory-item-create'
import InventoryItemEdit from './inventory-item-edit'
import InventoryItemList from './inventory-item-list'
import InventoryItemShow from './inventory-item-show'

const resource = {
  options: {label: 'Inventory Items'},
  create: InventoryItemCreate,
  edit: InventoryItemEdit,
  list: InventoryItemList,
  show: InventoryItemShow,
  icon: StoreIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('InventoryItem') ? InventoryItemCreate : null,
  }
}

export default supplier
