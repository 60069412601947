import * as React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {useAuth0} from '@auth0/auth0-react'
import {Loading} from '../components/lib'

const apiUrl = process.env.REACT_APP_API_URL

const httpLink = new HttpLink({
  uri: apiUrl,
})

function createAuthenticatedClient(getAccessTokenSilently, logout) {
  const authLink = setContext(async (_, {headers}) => {
    const token = await getAccessTokenSilently()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      // logout to clear current user if auth error
      if (networkError.statusCode === 401) {
        logout({
          returnTo: window.location.origin,
        })
      }
    }
  })

  const client = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  })

  return client
}

function AuthenticatedApolloProvider(props) {
  const [client, setClient] = React.useState(null)
  const {getAccessTokenSilently, logout} = useAuth0()

  React.useEffect(() => {
    const cli = createAuthenticatedClient(getAccessTokenSilently, logout)
    setClient(() => cli)
  }, [getAccessTokenSilently, logout])

  if (!client) return <Loading />

  return <ApolloProvider client={client} {...props} />
}

export {AuthenticatedApolloProvider}
