import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  NumberInput,
  DateTimeInput,
  SelectInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {PartInput} from '../part/part-lib'
import {getPartAssociationTypeLabels} from './part-association-lib'
import {parse} from 'query-string'

const PartAssociationCreateTitle = () => {
  return <span>Create Part Association</span>
}

const PartAssociationCreate = props => {
  const {permissions, ...rest} = props

  const {
    toPartId: toPartIdString,
    partAssociationTypeId: partAssociationTypeIdString,
  } = parse(rest.location.search)

  const toPartId = toPartIdString ? parseInt(toPartIdString, 10) : ''
  const [partAssociationTypeId, setPartAssociationTypeId] = React.useState(() =>
    partAssociationTypeIdString
      ? parseInt(partAssociationTypeIdString, 10)
      : '',
  )
  const handleTypeChange = event => {
    setPartAssociationTypeId(event.target.value)
  }

  const labels = getPartAssociationTypeLabels(partAssociationTypeId)

  const redirect = toPartId ? `/parts/${toPartId}/show${labels.tab}` : 'show'

  const initialValues = {
    fromDate: new Date(),
    quantity: 1,
    toPartId,
    partAssociationTypeId,
  }

  return (
    <Create title={<PartAssociationCreateTitle />} {...rest}>
      <SimpleForm initialValues={initialValues} redirect={redirect}>
        <ReferenceInput
          label="Type"
          source="partAssociationTypeId"
          reference="partAssociationTypes"
          onChange={handleTypeChange}
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <PartInput label={labels.fromLabel} source="fromPartId" />
        <PartInput label={labels.toLabel} source="toPartId" />
        {labels.abbr === 'BOM' ? (
          <NumberInput
            label="Quantity"
            source="quantity"
            validate={required()}
          />
        ) : null}
        <DateTimeInput
          label="From Date"
          source="fromDate"
          validate={required()}
        />
        <DateTimeInput label="To Date" source="toDate" />
      </SimpleForm>
    </Create>
  )
}

export default PartAssociationCreate
