import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import PartCorrelationIdTypeShow from './part-correlation-id-type-show'
import PartCorrelationIdTypeEdit from './part-correlation-id-type-edit'
import PartCorrelationIdTypeCreate from './part-correlation-id-type-create'

const resource = {
  options: {label: 'Part External Id Types'},
  create: PartCorrelationIdTypeCreate,
  edit: PartCorrelationIdTypeEdit,
  show: PartCorrelationIdTypeShow,
  icon: DeveloperBoardIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('PartCorrelationIdType')
      ? PartCorrelationIdTypeCreate
      : null,
  }
}

export default supplier
