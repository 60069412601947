import React from 'react'
import {
  SimpleForm,
  DateTimeInput,
  SelectInput,
  required,
  ReferenceField,
  TextField,
  AutocompleteInput,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'
import {gql, useQuery} from '@apollo/client'

const GET_PARTY_ASSOC_TYPES = gql`
  query q($partyRoleId: Int) {
    partyAssociationTypeForPartyRole(partyroleid: $partyRoleId) {
      nodes {
        id
        name
      }
    }
  }
`

function usePartyAssocTypeChoices(fromPartyRoleId) {
  const {loading, error, data} = useQuery(GET_PARTY_ASSOC_TYPES, {
    variables: {partyRoleId: fromPartyRoleId},
  })
  if (loading) return []
  if (error) throw error
  return data.partyAssociationTypeForPartyRole.nodes
}

const GET_TO_PARTY_ROLES = gql`
  query q($partyAssociationTypeId: Int) {
    toPartyRoleForPartyAssociationType(
      partyassociationtypeid: $partyAssociationTypeId
    ) {
      nodes {
        id
        fullName
      }
    }
  }
`
function useToPartyRoleChoices(partyAssociationTypeId) {
  const {loading, error, data} = useQuery(GET_TO_PARTY_ROLES, {
    fetchPolicy: 'network-only',
    variables: {partyAssociationTypeId: partyAssociationTypeId},
  })
  if (loading) return []
  if (error) throw error
  return data.toPartyRoleForPartyAssociationType.nodes
}

const useFromPartyIdFromQueryString = props => {
  const {fromPartyRoleId: fromPartyRoleIdString} = parse(props.location.search)
  const fromPartyRoleId = fromPartyRoleIdString
    ? parseInt(fromPartyRoleIdString, 10)
    : ''
  return fromPartyRoleId
}

const PartyAssociationCreateTitle = () => {
  return <span>Create Party Association</span>
}

const PartyAssociationCreate = props => {
  const [partyAssociationTypeId, setPartyAssociationTypeId] = React.useState(-1)
  const handlePartyAssociationTypeChange = event =>
    setPartyAssociationTypeId(event.target.value)

  const fromPartyRoleId = useFromPartyIdFromQueryString(props)
  const redirect = fromPartyRoleId
    ? `/partyRoles/${fromPartyRoleId}/show/associations`
    : 'show'
  const initialValues = {fromPartyRoleId, fromDate: new Date()}
  const partyAssociationTypeChoices = usePartyAssocTypeChoices(fromPartyRoleId)
  const toPartyRoleChoices = useToPartyRoleChoices(partyAssociationTypeId)

  return (
    <Create title={<PartyAssociationCreateTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirect}>
        <ReferenceField
          record={initialValues}
          source="fromPartyRoleId"
          label="From Role"
          reference="expandedPartyRoles"
          link={false}>
          <TextField source="fullName" />
        </ReferenceField>
        {partyAssociationTypeChoices.length > 0 ? (
          <SelectInput
            source="partyAssociationTypeId"
            label="Type"
            choices={partyAssociationTypeChoices}
            onChange={handlePartyAssociationTypeChange}
            validate={required()}
          />
        ) : null}
        {toPartyRoleChoices.length > 0 ? (
          <AutocompleteInput
            source="toPartyRoleId"
            label="To Role"
            choices={toPartyRoleChoices}
            validate={required()}
            optionText="fullName"
          />
        ) : null}
        <DateTimeInput
          label="From Date"
          source="fromDate"
          validate={required()}
        />
        <DateTimeInput label="To Date" source="toDate" />
      </SimpleForm>
    </Create>
  )
}

export default PartyAssociationCreate
