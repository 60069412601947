import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
  TextField,
} from 'react-admin'
import {fullName} from '../part/part-lib'
import {List} from '../../components'

const SupplierPartFilter = props => (
  <Filter {...props}>
    <TextInput
      label="Search by Supplier Nbr"
      source="supplierPartNumber"
      alwaysOn
    />
    <ReferenceInput
      source="partyRoleId"
      label="Search by Supplier"
      reference="organizationRoles"
      filter={{partyRoleTypeId: 11}}>
      <SelectInput optionText="fullName" />
    </ReferenceInput>
  </Filter>
)

const SupplierPartList = props => (
  <List
    title="All Supplier Parts"
    filters={<SupplierPartFilter />}
    bulkActionButtons={false}
    sort={{field: 'supplierPartNumber', order: 'ASC'}}
    {...props}>
    <Datagrid rowClick="show">
      <TextField label="Supplier Part Number" source="supplierPartNumber" />
      <ReferenceField
        label="Internal Part Number"
        source="partId"
        reference="parts"
        link="show">
        <FunctionField render={fullName} />
      </ReferenceField>
      <ReferenceField
        label="Supplier"
        source="partyRoleId"
        reference="expandedPartyRoles"
        link={record => `/partyRoles/${record.partyRoleId}/show`}>
        <TextField source="fullName" />
      </ReferenceField>
      <DateField label="From Date" source="fromDate" />
      <DateField label="To Date" source="toDate" />
    </Datagrid>
  </List>
)

export default SupplierPartList
