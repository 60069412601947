import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {makeStyles} from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
}))

const LoginButton = () => {
  const {loginWithRedirect} = useAuth0()
  const classes = useStyles()
  return (
    <div>
      <CardActions>
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
          color="primary"
          onClick={loginWithRedirect}>
          Login To Caboodle
        </Button>
      </CardActions>
    </div>
  )
}

export default LoginButton
