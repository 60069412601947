import React from 'react'
import {
  SelectInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useEditController,
  required,
  NumberInput,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {isCustomer, isWorker, PartyRoleTitle} from './party-role-lib'

const PartyRoleEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  return (
    <Edit title={<PartyRoleTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect="show">
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          source="forPartyId"
          label="Party"
          reference="parties"
          disabled>
          <SelectInput optionText="fullName" />
        </ReferenceInput>
        <ReferenceInput
          reference="partyRoleTypes"
          source="partyRoleTypeId"
          label="Type"
          validate={required()}
          disabled>
          <SelectInput optionText="name" />
        </ReferenceInput>
        {isWorker(record) ? (
          <TextInput source="userName" label="Username" validate={required()} />
        ) : null}
        {isCustomer(record) ? (
          <NumberInput source="hubSpotId" label="Hub Spot Id" />
        ) : null}
      </SimpleForm>
    </Edit>
  )
}

export default PartyRoleEdit
