import React from 'react'
import {Filter, Datagrid, TextField, TextInput, DateField} from 'react-admin'
import {List} from '../../components'

const ProductFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by Name" source="name" alwaysOn />
  </Filter>
)

const ProductList = props => (
  <List
    title="All Products"
    filters={<ProductFilter />}
    bulkActionButtons={false}
    {...props}
    sort={{field: 'name', order: 'ASC'}}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Name" />
      <DateField source="introducedDate" label="Introduced" />
      <DateField source="discontinuedDate" label="Discontinued" />
    </Datagrid>
  </List>
)

export default ProductList
