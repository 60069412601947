import * as React from 'react'
import {Children, cloneElement} from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {ComponentPropType, useEditContext} from 'react-admin'
import {TitleForRecord} from '../layout'

const useStyles = makeStyles({
  root: {},
  card: {},
})

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  id = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  onFailure = null,
  onFailureRef = null,
  onSuccess = null,
  onSuccessRef = null,
  options = null,
  permissions = null,
  refetch = null,
  save = null,
  saving = null,
  setOnFailure = null,
  setOnSuccess = null,
  setTransform = null,
  successMessage = null,
  transform = null,
  transformRef = null,
  ...rest
}) => rest

const EditView = props => {
  const {
    actions,
    aside,
    children,
    classes: classesOverride,
    className,
    title,
    undoable,
    mutationMode,
    ...rest
  } = props

  const classes = useStyles(props)

  const {
    basePath,
    defaultTitle,
    record,
    redirect,
    resource,
    save,
    saving,
    version,
  } = useEditContext(props)

  return (
    <div
      className={classnames('edit-page', classes.root, className)}
      {...sanitizeRestProps(rest)}>
      <Card className={classes.card}>
        <TitleForRecord
          title={title}
          record={record}
          defaultTitle={defaultTitle}
        />
        {record &&
          cloneElement(Children.only(children), {
            basePath,
            record,
            redirect:
              typeof children.props.redirect === 'undefined'
                ? redirect
                : children.props.redirect,
            resource,
            save:
              typeof children.props.save === 'undefined'
                ? save
                : children.props.save,
            saving,
            undoable,
            mutationMode,
            version,
          })}
      </Card>
      {aside &&
        React.cloneElement(aside, {
          basePath,
          record,
          resource,
          version,
          save:
            typeof children.props.save === 'undefined'
              ? save
              : children.props.save,
          saving,
        })}
    </div>
  )
}

EditView.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  component: ComponentPropType,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.node,
  version: PropTypes.number,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  setOnSuccess: PropTypes.func,
  setOnFailure: PropTypes.func,
  setTransform: PropTypes.func,
  undoable: PropTypes.bool,
}

EditView.defaultProps = {
  classes: {},
}

export default EditView
