import React from 'react'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import {Menu} from './menu'
import {useSidebarToggler} from './sidebar-toggle-provider'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.sidebar.width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  drawerOpen: {
    width: theme.sidebar.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
  },
}))

function Sidebar() {
  const classes = useStyles()
  const [sidebarOpen] = useSidebarToggler()
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: sidebarOpen,
        [classes.drawerClose]: !sidebarOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        }),
      }}>
      <div className={classes.toolbar}>
        <img
          src="/betteries_logo_white.png"
          alt="betteries"
          width="157"
          height="25"
        />
      </div>
      <Menu />
    </Drawer>
  )
}

export default Sidebar
