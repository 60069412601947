import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'

const DeploymentItemTitle = () => {
  return <span>Create Deployment Item</span>
}

const DeploymentItemCreate = props => {
  const {deploymentId: deploymentIdString} = parse(props.location.search)
  const deploymentId = deploymentIdString
    ? parseInt(deploymentIdString, 10)
    : ''
  const redirectUrl = deploymentId
    ? `/deployments/${deploymentId}/show/items`
    : false
  const today = new Date()
  const initialValues = {fromDate: today, deploymentId}
  return (
    <Create title={<DeploymentItemTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="deployments"
          source="deploymentId"
          label="Deployment"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <TextInput source="alias" label="Alias" />
        <DateTimeInput
          source="fromDate"
          label="From Date"
          defaultValue={new Date()}
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
        <TextInput source="comment" label="Comment" fullWidth={true} />
      </SimpleForm>
    </Create>
  )
}

export default DeploymentItemCreate
