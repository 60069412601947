import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import {PartListField} from '../part/part-lib'
import {List} from '../../components'

const InventoryItemFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by Serial Number" source="serialNumber" alwaysOn />
    <ReferenceInput
      source="inventoryItemStatusTypeId"
      label="Search by Status"
      reference="inventoryItemStatusTypes">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const InventoryItemList = props => (
  <List
    title="All Inventory Items"
    filters={<InventoryItemFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="Id" />
      <TextField source="serialNumber" label="Serial Number" />
      <PartListField label="Part" />
      <ReferenceField
        source="inventoryItemStatusTypeId"
        label="Status"
        reference="inventoryItemStatusTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default InventoryItemList
