import React from 'react'
import {
  TextField,
  SimpleShowLayout,
  BooleanField,
  ReferenceManyField,
  SingleFieldList,
} from 'react-admin'
import {ChipFieldLink, Show} from '../../components'

const InventoryItemStatusTypeTitle = ({record}) => {
  return (
    <span>Inventory Item Status Type: {record ? `${record.name}` : ''}</span>
  )
}

const InventoryItemStatusTypeShow = props => (
  <Show title={<InventoryItemStatusTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
      <BooleanField source="initialState" label="Initial State" />
      <BooleanField source="endState" label="End State" />
      <ReferenceManyField
        label="Possible Next States"
        reference="expandedInventoryItemStatusTransitionTypes"
        target="fromInventoryItemStatusTypeId">
        <SingleFieldList linkType={false}>
          <ChipFieldLink
            link={record =>
              `/inventoryItemStatusTypes/${record.toInventoryItemStatusTypeId}/show`
            }
            source="toInventoryItemStatusTypeName"
          />
        </SingleFieldList>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export default InventoryItemStatusTypeShow
