import StoreIcon from '@material-ui/icons/Store'
import ProductCreate from './product-create'
import ProductEdit from './product-edit'
import ProductList from './product-list'
import ProductShow from './product-show'

const resource = {
  options: {label: 'Products'},
  create: ProductCreate,
  edit: ProductEdit,
  list: ProductList,
  show: ProductShow,
  icon: StoreIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('Product') ? ProductCreate : null,
  }
}

export default supplier
