import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import CorrelationIdTypeShow from './correlation-id-type-show'

const resource = {
  options: {label: 'Correlation Id Types'},
  show: CorrelationIdTypeShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
