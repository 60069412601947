import * as React from 'react'
import {Fragment} from 'react'
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import {useSidebarToggler} from './sidebar-toggle-provider'

const useStyles = makeStyles(theme => ({
  label: {color: '#f9f9f9', width: '100%'},
  icon: {
    color: '#f9f9f9',
    justifyContent: 'center',
    minWidth: theme.spacing(5),
  },
  toggleIcon: {
    color: '#f9f9f9',
    justifyContent: 'right',
  },
}))

const SubMenu = ({
  handleToggle,
  isOpen,
  name,
  icon,
  children,
  dense = false,
}) => {
  const classes = useStyles()
  const [sidebarOpen] = useSidebarToggler()
  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      {sidebarOpen ? (
        <React.Fragment>
          <Typography
            variant="inherit"
            display="block"
            className={classes.label}>
            {name}
          </Typography>
          <ListItemIcon className={classes.toggleIcon}>
            {isOpen ? <ExpandMore /> : <ChevronRightIcon />}
          </ListItemIcon>
        </React.Fragment>
      ) : null}
    </MenuItem>
  )

  return (
    <Fragment>
      {sidebarOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List dense={dense} component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default SubMenu
