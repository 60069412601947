import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  AutocompleteInput,
} from 'react-admin'
import {Create} from '../../components/'

const FacilityTitle = () => {
  return <span>Create Facility</span>
}

const FacilityCreate = props => {
  const {permissions, ...rest} = props
  return (
    <Create title={<FacilityTitle />} {...rest}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Name" validate={required()} />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
        <ReferenceInput
          source="facilityTypeId"
          label="Facility Type"
          reference="facilityTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="partOfFacilityId"
          reference="facilities"
          label="Part Of">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default FacilityCreate
