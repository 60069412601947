import React from 'react'
import {
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  useEditController,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {TestVariableTitle} from './test-variable-lib'

const TestVariableEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/testProcedures/${record.testProcedureId}/show/testVariables`
  return (
    <Edit title={<TestVariableTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Name" validate={required()} />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
        <ReferenceInput
          reference="unitOfMeasures"
          source="unitOfMeasureId"
          label="Unit Of Measure"
          validate={required()}>
          <AutocompleteInput optionText="symbol" />
        </ReferenceInput>
        <NumberInput source="minimumValue" label="Minimum Value" />
        <NumberInput source="maximumValue" label="Maximum Value" />
      </SimpleForm>
    </Edit>
  )
}

export default TestVariableEdit
