import React from 'react'
import uPlot from 'uplot'
import 'uplot/dist/uPlot.min.css'
import UplotReact from 'uplot-react'
import * as d3TimeFormat from 'd3-time-format'
import * as d3Format from 'd3-format'

function timeFormatter(formatString) {
  const fmt = d3TimeFormat.timeFormat(formatString)
  return value => fmt(new Date(value))
}

function channelFormatter(ch) {
  const fmt = d3Format.format(ch.format)
  return value => `${fmt(value)}${ch.unit}`
}

const xTickFmt = timeFormatter('%H:%M:%S')
const xValueFmt = timeFormatter('%Y-%m-%d %H:%M:%S')

function TelemetryChart({channelNames, channels}) {
  const timeSync = uPlot.sync('time')
  const matchSyncKeys = (own, ext) => own === ext
  const cursorOpts = {
    lock: true,
    focus: {
      prox: 16,
    },
    sync: {
      key: timeSync.key,
      setSeries: true,
      match: [matchSyncKeys, matchSyncKeys],
    },
  }

  function makeChart(channel) {
    const yFmt = channelFormatter(channel)

    const opts = {
      title: channel.label,
      width: 1000,
      height: 200,
      focus: {
        alpha: 0.3,
      },
      cursor: cursorOpts,
      series: [
        {
          value: (u, v) => xValueFmt(v),
          scale: 's',
        },
        {
          label: channel.label,
          value: (u, v) => yFmt(v),
          scale: channel.unit,
          stroke: channel.color,
        },
      ],
      axes: [
        {
          values: (u, vals, space) => vals.map(v => xTickFmt(v)),
          scale: 's',
        },
        {
          values: (u, vals, space) => vals.map(v => yFmt(v)),
          scale: channel.unit,
        },
      ],
    }

    return <UplotReact options={opts} data={channel.series} />
  }

  const rows = []
  for (let channelName of channelNames) {
    if (channels[channelName]?.show) {
      const channel = channels[channelName]
      rows.push(makeChart(channel))
    }
  }

  return <div style={{height: '100%', width: '100%'}}>{rows}</div>
}

export {TelemetryChart}
