import PeopleIcon from '@material-ui/icons/People'
import PartyAssociationTypeShow from './party-association-type-show'

const resource = {
  options: {label: 'Party Association Types'},
  show: PartyAssociationTypeShow,
  icon: PeopleIcon,
}

const supplier = () => resource

export default supplier
