import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {typeConsigneeId} from '../party-role/party-role-lib'
import {parse} from 'query-string'
import {DeploymentNameField} from './deployment-lib'

const DeploymentTitle = () => {
  return <span>Create Deployment</span>
}

const DeploymentCreate = props => {
  const {permissions, ...rest} = props
  const {partyRoleId: partyRoleIdString} = parse(props.location.search)
  const partyRoleId = partyRoleIdString ? parseInt(partyRoleIdString, 10) : ''
  const redirect = partyRoleId
    ? `/partyRoles/${partyRoleId}/show/deployments`
    : 'show'
  const initialValues = {partyRoleId}
  const nameRef = React.useRef('')
  const transform = data => ({
    ...data,
    name: nameRef.current,
  })
  return (
    <Create title={<DeploymentTitle />} transform={transform} {...rest}>
      <SimpleForm initialValues={initialValues} redirect={redirect}>
        <DeploymentNameField nameRef={nameRef} />
        <ReferenceInput
          source="partyRoleId"
          reference="expandedPartyRoles"
          label="Consignee"
          filter={{partyRoleTypeId: typeConsigneeId}}
          validate={required()}>
          <AutocompleteInput optionText="preferredName" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          defaultValue={new Date()}
          validate={required()}
        />
        <TextInput source="qualifier" label="Qualifier" />
        <DateTimeInput source="toDate" label="To Date" />
        <TextInput source="comment" label="Comment" fullWidth={true} />
      </SimpleForm>
    </Create>
  )
}

export default DeploymentCreate
