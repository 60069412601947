import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import WorkEffortTypeShow from './work-effort-type-show'

const resource = {
  options: {label: 'Work Effort Types'},
  show: WorkEffortTypeShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
