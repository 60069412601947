import React from 'react'
import {
  TextField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  DateField,
  ShowButton,
  EditButton,
  SingleFieldList,
  Pagination,
} from 'react-admin'
import {
  ChipFieldLink,
  DateTimeField,
  Show,
  ShowToolbar,
  TelemetryPanel,
} from '../../components'
import AddInventoryItemConfigurationButton from '../inventory-item-configuration/add-inventory-item-configuration-button'
import AddInventoryItemConfigurationParameterButton from '../inventory-item-configuration-parameter/add-inventory-item-configuration-parameter-button'
import AddInventoryItemLocationButton from '../inventory-item-location/add-inventory-item-location-button'
import {PartFormField, PartListField} from '../part/part-lib'
import {InventoryItemTitle} from './inventory-item-lib'
import {withRouter} from 'react-router'
import AddInventoryItemCorrelationIdButton from '../inventory-item-correlation-id/add-inventory-item-correlation-id-button'
import ReplaceInventoryItemConfigurationButton from '../inventory-item-configuration/replace-inventory-item-configuration-button'

const InventoryItemShowActions = ({basePath, data, permissions, location}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) && location.pathname.endsWith('show') ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
    {data &&
    permissions.canCreate('InventoryItemConfiguration') &&
    location.pathname.endsWith('configuration') ? (
      <AddInventoryItemConfigurationButton record={data} />
    ) : null}
    {data &&
    permissions.canCreate('InventoryItemConfigurationParameter') &&
    location.pathname.endsWith('configurationParameters') ? (
      <AddInventoryItemConfigurationParameterButton record={data} />
    ) : null}
    {data &&
    permissions.canCreate('InventoryItemLocation') &&
    location.pathname.endsWith('locations') ? (
      <AddInventoryItemLocationButton record={data} />
    ) : null}
    {data &&
    permissions.canCreate('InventoryItemCorrelationId') &&
    location.pathname.endsWith('show') ? (
      <AddInventoryItemCorrelationIdButton record={data} />
    ) : null}
  </ShowToolbar>
)

const InventoryItemShowLayout = props => (
  <TabbedShowLayout>
    <Tab label="Detail">
      <TextField source="id" label="Id" />
      <TextField source="serialNumber" label="Serial Number" />
      <ReferenceField
        source="inventoryItemStatusTypeId"
        label="Status"
        reference="inventoryItemStatusTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <PartFormField label="Part" />
      <ReferenceManyField
        label="External Ids"
        reference="expandedInventoryItemCorrelationIds"
        target="inventoryItemId">
        <SingleFieldList>
          <ChipFieldLink
            source="correlationIdLabel"
            link={record => `/inventoryItemCorrelationIds/${record.id}/show`}
          />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="comment" label="Comment" />
    </Tab>
    <Tab label="Configuration" path="configuration">
      <ReferenceManyField
        label="Containing Inventory Items"
        reference="expandedInventoryItemConfigurations"
        pagination={<Pagination />}
        perPage={5}
        target="usedInInventoryItemId"
        sort={{field: 'fromDate', order: 'DESC'}}>
        <Datagrid>
          <ReferenceField
            reference="inventoryItems"
            source="inventoryItemId"
            label="Item"
            link="show">
            <TextField source="serialNumber" />
          </ReferenceField>
          <ReferenceField
            reference="inventoryItems"
            source="inventoryItemId"
            label="Part"
            link={false}>
            <PartListField link={false} />
          </ReferenceField>
          <DateTimeField label="From Date" source="fromDate" />
          <DateTimeField label="To Date" source="toDate" />
          <ReferenceField
            reference="inventoryItemConfigurationTypes"
            source="inventoryItemConfigurationTypeId"
            label="Reason"
            link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ShowButton
            basePath="/inventoryItemConfigurations"
            resource="inventoryItemConfigurations"
          />
          <ReplaceInventoryItemConfigurationButton />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Used in Inventory Items"
        reference="expandedInventoryItemConfigurations"
        pagination={<Pagination />}
        perPage={5}
        target="inventoryItemId"
        sort={{field: 'fromDate', order: 'DESC'}}>
        <Datagrid>
          <ReferenceField
            reference="inventoryItems"
            source="usedInInventoryItemId"
            label="Item"
            link="show">
            <TextField source="serialNumber" />
          </ReferenceField>
          <ReferenceField
            reference="inventoryItems"
            source="usedInInventoryItemId"
            label="Part"
            link={false}>
            <PartListField link={false} />
          </ReferenceField>
          <DateField label="From Date" source="fromDate" />
          <DateField label="To Date" source="toDate" />
          <ReferenceField
            reference="inventoryItemConfigurationTypes"
            source="inventoryItemConfigurationTypeId"
            label="Reason"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <ShowButton
            basePath="/inventoryItemConfigurations"
            resource="inventoryItemConfigurations"
          />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
    <Tab label="Parameters" path="configurationParameters">
      <ReferenceManyField
        label="Configuration Parameters"
        reference="inventoryItemConfigurationParameters"
        target="inventoryItemId">
        <Datagrid>
          <ReferenceField
            source="configurationParameterTypeId"
            label="Type"
            reference="inventoryItemConfigurationParameterTypes"
            link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Value" source="value" />
          <ShowButton
            basePath="/inventoryItemConfigurationParameters"
            resource="inventoryItemConfigurationParameters"
          />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
    <Tab label="Locations" path="locations">
      <ReferenceManyField
        label="Storage Location"
        reference="inventoryItemLocations"
        target="inventoryItemId"
        sort={{field: 'fromDate', order: 'DESC'}}>
        <Datagrid>
          <ReferenceField
            source="facilityId"
            reference="facilities"
            label="Stored At"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <DateField label="From Date" source="fromDate" />
          <DateField label="To Date" source="toDate" />
          <ShowButton
            basePath="/inventoryItemLocations"
            resource="inventoryItemLocations"
          />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Deployments"
        reference="expandedDeploymentItems"
        target="inventoryItemId"
        sort={{field: 'fromDate', order: 'DESC'}}>
        <Datagrid>
          <TextField source="deploymentName" label="Name" />
          <ReferenceField
            label="Assigned Party"
            source="partyId"
            reference="parties"
            link="show">
            <TextField source="fullName" />
          </ReferenceField>
          <TextField label="Comment" source="comment" />
          <DateField label="From Date" source="fromDate" />
          <DateField label="To Date" source="toDate" />
          <ShowButton basePath="/deploymentItems" reference="deploymentItems" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
    <Tab label="Telemetry" path="telemetry">
      <TelemetryPanel />
    </Tab>
    <Tab label="History" path="history">
      <ReferenceManyField
        label="Audit Log"
        reference="inventoryItemAuditLogs"
        target="inventoryItemId">
        <Datagrid>
          <ReferenceField
            source="inventoryItemStatusTypeId"
            label="Status"
            reference="inventoryItemStatusTypes"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Comment" source="comment" />
          <TextField label="User" source="operationBy" />
          <DateField showTime={true} label="Date" source="operationAt" />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
    <Tab label="Test Executions" path="testExecutions">
      <ReferenceManyField
        pagination={<Pagination />}
        perPage={10}
        label="Test Executions"
        reference="inventoryItemTestExecutions"
        sort={{field: 'executedAt', order: 'DESC'}}
        target="inventoryItemId">
        <Datagrid>
          <ReferenceField
            source="testProcedureId"
            label="Test Procedure"
            reference="testProcedures"
            link="show">
            <TextField source="name" />
          </ReferenceField>
          <DateTimeField source="executedAt" label="Executed At" />
          <BooleanField source="passed" label="Passed" />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </Tab>
  </TabbedShowLayout>
)

const InventoryItemShow = ({permissions, ...rest}) => (
  <Show
    title={<InventoryItemTitle />}
    actions={<InventoryItemShowActions permissions={permissions} {...rest} />}
    {...rest}>
    <InventoryItemShowLayout permissions={permissions} />
  </Show>
)

export default withRouter(InventoryItemShow)
