import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  TextInput,
  BooleanInput,
  useListContext,
} from 'react-admin'
import {typeConsigneeId} from '../party-role/party-role-lib'
import {List} from '../../components'

const DeploymentFilter = props => {
  const {setFilters} = useListContext(props)
  const handleSetFilters = (filters, displayedFilters, debounce) => {
    // isActive = (fromDate <= now) and (toDate is null or toDate < now)
    if (filters.isActive) {
      const now = new Date()
      filters.fromDate = {
        operator: 'lessThanOrEqualTo',
        value: now.toISOString(),
      }
      // TODO: toDate is null or toDate < now
      filters.toDate = {operator: 'isNull', value: true}
    } else {
      delete filters.fromDate
      delete filters.toDate
    }
    setFilters(filters, displayedFilters, debounce)
  }
  return (
    <Filter {...props} setFilters={handleSetFilters}>
      <TextInput source="name" label="Search by Name" alwaysOn />
      <BooleanInput
        source="isActive"
        label="Active Only"
        defaultChecked={false}
        alwaysOn
      />
      <ReferenceInput
        source="partyRoleId"
        label="Search by Consignee"
        reference="expandedPartyRoles"
        filter={{partyRoleTypeId: typeConsigneeId}}
        filterToQuery={searchText => ({preferredName: searchText})}>
        <AutocompleteInput optionText="preferredName" />
      </ReferenceInput>
    </Filter>
  )
}

const DeploymentList = props => (
  <List
    title="All Deployments"
    filters={<DeploymentFilter />}
    bulkActionButtons={false}
    sort={{field: 'name', order: 'ASC'}}
    {...props}>
    <Datagrid rowClick="show">
      <TextField label="Name" source="name" />
      <ReferenceField
        source="partyRoleId"
        reference="expandedPartyRoles"
        label="Consignee"
        link={record => `/partyRoles/${record.partyRoleId}/show`}>
        <TextField source="preferredName" />
      </ReferenceField>
      <TextField label="Comment" source="comment" />
      <DateField label="From Date" source="fromDate" />
      <DateField label="To Date" source="toDate" />
    </Datagrid>
  </List>
)

export default DeploymentList
