import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddPartCorrelationIdTypeButton = ({partId, ...rest}) => {
  return (
    <Button
      component={Link}
      type="button"
      variant="contained"
      size="medium"
      to={`/partCorrelationIdTypes/create?partId=${partId}`}
      label="Add external id type"
      title="Add external id type">
      <AddCircleIcon />
    </Button>
  )
}

export default AddPartCorrelationIdTypeButton
