import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import PartTypeShow from './part-type-show'

const resource = {
  options: {label: 'Part Types'},
  show: PartTypeShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
