import React from 'react'
import {
  TextField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  NumberField,
  DateField,
  TabbedShowLayout,
  Tab,
  ShowButton,
  Pagination,
  EditButton,
  SingleFieldList,
  ChipField,
  Labeled,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import AddPartAssociationButton from '../part-association/add-part-association-button'
import AddPartReleaseAssociationButton from '../part-release-association/add-part-release-association-button'
import AddProductPartAssociationButton from '../product-part-association/add-product-part-association-button'
import {isFinishedGood, PartTitle, PartListField} from './part-lib'
import {
  PartRevisionAncestorList,
  PartRevisionDescendantList,
} from './part-revision-list'
import {withRouter} from 'react-router'
import AddInventoryItemButton from '../inventory-item/add-inventory-item-button'
import {PartInventoryItemSummaryChart} from '../../components'
import AddPartCorrelationIdTypeButton from '../part-correlation-id-type/add-part-correlation-id-type-button'

const PartShowActions = ({basePath, data, permissions, location}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) && location.pathname.endsWith('show') ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
    {data &&
    permissions.canCreate('PartCorrelationIdType') &&
    location.pathname.endsWith('show') ? (
      <AddPartCorrelationIdTypeButton partId={data.id} record={data} />
    ) : null}
    {data &&
    permissions.canCreate('PartAssociation') &&
    location.pathname.endsWith('boms') ? (
      <AddPartAssociationButton partAssociationTypeId={1} record={data} />
    ) : null}
    {data &&
    permissions.canCreate('PartAssociation') &&
    location.pathname.endsWith('revisions') ? (
      <AddPartAssociationButton partAssociationTypeId={3} record={data} />
    ) : null}
    {data &&
    isFinishedGood(data) &&
    permissions.canCreate('ProductPartAssociation') &&
    location.pathname.endsWith('show') ? (
      <AddProductPartAssociationButton record={data} />
    ) : null}
    {data &&
    isFinishedGood(data) &&
    permissions.canCreate('PartReleaseAssociation') &&
    location.pathname.endsWith('show') ? (
      <AddPartReleaseAssociationButton record={data} />
    ) : null}
    {data &&
    permissions.canCreate('InventoryItem') &&
    location.pathname.endsWith('inventory') ? (
      <AddInventoryItemButton partId={data.id} />
    ) : null}
  </ShowToolbar>
)

const PartShowLayout = props => {
  const {record} = props
  return (
    <TabbedShowLayout>
      <Tab label="Detail">
        <TextField source="id" label="Id" />
        <TextField source="description" label="Description" />
        <TextField source="partNumber" label="Part Number" />
        <TextField source="revision" label="Revision" />
        {isFinishedGood(record) ? (
          [
            <ReferenceManyField
              label="Implements Product"
              reference="productPartAssociations"
              target="partId">
              <SingleFieldList linkType="show">
                <ReferenceField
                  source="productId"
                  reference="products"
                  link={false}>
                  <ChipField source="name" />
                </ReferenceField>
              </SingleFieldList>
            </ReferenceManyField>,
            <ReferenceManyField
              label="Release"
              reference="partReleaseAssociations"
              target="partId">
              <SingleFieldList linkType="show">
                <ReferenceField
                  source="releaseId"
                  reference="releases"
                  link={false}>
                  <ChipField source="name" />
                </ReferenceField>
              </SingleFieldList>
            </ReferenceManyField>,
          ]
        ) : (
          <ReferenceManyField
            label="External Id Types"
            reference="partCorrelationIdTypes"
            target="partId">
            <SingleFieldList linkType="show">
              <ReferenceField
                source="correlationIdTypeId"
                reference="correlationIdTypes"
                link={false}>
                <ChipField source="name" />
              </ReferenceField>
            </SingleFieldList>
          </ReferenceManyField>
        )}
        <ReferenceField
          source="partTypeId"
          label="Type"
          reference="partTypes"
          link={false}>
          <TextField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="BOM" path="boms">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Including Parts"
          reference="partAssociations"
          target="toPartId"
          filter={{
            partAssociationTypeId: [1, 2],
          }}>
          <Datagrid>
            <PartListField label="Part" source="fromPartId" />
            <NumberField label="Quantity" source="quantity" />
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Included in Parts"
          reference="partAssociations"
          target="fromPartId"
          filter={{
            partAssociationTypeId: [1, 2],
          }}>
          <Datagrid>
            <PartListField label="Part" source="toPartId" />
            <NumberField label="Quantity" source="quantity" />
            <DateField label="From Date" source="fromDate" />
            <DateField label="To Date" source="toDate" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Revisions" path="revisions">
        <Labeled label="Prior Revisions">
          <PartRevisionAncestorList partId={record.id} />
        </Labeled>
        <Labeled label="Later Revisions">
          <PartRevisionDescendantList partId={record.id} />
        </Labeled>
      </Tab>
      <Tab label="Inventory" path="inventory">
        <ReferenceManyField
          label="Inventory Item Summary"
          reference="partInventoryItemByStatusCounts"
          sort={{field: 'inventoryItemStatusTypeId', order: 'ASC'}}
          target="partId">
          <PartInventoryItemSummaryChart />
        </ReferenceManyField>
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Inventory Items"
          reference="inventoryItems"
          target="partId">
          <Datagrid>
            <TextField source="serialNumber" label="Serial Number" />
            <ReferenceField
              source="inventoryItemStatusTypeId"
              label="Status"
              reference="inventoryItemStatusTypes"
              link={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="comment" label="Comment" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  )
}

const PartShow = props => {
  const {permissions, location, ...rest} = props
  return (
    <Show
      title={<PartTitle />}
      actions={
        <PartShowActions
          permissions={permissions}
          location={location}
          {...rest}
        />
      }
      {...props}>
      <PartShowLayout permissions={permissions} />
    </Show>
  )
}

export default withRouter(PartShow)
