import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'

export const AddTestProcedurePartCoverageButton = props => {
  const {record} = props
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      component={Link}
      to={`/testProcedurePartCoverages/create?testProcedureId=${record.id}`}
      label="Add Part Coverage"
      title="Add Part Coverage">
      <AddCircleIcon />
    </Button>
  )
}

export default AddTestProcedurePartCoverageButton
