import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {PartTitle} from './part-lib'

const PartEdit = ({permissions, ...rest}) => {
  return (
    <Edit title={<PartTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect="show">
        <TextInput disabled source="id" label="Id" />
        <TextInput
          source="description"
          label="Description"
          validate={required()}
        />
        <ReferenceInput
          source="partTypeId"
          label="Type"
          reference="partTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="partNumber"
          label="Part Number"
          validate={required()}
        />
        <TextInput source="revision" label="Revision" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}

export default PartEdit
