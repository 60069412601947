import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import PartAssociationShow from './part-association-show'
import PartAssociationCreate from './part-association-create'
import PartAssociationEdit from './part-association-edit'

const resource = {
  options: {label: 'Part Associations'},
  create: PartAssociationCreate,
  edit: PartAssociationEdit,
  show: PartAssociationShow,
  icon: DeveloperBoardIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('PartAssociation')
      ? PartAssociationCreate
      : null,
  }
}

export default supplier
