import React from 'react'
import {SimpleForm, TextInput, DateTimeInput, required, NumberInput} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {ProductTitle} from './product-lib'

const ProductEdit = ({permissions, ...rest}) => (
  <Edit title={<ProductTitle />} {...rest}>
    <SimpleForm
      toolbar={<EditToolbar permissions={permissions} {...rest} />}
      redirect="show"
      {...rest}>
      <TextInput disabled source="id" label="Id" />
      <TextInput source="name" label="Name" validate={required()} />
      <TextInput
        source="description"
        label="Description"
        validate={required()}
      />
      <DateTimeInput
        source="introducedDate"
        label="Introduced Date"
        validate={required()}
      />
      <DateTimeInput source="discontinuedDate" label="Discontinued Data" />
      <NumberInput source="appNodeLevel" label="Application Node Level" disabled/>
    </SimpleForm> 
  </Edit>
)

export default ProductEdit
