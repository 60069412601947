import React from 'react'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import {LoadingIndicator} from 'react-admin'
import LogoutMenuItem from './logout-menu-item'
import UserMenu from './user-menu'
import {useSidebarToggler} from './sidebar-toggle-provider'
import SidebarToggleButton from './sidebar-toggle-button'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: theme.sidebar.width,
    width: `calc(100% - ${theme.sidebar.width}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  spacer: {
    flex: 1,
  },
}))

function MyAppBar(props) {
  const [sidebarOpen] = useSidebarToggler()
  const classes = useStyles()
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: sidebarOpen,
      })}>
      <Toolbar>
        <SidebarToggleButton />
        <span className={classes.spacer} />
        <LoadingIndicator />
        <UserMenu {...props} logout={<LogoutMenuItem />} />
      </Toolbar>
    </AppBar>
  )
}

export default MyAppBar
