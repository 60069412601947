import React from 'react'
import {
  SelectInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useEditController,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {
  isPerson,
  isOrganization,
  PartyTitle,
  personInputs,
  organizationInputs,
} from './party-lib'

const PartyEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  return (
    <Edit title={<PartyTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect="show">
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          source="partyTypeId"
          label="Type"
          reference="partyTypes"
          disabled
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="fullName" label="Full Name" validate={required()} />
        <TextInput
          source="preferredName"
          label="Preferred Name"
          validate={required()}
        />
        {isPerson(record)
          ? personInputs
          : isOrganization(record)
          ? organizationInputs
          : null}
      </SimpleForm>
    </Edit>
  )
}

export default PartyEdit
