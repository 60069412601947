import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  useEditController,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {InventoryItemConfigurationTitle} from './inventory-item-configuration-lib'

const InventoryItemConfigurationEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/inventoryItems/${record.usedInInventoryItemId}/show/configuration`
  return (
    <Edit title={<InventoryItemConfigurationTitle />} {...props}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          source="inventoryItemConfigurationTypeId"
          label="Type"
          reference="inventoryItemConfigurationTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <ReferenceInput
          reference="inventoryItems"
          source="usedInInventoryItemId"
          label="Used in Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
      </SimpleForm>
    </Edit>
  )
}

export default InventoryItemConfigurationEdit
