import React from 'react'
import {TextField, SimpleShowLayout} from 'react-admin'
import {Show} from '../../components'

const PartTypeTitle = ({record}) => {
  return <span>Part Type: {record ? `${record.name}` : ''}</span>
}

const PartTypeShow = props => (
  <Show title={<PartTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
    </SimpleShowLayout>
  </Show>
)

export default PartTypeShow
