import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  useEditController,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {InventoryItemLocationTitle} from './inventory-item-location-lib'

const InventoryItemLocationEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/inventoryItems/${record.inventoryItemId}/show/locations`
  return (
    <Edit title={<InventoryItemLocationTitle />} {...props}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <ReferenceInput
          source="facilityId"
          reference="facilities"
          label="Stored At"
          filterToQuery={searchText => ({name: searchText})}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
      </SimpleForm>
    </Edit>
  )
}

export default InventoryItemLocationEdit
