import React from 'react'
import {
  SimpleForm,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
  DateTimeInput,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {PartInput} from '../part/part-lib'
import {SupplierPartTitle} from './supplier-part-lib'
import {typeSupplierId} from '../party-role/party-role-lib'

const SupplierPartEdit = ({permissions, ...rest}) => (
  <Edit title={<SupplierPartTitle />} {...rest}>
    <SimpleForm
      toolbar={<EditToolbar permissions={permissions} {...rest} />}
      redirect="show">
      <TextInput disabled source="id" label="Id" />
      <ReferenceInput
        label="Supplier"
        source="partyRoleId"
        reference="expandedPartyRoles"
        filter={{partyRoleTypeId: typeSupplierId}}
        filterToQuery={searchText => ({fullName: searchText})}
        validate={required()}>
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <PartInput />
      <TextInput
        source="supplierPartNumber"
        label="Supplier Part Number"
        validate={required()}
      />
      <NumberInput
        source="safetyStockQuantity"
        label="Safety Stock Quantity"
        validate={required()}
      />
      <NumberInput
        source="leadTimeDays"
        label="Lead Time Days"
        validate={required()}
      />
      <TextInput source="comment" label="Comment" />
      <DateTimeInput
        source="fromDate"
        label="From Date"
        validate={required()}
      />
      <DateTimeInput source="toDate" label="To Date" />
    </SimpleForm>
  </Edit>
)

export default SupplierPartEdit
