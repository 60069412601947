import * as React from 'react'
import PropTypes from 'prop-types'
import MuiToolbar from '@material-ui/core/Toolbar'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
  },
  desktopToolbar: {
    marginTop: theme.spacing(2),
  },
  mobileToolbar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '16px',
    width: '100%',
    boxSizing: 'border-box',
    flexShrink: 0,
    zIndex: 2,
  },
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    [theme.breakpoints.down('xs')]: {
      height: '5em',
    },
  },
}))

const ShowToolbar = props => {
  const {children, className, width, ...rest} = props
  const classes = useStyles(props)
  return (
    <React.Fragment>
      <MuiToolbar
        className={classnames(
          classes.toolbar,
          {
            [classes.mobileToolbar]: width === 'xs',
            [classes.desktopToolbar]: width !== 'xs',
          },
          className,
        )}
        role="toolbar"
        {...rest}>
        {children}
      </MuiToolbar>
      <div className={classes.spacer} />
    </React.Fragment>
  )
}

ShowToolbar.propTypes = {
  className: PropTypes.string,
}

export default ShowToolbar
