import FingerprintIcon from '@material-ui/icons/Fingerprint'
import InventoryItemCorrelationIdShow from './inventory-item-correlation-id-show'
import InventoryItemCorrelationIdCreate from './inventory-item-correlation-id-create'
import InventoryItemCorrelationIdEdit from './inventory-item-correlation-id-edit'
import InventoryItemCorrelationIdList from './inventory-item-correlation-id-list'

const resource = {
  options: {label: 'Inventory Item External Ids'},
  create: InventoryItemCorrelationIdCreate,
  edit: InventoryItemCorrelationIdEdit,
  show: InventoryItemCorrelationIdShow,
  list: InventoryItemCorrelationIdList,
  icon: FingerprintIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('InventoryItemCorrelationId')
      ? InventoryItemCorrelationIdCreate
      : null,
  }
}

export default supplier
