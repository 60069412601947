import React from 'react'

const InventoryItemCorrelationIdTitle = ({record}) => {
  return (
    <span>
      Inventory Item External Id: {record ? `${record.correlationId}` : ''}
    </span>
  )
}

export default InventoryItemCorrelationIdTitle
