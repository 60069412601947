import WidgetsIcon from '@material-ui/icons/Widgets'
import InventoryItemConfigurationTypeShow from './inventory-item-configuration-type-show'

const resource = {
  options: {label: 'Inventory Config Types'},
  show: InventoryItemConfigurationTypeShow,
  icon: WidgetsIcon,
}

const supplier = () => resource

export default supplier
