import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
} from 'react-admin'
import {List} from '../../components'

const PartyFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by Name" source="fullName" alwaysOn />
    <ReferenceInput
      source="partyTypeId"
      label="Search by Type"
      reference="partyTypes"
      filter={{abstract: false}}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const PartyList = props => (
  <List
    title="All Parties"
    filters={<PartyFilter />}
    bulkActionButtons={false}
    sort={{field: 'fullName', order: 'ASC'}}
    {...props}>
    <Datagrid rowClick="show">
      <TextField source="fullName" label="Full Name" />
      <ReferenceField
        source="partyTypeId"
        label="Type"
        reference="partyTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceManyField
        label="Roles"
        reference="partyRoles"
        target="forPartyId"
        link={false}>
        <SingleFieldList linkType="show">
          <ReferenceField
            source="partyRoleTypeId"
            reference="partyRoleTypes"
            link={false}>
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
    </Datagrid>
  </List>
)

export default PartyList
