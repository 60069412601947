import React from 'react'
import {
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  Datagrid,
  TopToolbar,
  ExportButton,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'

const PartyRoleListActions = props => (
  <TopToolbar>
    {cloneElement(props.filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const PartyRoleFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="partyRoleTypeId"
      label="Search by Type"
      reference="partyRoleTypes"
      filter={{abstract: false}}
      alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const PartyRoleList = props => (
  <List
    title="All Party Roles"
    filters={<PartyRoleFilter />}
    bulkActionButtons={false}
    actions={<PartyRoleListActions />}
    {...props}>
    <Datagrid rowClick="show">
      <ReferenceField
        source="forPartyId"
        label="Party"
        reference="parties"
        link="show">
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField
        source="partyRoleTypeId"
        label="Type"
        reference="partyRoleTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default PartyRoleList
