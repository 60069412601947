import React from 'react'
import {
  TextField,
  ReferenceField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ShowButton,
} from 'react-admin'
import {Show} from '../../components'

const PartyTypeTitle = ({record}) => {
  return <span>Party Type: {record ? `${record.name}` : ''}</span>
}

const PartyTypeShow = props => (
  <Show title={<PartyTypeTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" label="Id" />
        <TextField source="name" label="Name" />
        <ReferenceField
          source="superPartyTypeId"
          label="Super"
          reference="partyTypes"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField label="Abstract" source="abstract" />
      </Tab>
      <Tab label="Instances">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Instances"
          reference="parties"
          target="partyTypeId">
          <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="fullName" label="Full Name" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default PartyTypeShow
