import * as React from 'react'
import PropTypes from 'prop-types'
import {useListController, ListContextProvider} from 'ra-core'
import {TitlePropType} from '../layout'
import ListView from './ListView'

const List = props => {
  const controllerProps = useListController(props)
  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} />
    </ListContextProvider>
  )
}

List.propTypes = {
  // the props you can change
  // @ts-ignore-line
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  aside: PropTypes.element,
  // @ts-ignore-line
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  // @ts-ignore-line
  pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  perPage: PropTypes.number.isRequired,
  //@ts-ignore-line
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: TitlePropType,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  location: PropTypes.any,
  match: PropTypes.any,
  path: PropTypes.string,
  resource: PropTypes.string,
  syncWithLocation: PropTypes.bool,
}

List.defaultProps = {
  filter: {},
  perPage: 10,
}

export default List
