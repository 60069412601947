import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
  TopToolbar,
  ExportButton,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'

const PartInventoryItemListActions = ({permissions, filters}) => (
  <TopToolbar>
    {cloneElement(filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const PartInventoryItemFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by Part Number" source="partNumber" alwaysOn />
    <TextInput label="Search by Serial Number" source="serialNumber" alwaysOn />
    <TextInput label="Search by Revision" source="partRevision" />
    <ReferenceInput
      source="inventoryItemStatusTypeId"
      label="Search by Status"
      reference="inventoryItemStatusTypes">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const PartInventoryItemList = ({permissions, ...rest}) => (
  <List
    title="All Part Inventory Items"
    filters={<PartInventoryItemFilter />}
    actions={
      <PartInventoryItemListActions permissions={permissions} {...rest} />
    }
    sort={{field: 'serialNumber', order: 'ASC'}}
    bulkActionButtons={false}
    {...rest}>
    <Datagrid rowClick={(id, basePath, record) => `/inventoryItems/${id}/show`}>
      <TextField source="serialNumber" label="Serial Number" />
      <TextField source="partNumber" label="Part Number" />
      <TextField source="partRevision" label="Revision" />
      <TextField source="partDescription" label="Part Description" />
      <ReferenceField
        source="inventoryItemStatusTypeId"
        label="Status"
        reference="inventoryItemStatusTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="partTypeId"
        label="Type"
        reference="partTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="usedInInventoryItemId"
        label="Used In"
        reference="inventoryItems"
        link="show">
        <TextField source="serialNumber" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default PartInventoryItemList
