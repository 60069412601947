import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {TimeRange} from './time-range'

const telemetryTypes = {
  voltage: 1,
  current: 2,
  temperature: 3,
  soc: 5,
}

function createTelemetryClient(getAccessTokenSilently) {
  const serverUrl = process.env.REACT_APP_TELEMETRY_URL
  const getTelemetry = async (
    serialNumber,
    telemetryTypeId,
    timeRange = new TimeRange(),
  ) => {
    const token = await getAccessTokenSilently()
    const [beginTime, endTime] = timeRange.calculateEndpoints()
    const parameters = {
      deviceName: serialNumber,
      telemetryTypeId: telemetryTypeId,
      beginTime: beginTime.toISO(),
      endTime: endTime.toISO(),
    }
    const response = await fetch(serverUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(parameters),
    })
    return await response.json()
  }
  return {getTelemetry}
}

function useTelemetryProvider() {
  const [client, setClient] = React.useState(null)
  const {getAccessTokenSilently} = useAuth0()
  React.useEffect(() => {
    if (getAccessTokenSilently) {
      setClient(createTelemetryClient(getAccessTokenSilently))
    }
  }, [getAccessTokenSilently])
  return client
}

export {telemetryTypes, useTelemetryProvider, TimeRange}
