import React from 'react'
import {TextField, SimpleShowLayout} from 'react-admin'
import {Show} from '../../components'

const CorrelationIdTypeTitle = ({record}) => {
  return <span>External Id Type: {record ? `${record.name}` : ''}</span>
}

const CorrelationIdTypeShow = props => (
  <Show title={<CorrelationIdTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
    </SimpleShowLayout>
  </Show>
)

export default CorrelationIdTypeShow
