import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {PartReleaseAssociationTitle} from './part-release-association-lib'
import {Show, ShowToolbar} from '../../components'
import {PartFormField} from '../part/part-lib'

const PartReleaseAssociationActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const PartReleaseAssociationShow = ({permissions, ...rest}) => (
  <Show
    title={<PartReleaseAssociationTitle />}
    actions={
      <PartReleaseAssociationActions permissions={permissions} {...rest} />
    }
    {...rest}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <PartFormField label="Part" source="partId" />
      <ReferenceField
        source="releaseId"
        label="Release"
        reference="releases"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export default PartReleaseAssociationShow
