import {DateTime, Duration} from 'luxon'

const defaultDuration = Duration.fromObject({hours: 3})

class TimeRange {
  now = () => DateTime.now().toUTC()
  beginTime
  endTime
  beginTimeOffset
  endTimeOffset

  constructor(beginTimeOffset = defaultDuration, endTime) {
    this.beginTimeOffset = beginTimeOffset
    this.endTime = endTime
  }

  calculateEndpoints() {
    const nt = this.now()
    let et = DateTime.min(nt, this.endTime ?? nt)
    if (this.endTimeOffset) {
      et = et.minus(this.endTimeOffset)
    }
    let bt = DateTime.min(et, this.beginTime ?? et)
    if (this.beginTimeOffset) {
      bt = bt.minus(this.beginTimeOffset)
    }
    return [bt, et]
  }
}

export {TimeRange, defaultDuration}
