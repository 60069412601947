import WidgetsIcon from '@material-ui/icons/Widgets'
import InventoryItemConfigurationShow from './inventory-item-configuration-show'
import InventoryItemConfigurationEdit from './inventory-item-configuration-edit'
import InventoryItemConfigurationCreate from './inventory-item-configuration-create'

const resource = {
  options: {label: 'Inventory Item Configurations'},
  show: InventoryItemConfigurationShow,
  edit: InventoryItemConfigurationEdit,
  create: InventoryItemConfigurationCreate,
  icon: WidgetsIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('InventoryItemConfiguration')
      ? InventoryItemConfigurationCreate
      : null,
  }
}

export default supplier
