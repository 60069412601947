import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import TestVariableCreate from './test-variable-create'
import TestVariableEdit from './test-variable-edit'
import TestVariableShow from './test-variable-show'

const resource = {
  options: {label: 'Test Variables'},
  create: TestVariableCreate,
  edit: TestVariableEdit,
  show: TestVariableShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('TestVariable') ? TestVariableCreate : null,
  }
}

export default supplier
