import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {fullName} from '../part/part-lib'

const ProductPartAssociationTitle = ({record}) => {
  return <span>Product Part Association: {record ? `${record.id}` : ''}</span>
}

const ProductPartAssociationActions = ({basePath, data, permissions}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const ProductPartAssociationShow = ({permissions, ...rest}) => (
  <Show
    title={<ProductPartAssociationTitle />}
    actions={
      <ProductPartAssociationActions permissions={permissions} {...rest} />
    }
    {...rest}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="Part"
        source="partId"
        reference="parts"
        link="show">
        <FunctionField render={fullName} />
      </ReferenceField>
      <ReferenceField
        label="Implements Product"
        source="productId"
        reference="products"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateTimeField label="From" source="fromDate" />
      <DateTimeField label="To" source="toDate" />
    </SimpleShowLayout>
  </Show>
)

export default ProductPartAssociationShow
