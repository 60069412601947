import StoreIcon from '@material-ui/icons/Store'
import PartReleaseAssociationCreate from './part-release-association-create'
import PartReleaseAssociationEdit from './part-release-association-edit'
import PartReleaseAssociationShow from './part-release-association-show'

const resource = {
  options: {label: 'PartReleaseAssociations'},
  create: PartReleaseAssociationCreate,
  edit: PartReleaseAssociationEdit,
  show: PartReleaseAssociationShow,
  icon: StoreIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('PartReleaseAssociation')
      ? PartReleaseAssociationCreate
      : null,
  }
}

export default supplier
