import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Login from './pages/login'
import hcbgImage from "./pages/login/login_bg_optim.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: 'url('+hcbgImage+')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}))

function App() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Login />
    </div>
  )
}

export default App
