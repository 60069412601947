import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {InventoryItemLocationTitle} from './inventory-item-location-lib'

const InventoryItemLocationShowActions = ({basePath, data, permissions}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const InventoryItemLocationShowLayout = props => (
  <SimpleShowLayout>
    <TextField source="id" label="Id" />
    <ReferenceField
      reference="inventoryItems"
      source="inventoryItemId"
      label="Item"
      link="show">
      <TextField source="serialNumber" />
    </ReferenceField>
    <ReferenceField
      source="facilityId"
      reference="facilities"
      label="Stored At"
      link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateTimeField label="From Date" source="fromDate" />
    <DateTimeField label="To Date" source="toDate" />
  </SimpleShowLayout>
)

const InventoryItemLocationShow = ({permissions, ...rest}) => (
  <Show
    title={<InventoryItemLocationTitle />}
    actions={
      <InventoryItemLocationShowActions permissions={permissions} {...rest} />
    }
    {...rest}>
    <InventoryItemLocationShowLayout permissions={permissions} />
  </Show>
)

export default InventoryItemLocationShow
