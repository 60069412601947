import * as React from 'react'
import SubMenu from './sub-menu'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import WidgetsIcon from '@material-ui/icons/Widgets'
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import BallotIcon from '@material-ui/icons/Ballot'
import Dashboard from '@material-ui/icons/Dashboard'
import MenuItemLink from './menu-item-link'

const menuItems = [
  {
    name: 'setup',
    label: 'Setup',
    icon: PersonIcon,
    resources: [
      {name: 'parties', label: 'Parties'},
      {name: 'partyRoles', label: 'Party Roles'},
      {name: 'partyAssociations', label: 'Party Associations'},
      {name: 'facilities', label: 'Facilities'},
    ],
  },
  {
    name: 'definitions',
    label: 'Definitions',
    icon: BusinessIcon,
    resources: [
      {name: 'products', label: 'Products'},
      {name: 'parts', label: 'Parts'},
      {name: 'supplierParts', label: 'Supplier Parts'},
      {name: 'testProcedures', label: 'Test Procedures'},
    ],
  },
  {
    name: 'inventory',
    label: 'Inventory',
    icon: WidgetsIcon,
    resources: [
      {name: 'partInventoryItems', label: 'Part Inventory'},
      {name: 'productInventoryItems', label: 'Product Inventory'},
      {name: 'inventoryItemCorrelationIds', label: 'External Ids'},
    ],
  },
  {
    name: 'deployments',
    label: 'Deployments',
    icon: AirportShuttleIcon,
    resource: 'deployments',
  },
  {
    name: 'reports',
    label: 'Reports',
    icon: BallotIcon,
    resources: [
      {
        name: 'productInventoryItemCountByDeploymentStatuses',
        label: 'Deployment Status',
      },
    ],
  },
]

function initSubMenuState() {
  return menuItems.reduce((a, b) => ({...a, [b.name]: false}), {})
}

export const NavBullet = props => <span>&bull;</span>

export const Menu = props => {
  const [state, setState] = React.useState(initSubMenuState)

  const handleToggle = menu => {
    setState(state => ({...state, [menu]: !state[menu]}))
  }

  const createSubMenuItems = resources => {
    return resources.map(resource => (
      <MenuItemLink
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={resource.label}
        leftIcon={<NavBullet />}
      />
    ))
  }

  const createMenuItem = menu => {
    if (menu.resource) {
      return (
        <MenuItemLink
          key={menu.name}
          to={`/${menu.resource}`}
          primaryText={menu.label}
          leftIcon={<menu.icon />}
        />
      )
    } else {
      return (
        <SubMenu
          key={menu.name}
          handleToggle={() => handleToggle(menu.name)}
          isOpen={state[menu.name]}
          name={menu.label}
          icon={<menu.icon />}>
          {createSubMenuItems(menu.resources)}
        </SubMenu>
      )
    }
  }

  const dashboardMenuItem = (
    <MenuItemLink
      key="dashboard"
      to="/"
      primaryText="Dashboard"
      leftIcon={<Dashboard />}
    />
  )

  return (
    <React.Fragment>
      {dashboardMenuItem}
      {menuItems.map(createMenuItem)}
    </React.Fragment>
  )
}
