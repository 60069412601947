import React from 'react'
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'
import {
  typePersonId,
  typeOrganizationId,
  personInputs,
  organizationInputs,
} from './party-lib'

const PartyCreateTitle = () => {
  return <span>Create Party</span>
}

const PartyCreate = props => {
  const [partyTypeId, setPartyTypeId] = React.useState(-1)
  const handleTypeChange = event => setPartyTypeId(event.target.value)
  return (
    <Create title={<PartyCreateTitle />} {...props}>
      <SimpleForm redirect="show">
        <ReferenceInput
          source="partyTypeId"
          label="Type"
          reference="partyTypes"
          filter={{abstract: false}}
          onChange={handleTypeChange}
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="fullName" label="Full Name" validate={required()} />
        <TextInput
          source="preferredName"
          label="Preferred Name"
          validate={required()}
        />
        {partyTypeId === typePersonId
          ? personInputs
          : partyTypeId === typeOrganizationId
          ? organizationInputs
          : null}
      </SimpleForm>
    </Create>
  )
}

export default PartyCreate
