import * as React from 'react'
import {Children, cloneElement} from 'react'
import PropTypes from 'prop-types'
import {useCreateContext} from 'react-admin'
import {Card} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classnames from 'classnames'
import {TitleForRecord} from '../layout'

const useStyles = makeStyles(theme => ({
  root: {},
  card: {},
}))

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  onFailure = null,
  onFailureRef = null,
  onSuccess = null,
  onSuccessRef = null,
  options = null,
  permissions = null,
  save = null,
  saving = null,
  setOnFailure = null,
  setOnSuccess = null,
  setTransform = null,
  transform = null,
  transformRef = null,
  ...rest
}) => rest

const CreateView = props => {
  const {
    actions,
    aside,
    children,
    classes: classesOverride,
    className,
    title,
    ...rest
  } = props

  const classes = useStyles(props)

  const {basePath, record, redirect, resource, save, saving, version} =
    useCreateContext(props)

  return (
    <div
      className={classnames('create-page', classes.root, className)}
      {...sanitizeRestProps(rest)}>
      <Card className={classes.card}>
        <TitleForRecord title={title} record={record} />
        {record &&
          cloneElement(Children.only(children), {
            basePath,
            record,
            redirect:
              typeof children.props.redirect === 'undefined'
                ? redirect
                : children.props.redirect,
            resource,
            save:
              typeof children.props.save === 'undefined'
                ? save
                : children.props.save,
            saving,
            version,
          })}
      </Card>
      {aside &&
        cloneElement(aside, {
          basePath,
          record,
          resource,
          save:
            typeof children.props.save === 'undefined'
              ? save
              : children.props.save,
          saving,
          version,
        })}
    </div>
  )
}

CreateView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.node,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  setOnSuccess: PropTypes.func,
  setOnFailure: PropTypes.func,
  setTransform: PropTypes.func,
}

CreateView.defaultProps = {
  classes: {},
}

export default CreateView
