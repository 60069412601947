import React from 'react'
import {SimpleForm, TextInput, DateTimeInput, required} from 'react-admin'
import {Create} from '../../components/'

const ProductCreateTitle = () => {
  return <span>Create Product</span>
}

const ProductCreate = props => (
  <Create title={<ProductCreateTitle />} {...props}>
    <SimpleForm redirect="show" initialValues={{introducedDate: new Date()}}>
      <TextInput source="name" label="Name" validate={required()} />
      <TextInput
        source="description"
        label="Description"
        validate={required()}
      />
      <DateTimeInput
        source="introducedDate"
        label="Introduced Date"
        validate={required()}
      />
      <DateTimeInput source="discontinuedDate" label="Discontinued Data" />
    </SimpleForm>
  </Create>
)

export default ProductCreate
