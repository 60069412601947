import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  useEditController,
  required,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {InventoryItemConfigurationParameterTitle} from './inventory-item-configuration-parameter-lib'

const InventoryItemConfigurationParameterEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/inventoryItems/${record.inventoryItemId}/show/configurationParameters`
  return (
    <Edit title={<InventoryItemConfigurationParameterTitle />} {...props}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <ReferenceInput
          source="configurationParameterTypeId"
          label="Type"
          reference="inventoryItemConfigurationParameterTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="value" label="Value" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}

export default InventoryItemConfigurationParameterEdit
