import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  NumberInput,
  DateTimeInput,
  SelectInput,
  required,
  TextField,
  useEditController,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {PartInput} from '../part/part-lib'
import {
  PartAssociationTitle,
  getPartAssociationTypeLabels,
} from './part-association-lib'

const PartAssociationEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(rest)
  const [partAssociationTypeId, setPartAssociationTypeId] = React.useState(
    record.partAssociationTypeId,
  )
  const handleTypeChange = event => {
    setPartAssociationTypeId(event.target.value)
  }
  const labels = getPartAssociationTypeLabels(partAssociationTypeId)
  const redirect = record
    ? `/parts/${record.toPartId}/show${labels.tab}`
    : 'show'
  return (
    <Edit title={<PartAssociationTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextField source="id" label="Id" disabled />
        <ReferenceInput
          label="Type"
          source="partAssociationTypeId"
          reference="partAssociationTypes"
          onChange={handleTypeChange}
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <PartInput label={labels.fromLabel} source="fromPartId" />
        <PartInput label={labels.toLabel} source="toPartId" />
        {labels.abbr === 'BOM' ? (
          <NumberInput
            label="Quantity"
            source="quantity"
            validate={required()}
          />
        ) : null}
        <DateTimeInput
          label="From Date"
          source="fromDate"
          validate={required()}
        />
        <DateTimeInput label="To Date" source="toDate" />
      </SimpleForm>
    </Edit>
  )
}

export default PartAssociationEdit
