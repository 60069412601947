import React from 'react'
import {useCurrentPrincipal} from './current-principal'
import {useCurrentWorker} from './current-worker'
import {Permissions} from './permissions'

class AuthProvider {
  constructor(principal, worker) {
    this.permissions = new Permissions(principal, worker)
    this.identity = {id: worker.forPartyId, fullName: worker.fullName}
  }
  login() {
    return Promise.resolve()
  }
  checkError() {
    return Promise.resolve()
  }
  checkAuth() {
    return Promise.resolve()
  }
  logout() {
    return Promise.resolve()
  }
  getIdentity() {
    return Promise.resolve(this.identity)
  }
  getPermissions() {
    return Promise.resolve(this.permissions)
  }
}

function useAuthProvider() {
  const currentPrincipal = useCurrentPrincipal()
  const currentWorker = useCurrentWorker()
  const [authProvider, setAuthProvider] = React.useState(null)

  React.useEffect(() => {
    if (currentPrincipal && currentWorker) {
      setAuthProvider(new AuthProvider(currentPrincipal, currentWorker))
    }
  }, [currentPrincipal, currentWorker])

  return authProvider
}

export {AuthProvider, useAuthProvider}
