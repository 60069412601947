import React from 'react'
import {
  SimpleForm,
  TextInput,
  useEditController,
  required,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'
import {DeploymentItemWatchTitle} from './deployment-item-watch-lib'

const DeploymentItemWatchEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(props)
  const redirect = `/partyRoles/${record.partyRoleId}/show/watchItems`
  return (
    <Edit title={<DeploymentItemWatchTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextInput disabled source="id" label="Id" />
        <ReferenceInput
          reference="expandedPartyRoles"
          source="partyRoleId"
          label="Party Role"
          disabled>
          <SelectInput optionText="fullName" />
        </ReferenceInput>
        <ReferenceInput
          reference="deploymentPartyRoleDeploymentItems"
          source="deploymentItemId"
          filterToQuery={searchText => ({
            serialNumber: searchText,
          })}
          label="Deployment Item"
          validate={required()}
          disabled>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <BooleanInput label="Flagged" source="flagged" defaultValue={false} />
      </SimpleForm>
    </Edit>
  )
}

export default DeploymentItemWatchEdit
