import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {FacilityTitle} from './facility-lib'

const FacilityActions = ({permissions, basePath, data}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const FacilityShow = props => {
  const {permissions} = props
  return (
    <Show
      title={<FacilityTitle />}
      actions={<FacilityActions permissions={permissions} />}
      {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="Id" />
        <TextField label="Name" source="name" />
        <TextField label="Description" source="description" />
        <ReferenceField
          source="facilityTypeId"
          reference="facilityTypes"
          label="Facility Type"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="partOfFacilityId"
          reference="facilities"
          label="Part Of"
          link="show">
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  )
}

export default FacilityShow
