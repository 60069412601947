const partAssociationTypeLabels = {
  1: {
    abbr: 'BOM',
    tab: '/boms',
    name: 'Engineering BOM',
    help: 'BOM for initial design',
    fromLabel: 'Component Part',
    toLabel: 'Included in',
  },
  2: {
    abbr: 'BOM',
    tab: '/boms',
    name: 'Manufacturing BOM',
    help: 'BOM for part substitutions in manufacturing',
    fromLabel: 'Component Part',
    toLabel: 'Included in',
  },
  3: {
    abbr: 'Revision',
    tab: '/revisions',
    name: 'Class 1 Change',
    help: 'Functional change that requires external update',
    fromLabel: 'Component Part',
    toLabel: 'Based on',
  },
  4: {
    abbr: 'Revision',
    tab: '/revisions',
    name: 'Class 2 Change',
    help: 'Technical change that requires internal update',
    fromLabel: 'Revised Part',
    toLabel: 'Based on',
  },
  5: {
    abbr: 'Revision',
    tab: '/revisions',
    name: 'Class 3 Change',
    help: 'Administrative change that requires no update',
    fromLabel: 'Revised Part',
    toLabel: 'Based on',
  },
  6: {
    abbr: 'Substitution',
    tab: '',
    name: 'Part Substitution',
    help: 'Part is substitute for other part',
    fromLabel: 'Substitute Part',
    toLabel: 'Substitute for',
  },
  7: {
    abbr: 'Incompatibility',
    tab: '',
    name: 'Part Incompatibility',
    help: 'Part is incompatible with other part',
    fromLabel: 'Incompatible Part',
    toLabel: 'Incompatible with',
  },
  default: {
    abbr: 'Association',
    tab: '',
    name: 'Part Association',
    help: 'Association between two parts',
    fromLabel: 'From',
    toLabel: 'To',
  },
}

function getPartAssociationTypeLabels(partAssociationTypeId) {
  return (
    partAssociationTypeLabels[partAssociationTypeId] ??
    partAssociationTypeLabels.default
  )
}

const PartAssociationTitle = ({record}) => {
  return <span>Part Association: {record ? `${record.id}` : ''}</span>
}

export {PartAssociationTitle, getPartAssociationTypeLabels}
