import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import DeploymentItemWatchCreate from './deployment-item-watch-create'
import DeploymentItemWatchEdit from './deployment-item-watch-edit'
import DeploymentItemWatchShow from './deployment-item-watch-show'

const resource = {
  options: {label: 'Deployment Item Watches'},
  create: DeploymentItemWatchCreate,
  edit: DeploymentItemWatchEdit,
  show: DeploymentItemWatchShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('DeploymentItemWatch')
      ? DeploymentItemWatchCreate
      : null,
  }
}

export default supplier
