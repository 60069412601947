import React from 'react'
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  TextInput,
} from 'react-admin'
import {Create} from '../../components'
import {parse} from 'query-string'

const InventoryItemConfigurationParameterCreate = props => {
  const {inventoryItemId: inventoryItemIdString} = parse(props.location.search)

  const inventoryItemId = inventoryItemIdString
    ? parseInt(inventoryItemIdString, 10)
    : null

  const redirectUrl = inventoryItemId
    ? `/inventoryItems/${inventoryItemId}/show/configurationParameters`
    : false

  const initialValues = {
    inventoryItemId,
  }

  return (
    <Create title="Create Inventory Item Configuration Parameter" {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="inventoryItems"
          source="inventoryItemId"
          label="Item"
          filterToQuery={searchText => ({serialNumber: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <ReferenceInput
          source="configurationParameterTypeId"
          label="Type"
          reference="inventoryItemConfigurationParameterTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="value" label="Value" validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default InventoryItemConfigurationParameterCreate
