import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {getPartAssociationTypeLabels} from './part-association-lib'

export const AddPartAssociationButton = ({partAssociationTypeId, record}) => {
  const labels = getPartAssociationTypeLabels(partAssociationTypeId)
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      component={Link}
      to={`/partAssociations/create?toPartId=${record.id}&partAssociationTypeId=${partAssociationTypeId}`}
      label={`Add ${labels.abbr}`}
      title={`Add ${labels.abbr}`}>
      <AddCircleIcon />
    </Button>
  )
}

export default AddPartAssociationButton
