import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  DateTimeInput,
  required,
  TextField,
  AutocompleteInput,
  useEditController,
} from 'react-admin'
import {PartInput} from '../part/part-lib'
import {Edit, EditToolbar} from '../../components'
import {ProductPartAssociationTitle} from './product-part-association-lib'

const ProductPartAssociationEdit = props => {
  const {permissions, ...rest} = props
  const {record} = useEditController(rest)
  const redirect = record ? `/parts/${record.partId}/show` : 'show'
  return (
    <Edit title={<ProductPartAssociationTitle />} {...rest}>
      <SimpleForm
        toolbar={<EditToolbar permissions={permissions} {...rest} />}
        redirect={redirect}>
        <TextField source="id" label="Id" disabled />
        <PartInput label="Part" source="partId" />
        <ReferenceInput
          label="Implements Product"
          source="productId"
          reference="products"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateTimeInput
          label="From Date"
          source="fromDate"
          validate={required()}
        />
        <DateTimeInput label="To Date" source="toDate" />
      </SimpleForm>
    </Edit>
  )
}

export default ProductPartAssociationEdit
