import React from 'react'
import {
  ReferenceField,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  Labeled,
  required,
} from 'react-admin'

const typeFinishedGoodId = 1
const typeSubassemblyId = 2
const typeRawMaterial = 3

function isFinishedGood(record) {
  return record && record.partTypeId === typeFinishedGoodId
}

function isSubassembly(record) {
  return record && record.partTypeId === typeSubassemblyId
}

function isRawMaterial(record) {
  return record && record.partTypeId === typeRawMaterial
}

const partNumberAndRevision = record =>
  record ? `${record.partNumber} ${record.revision}` : ''

const fullName = record => {
  return `${record.partNumber} ${record.revision}`
}

const PartTitle = ({record}) => {
  return <span>Part: {record ? `${fullName(record)}` : ''}</span>
}

const PartFormField = props => {
  const {label = 'Part', source = 'partId', link = 'show', ...rest} = props
  return (
    <Labeled label={label}>
      <ReferenceField {...rest} source={source} reference="parts" link={link}>
        <FunctionField render={fullName} />
      </ReferenceField>
    </Labeled>
  )
}

const PartListField = props => {
  const {source = 'partId', link = 'show', ...rest} = props
  return (
    <ReferenceField source={source} reference="parts" link={link} {...rest}>
      <FunctionField render={fullName} />
    </ReferenceField>
  )
}

function filterByPartNumberAndRevision(searchText) {
  const revisionStart = searchText.indexOf(' ')
  if (revisionStart !== -1) {
    return {
      partNumber: searchText.slice(0, revisionStart),
      revision: searchText.slice(revisionStart + 1),
    }
  } else {
    return {partNumber: searchText}
  }
}

const PartInput = props => {
  const {label = 'Part', source = 'partId', ...rest} = props
  return (
    <ReferenceInput
      label={label}
      source={source}
      reference="parts"
      filterToQuery={filterByPartNumberAndRevision}
      validate={required()}
      {...rest}>
      <AutocompleteInput optionText={partNumberAndRevision} />
    </ReferenceInput>
  )
}

export {
  fullName,
  isFinishedGood,
  isSubassembly,
  isRawMaterial,
  partNumberAndRevision,
  PartTitle,
  PartListField,
  PartFormField,
  PartInput,
  typeRawMaterial,
}
