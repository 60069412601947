import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  TopToolbar,
  ExportButton,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'
import {parse} from 'query-string'

const ProductInventoryItemDeploymentStatusListActions = ({
  permissions,
  filters,
}) => (
  <TopToolbar>
    {cloneElement(filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const ProductInventoryItemDeploymentStatusFilter = props => (
  <Filter {...props}>
    <TextInput source="partNumber" label="Part Number" alwaysOn />
    <TextInput source="partRevision" label="Revision" alwaysOn />
    <ReferenceInput
      source="deploymentStatusId"
      label="Deployment Status"
      reference="deploymentStatusTypes"
      sort={{field: 'id', order: 'ASC'}}
      alwaysOn>
      <SelectInput source="name" />
    </ReferenceInput>
    <TextInput source="serialNumber" label="Serial Number" />
    <ReferenceInput
      source="releaseId"
      label="Release"
      reference="releases"
      link={false}>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const ProductInventoryItemDeploymentStatusList = props => {
  const {permissions, ...rest} = props

  const {
    partNumber: partNumberString,
    partRevision: partRevisionString,
    deploymentStatusId: deploymentStatusIdString,
  } = parse(props.location.search)

  const deploymentStatusId = deploymentStatusIdString
    ? parseInt(deploymentStatusIdString, 10)
    : null

  return (
    <List
      {...props}
      title="Product Inventory Items by Deployment Status"
      filters={<ProductInventoryItemDeploymentStatusFilter />}
      filterDefaultValues={{
        partNumber: partNumberString,
        partRevision: partRevisionString,
        deploymentStatusId: deploymentStatusId,
      }}
      actions={
        <ProductInventoryItemDeploymentStatusListActions
          permissions={permissions}
          {...rest}
        />
      }
      sort={{field: 'serialNumber', order: 'ASC'}}
      bulkActionButtons={false}
      {...rest}>
      <Datagrid
        rowClick={(id, basePath, record) => `/inventoryItems/${id}/show`}>
        <TextField source="serialNumber" label="Serial Number" />
        <TextField source="partNumber" label="Part Number" />
        <TextField source="partRevision" label="Revision" />
        <ReferenceField
          source="releaseId"
          label="Release"
          reference="releases"
          link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="inventoryItemStatusTypeId"
          label="Inventory Item Status"
          reference="inventoryItemStatusTypes"
          link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="deploymentStatusId"
          label="Deployment Status"
          reference="deploymentStatusTypes"
          link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="pastDeploymentId"
          label="Past Deployment"
          reference="deployments"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="deploymentId"
          label="Current Deployment"
          reference="deployments"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="futureDeploymentId"
          label="Future Deployment"
          reference="deployments"
          link="show">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

export default ProductInventoryItemDeploymentStatusList
