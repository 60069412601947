import React from 'react'
import {
  TextField,
  Datagrid,
  DateField,
  ShowButton,
  useList,
  ListContextProvider,
  Loading,
  Pagination,
} from 'react-admin'
import {gql, useQuery} from '@apollo/client'
import {PartListField} from './part-lib'

const GET_PART_REVISION_ANCESTOR = gql`
  query q($partId: Int) {
    partRevisionAncestor(partid: $partId) {
      nodes {
        id
        partAssociationTypeId
        fromPartId
        toPartId
        quantity
        comment
        fromDate
        toDate
      }
    }
  }
`

const GET_PART_REVISION_DESCENDANT = gql`
  query q($partId: Int) {
    partRevisionDescendant(partid: $partId) {
      nodes {
        id
        partAssociationTypeId
        fromPartId
        toPartId
        quantity
        comment
        fromDate
        toDate
      }
    }
  }
`

function usePartRevisionAncestorData(partId) {
  const {loading, error, data} = useQuery(GET_PART_REVISION_ANCESTOR, {
    variables: {partId: partId},
    fetchPolicy: 'cache-and-network',
  })
  if (loading)
    return {
      loading: true,
      data: [],
      ids: [],
    }
  if (error) throw error
  const nds = data.partRevisionAncestor.nodes
  const ids = nds.map(nd => nd.id)
  return {
    loading: false,
    loaded: true,
    data: nds,
    ids: ids,
    total: ids.length,
  }
}

function usePartRevisionDescendantData(partId) {
  const {loading, error, data} = useQuery(GET_PART_REVISION_DESCENDANT, {
    variables: {partId: partId},
    fetchPolicy: 'cache-and-network',
  })
  if (loading)
    return {
      loading: true,
      data: [],
      ids: [],
    }
  if (error) throw error
  const nds = data.partRevisionDescendant.nodes
  const ids = nds.map(nd => nd.id)
  return {
    loading: false,
    loaded: true,
    data: nds,
    ids: ids,
    total: ids.length,
  }
}

const PartRevisionAncestorList = props => {
  const data = usePartRevisionAncestorData(props.partId)
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const [sort, setSort] = React.useState({field: 'id', order: 'ASC'})
  const listContext = useList({
    ...data,
    perPage: perPage,
    page: page,
    sort: sort,
    basePath: '/partAssociations',
    resource: 'partAssociations',
  })
  return data.loading ? (
    <Loading />
  ) : (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <PartListField label="Part" source="toPartId" />
        <TextField label="Comment" source="comment" />
        <DateField label="From Date" source="fromDate" />
        <DateField label="To Date" source="toDate" />
        <ShowButton basePath="/partAssociations" resource="partAssociations" />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        setSort={setSort}
        total={data.total}
      />
    </ListContextProvider>
  )
}

const PartRevisionDescendantList = props => {
  const data = usePartRevisionDescendantData(props.partId)
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const [sort, setSort] = React.useState({field: 'id', order: 'ASC'})
  const listContext = useList({
    ...data,
    perPage: perPage,
    page: page,
    sort: sort,
    basePath: '/partAssociations',
    resource: 'partAssociations',
  })
  return data.loading ? (
    <Loading />
  ) : (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <PartListField label="Part" source="fromPartId" />
        <TextField label="Comment" source="comment" />
        <DateField label="From Date" source="fromDate" />
        <DateField label="To Date" source="toDate" />
        <ShowButton basePath="/partAssociations" resource="partAssociations" />
      </Datagrid>
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        setSort={setSort}
        total={data.total}
      />
    </ListContextProvider>
  )
}

export {PartRevisionAncestorList, PartRevisionDescendantList}
