import React from 'react'
import pgDataProvider from 'ra-postgraphile'
import {useApolloClient} from '@apollo/client'

const pgDataProviderConfig = {
  typeMap: {
    PartyType: {
      expand: false,
    },
    Party: {
      expand: false,
    },
  },
}

function useDataProvider() {
  const client = useApolloClient()
  const [dataProvider, setDataProvider] = React.useState(null)

  React.useEffect(() => {
    ;(async () => {
      const dp = await pgDataProvider(client, pgDataProviderConfig)
      setDataProvider(() => dp)
    })()
  }, [client])

  return dataProvider
}

export {useDataProvider}
