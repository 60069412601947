import React from 'react'
import {SimpleForm, SelectInput, ReferenceInput, required} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'
import {PartInput} from '../part/part-lib'

const PartCorrelationIdTypeTitle = () => {
  return <span>Create Part External Id Type</span>
}

const PartCorrelationIdTypeCreate = props => {
  const {partId: partIdString} = parse(props.location.search)
  const partId = partIdString ? parseInt(partIdString, 10) : ''
  const redirect = partId ? `/parts/${partId}/show` : 'show'
  const initialValues = {partId}
  return (
    <Create title={<PartCorrelationIdTypeTitle />} {...props}>
      <SimpleForm redirect={redirect} initialValues={initialValues}>
        <PartInput />
        <ReferenceInput
          source="correlationIdTypeId"
          label="External Id Type"
          reference="correlationIdTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default PartCorrelationIdTypeCreate
