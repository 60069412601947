import React from 'react'
import {
  DateTimeInput,
  ReferenceField,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import {Edit, EditToolbar} from '../../components'

const PartyAssociationTitle = ({record}) => {
  return <span>Party Association: {record ? `${record.id}` : ''}</span>
}

const PartyAssociationEdit = ({permissions, ...rest}) => (
  <Edit title={<PartyAssociationTitle />} {...rest}>
    <SimpleForm toolbar={<EditToolbar permissions={permissions} {...rest} />}>
      <TextInput disabled source="id" label="Id" />
      <ReferenceField
        label="From Role"
        source="fromPartyRoleId"
        reference="expandedPartyRoles"
        link={false}>
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField
        label="Type"
        source="partyAssociationTypeId"
        reference="partyAssociationTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="To Role"
        source="toPartyRoleId"
        reference="expandedPartyRoles"
        link={false}>
        <TextField source="fullName" />
      </ReferenceField>
      <DateTimeInput
        source="fromDate"
        label="From Date"
        validate={required()}
      />
      <DateTimeInput source="toDate" label="To Date" />
    </SimpleForm>
  </Edit>
)

export default PartyAssociationEdit
