import WidgetsIcon from '@material-ui/icons/Widgets'
import InventoryItemConfigurationParameterShow from './inventory-item-configuration-parameter-show'
import InventoryItemConfigurationParameterEdit from './inventory-item-configuration-parameter-edit'
import InventoryItemConfigurationParameterCreate from './inventory-item-configuration-parameter-create'

const resource = {
  options: {label: 'Inventory Item Configurations'},
  show: InventoryItemConfigurationParameterShow,
  edit: InventoryItemConfigurationParameterEdit,
  create: InventoryItemConfigurationParameterCreate,
  icon: WidgetsIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('InventoryItemConfigurationParameter')
      ? InventoryItemConfigurationParameterCreate
      : null,
  }
}

export default supplier
