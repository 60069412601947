import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
} from 'react-admin'
import {Create} from '../../components/'

const PartTitle = () => {
  return <span>Create Part</span>
}

const PartCreate = ({permissions, ...rest}) => {
  return (
    <Create title={<PartTitle />} {...rest}>
      <SimpleForm redirect="show">
        <TextInput source="description" label="Description" />
        <ReferenceInput
          source="partTypeId"
          label="Type"
          reference="partTypes"
          validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="partNumber"
          label="Part Number"
          validate={required()}
        />
        <TextInput source="revision" label="Revision" validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default PartCreate
