import BuildIcon from '@material-ui/icons/Build'
import PartInventoryItemList from './part-inventory-item-list'

const resource = {
  options: {label: 'Part Inventory Items'},
  list: PartInventoryItemList,
  icon: BuildIcon,
}

const supplier = permissions => resource

export default supplier
