import React from 'react'
import {
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  ReferenceInput,
  NumberInput,
} from 'react-admin'
import {Create, Error, Loading} from '../../components'
import {
  typeWorkerId,
  typeDirectCustomerId,
  typeIndirectCustomerId,
} from './party-role-lib'
import {parse} from 'query-string'
import {gql, useQuery} from '@apollo/client'

const GET_PARTY_ROLE_TYPES = gql`
  query q($partyId: Int) {
    partyRoleTypeForParty(partyid: $partyId) {
      nodes {
        id
        name
      }
    }
  }
`

const PartyRoleCreateTitle = () => {
  return <span>Create Party Role</span>
}

const PartyRoleCreate = props => {
  const [partyRoleTypeId, setPartyRoleTypeId] = React.useState(-1)
  const handlePartyRoleTypeChange = event =>
    setPartyRoleTypeId(event.target.value)

  const {forPartyId: forPartyIdString} = parse(props.location.search)
  const forPartyId = forPartyIdString ? parseInt(forPartyIdString, 10) : ''
  const redirect = forPartyId ? `/parties/${forPartyId}/show` : 'show'
  const initialValues = {forPartyId}

  const {loading, error, data} = useQuery(GET_PARTY_ROLE_TYPES, {
    variables: {partyId: forPartyId},
  })
  if (loading) return <Loading />
  if (error) return <Error errorMessage={`${error}`} />

  return (
    <Create title={<PartyRoleCreateTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirect}>
        <ReferenceInput
          record={initialValues}
          source="forPartyId"
          label="Party"
          reference="parties"
          disabled>
          <SelectInput optionText="fullName" />
        </ReferenceInput>
        <SelectInput
          source="partyRoleTypeId"
          label="Type"
          choices={data.partyRoleTypeForParty.nodes}
          onChange={handlePartyRoleTypeChange}
          validate={required()}
        />
        {partyRoleTypeId === typeWorkerId ? (
          <TextInput source="userName" label="Username" validate={required()} />
        ) : null}
        {partyRoleTypeId === typeDirectCustomerId ||
        partyRoleTypeId === typeIndirectCustomerId ? (
          <NumberInput source="hubSpotId" label="Hub Spot Id" />
        ) : null}
      </SimpleForm>
    </Create>
  )
}

export default PartyRoleCreate
