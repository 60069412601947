import React from 'react'
import {
  SimpleForm,
  ReferenceInput,
  required,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
} from 'react-admin'
import {Create} from '../../components/'
import {parse} from 'query-string'

const DeploymentItemWatchTitle = () => {
  return <span>Create Deployment Item Watch</span>
}

const DeploymentItemWatchCreate = props => {
  const {partyRoleId: partyRoleIdString} = parse(props.location.search)
  const partyRoleId = partyRoleIdString ? parseInt(partyRoleIdString, 10) : ''
  const redirectUrl = partyRoleId
    ? `/partyRoles/${partyRoleId}/show/watchItems`
    : false
  const initialValues = {partyRoleId}
  return (
    <Create title={<DeploymentItemWatchTitle />} {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="expandedPartyRoles"
          source="partyRoleId"
          label="Party Role"
          disabled>
          <SelectInput optionText="fullName" />
        </ReferenceInput>
        <ReferenceInput
          reference="deploymentPartyRoleDeploymentItems"
          source="deploymentItemId"
          filter={{watched: false, partyRoleId: partyRoleId}}
          filterToQuery={searchText => ({
            serialNumber: searchText,
          })}
          label="Deployment Item"
          validate={required()}>
          <AutocompleteInput optionText="serialNumber" />
        </ReferenceInput>
        <BooleanInput label="Flagged" source="flagged" defaultValue={false} />
      </SimpleForm>
    </Create>
  )
}

export default DeploymentItemWatchCreate
