import React from 'react'
import {Datagrid, TextField} from 'react-admin'
import {List} from '../../components'

const TestProcedureList = props => (
  <List
    title="All Test Procedures"
    bulkActionButtons={false}
    sort={{field: 'id', order: 'ASC'}}
    {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Name" />
      <TextField source="description" label="Description" />
    </Datagrid>
  </List>
)

export default TestProcedureList
