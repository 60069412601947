import PersonIcon from '@material-ui/icons/Person'
import PartyTypeShow from './party-type-show'

const resource = {
  options: {label: 'Party Types'},
  show: PartyTypeShow,
  icon: PersonIcon,
}

const supplier = () => resource

export default supplier
