import React from 'react'
import {
  TextField,
  ReferenceField,
  SimpleShowLayout,
  EditButton,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {InventoryItemConfigurationParameterTitle} from './inventory-item-configuration-parameter-lib'

const InventoryItemConfigurationParameterShowActions = ({
  basePath,
  data,
  permissions,
}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const InventoryItemConfigurationParameterShowLayout = props => (
  <SimpleShowLayout>
    <TextField source="id" label="Id" />
    <ReferenceField
      reference="inventoryItems"
      source="inventoryItemId"
      label="Item"
      link="show">
      <TextField source="serialNumber" />
    </ReferenceField>
    <ReferenceField
      reference="inventoryItemConfigurationParameterTypes"
      source="configurationParameterTypeId"
      label="Type"
      link={false}>
      <TextField source="name" />
    </ReferenceField>
    <TextField source="value" label="Value" />
  </SimpleShowLayout>
)

const InventoryItemConfigurationParameterShow = ({permissions, ...rest}) => (
  <Show
    title={<InventoryItemConfigurationParameterTitle />}
    actions={
      <InventoryItemConfigurationParameterShowActions
        permissions={permissions}
        {...rest}
      />
    }
    {...rest}>
    <InventoryItemConfigurationParameterShowLayout permissions={permissions} />
  </Show>
)

export default InventoryItemConfigurationParameterShow
