import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import SupplierPartList from './supplier-part-list'
import SupplierPartShow from './supplier-part-show'
import SupplierPartEdit from './supplier-part-edit'
import SupplierPartCreate from './supplier-part-create'

const resource = {
  options: {label: 'Supplier Parts'},
  create: SupplierPartCreate,
  edit: SupplierPartEdit,
  list: SupplierPartList,
  show: SupplierPartShow,
  icon: LocalShippingIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('SupplierPart') ? SupplierPartCreate : null,
  }
}

export default supplier
