const partyRoleTypeIdDeploymentCoordinator = 16
const partyRoleTypeIdDeploymentOperator = 17

const DeploymentPartyRoleTitle = ({record}) => {
  return <span>Deployment Party Role: {record ? `${record.id}` : ''}</span>
}

export {
  DeploymentPartyRoleTitle,
  partyRoleTypeIdDeploymentCoordinator,
  partyRoleTypeIdDeploymentOperator,
}
