import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import TestProcedurePartCoverageCreate from './test-procedure-part-coverage-create'
import TestProcedurePartCoverageEdit from './test-procedure-part-coverage-edit'
import TestProcedurePartCoverageShow from './test-procedure-part-coverage-show'

const resource = {
  options: {label: 'Test Procedure Part Coverages'},
  create: TestProcedurePartCoverageCreate,
  edit: TestProcedurePartCoverageEdit,
  show: TestProcedurePartCoverageShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('TestProcedurePartCoverage')
      ? TestProcedurePartCoverageCreate
      : null,
  }
}

export default supplier
