import React from 'react'
import {
  useTelemetryProvider,
  telemetryTypes,
  TimeRange,
} from './telemetry-provider'

const channelNames = ['current', 'voltage', 'soc', 'temperature']

const channelConfig = {
  current: {
    name: 'current',
    unit: 'A',
    label: 'Current',
    format: ',.1f',
    telemetryType: telemetryTypes.current,
    show: true,
    color: '#cc0066',
  },
  voltage: {
    name: 'voltage',
    unit: 'V',
    label: 'Voltage',
    format: ',.2f',
    telemetryType: telemetryTypes.voltage,
    show: true,
    color: '#00387b',
  },
  soc: {
    name: 'soc',
    unit: '%',
    label: 'SOC',
    format: 'd',
    telemetryType: telemetryTypes.soc,
    show: true,
    color: '#9c27b0',
  },
  temperature: {
    name: 'temperature',
    unit: '°C',
    label: 'Temperature',
    format: ',.1f',
    telemetryType: telemetryTypes.temperature,
    show: true,
    color: '#e25303',
  },
}

function createSeries(rows) {
  const series = [[], []]
  // comes ordered by time DESC so iterate backwards
  for (let i = rows.length - 1; i >= 0; i--) {
    series[0].push(new Date(rows[i].t).getTime())
    series[1].push(parseFloat(rows[i].v))
  }
  return series
}

function createChannel(name, rows) {
  const series = createSeries(rows)
  return {
    ...channelConfig[name],
    empty: rows.length === 0,
    series,
  }
}

function useChannels({serialNumber}) {
  const client = useTelemetryProvider()
  const [channels, setChannels] = React.useState({})
  const [timeRange, setTimeRange] = React.useState(() => new TimeRange())
  const [autoRefresh, setAutoRefresh] = React.useState(0)
  const timer = React.useRef(null)

  const loading =
    channelNames.filter(name => channels[name]).length < channelNames.length

  const empty =
    !loading &&
    channelNames
      .filter(name => channels[name])
      .filter(name => !channels[name].empty).length === 0

  const updateChannel = React.useCallback((name, rows) => {
    const ch = createChannel(name, rows)
    setChannels(prev => {
      if (prev[name]) {
        ch.show = prev[name].show
      }
      return {...prev, [name]: ch}
    })
  }, [])

  const fetchChannel = React.useCallback(
    name => {
      client
        .getTelemetry(
          serialNumber,
          channelConfig[name].telemetryType,
          timeRange,
        )
        .then(rows => updateChannel(name, rows))
    },
    [client, serialNumber, updateChannel, timeRange],
  )

  const fetchAllChannels = React.useCallback(() => {
    channelNames.forEach(fetchChannel)
  }, [fetchChannel])

  const toggleShow = React.useCallback(name => {
    setChannels(prev => {
      const ch = prev[name]
      return {...prev, [name]: {...ch, show: !ch.show}}
    })
  }, [])

  React.useEffect(() => {
    if (client && serialNumber) {
      fetchAllChannels()
    }
  }, [client, serialNumber, fetchAllChannels])

  React.useEffect(() => {
    if (client && serialNumber && autoRefresh > 0) {
      timer.current = setInterval(fetchAllChannels, autoRefresh)
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
        timer.current = null
      }
    }
  }, [client, serialNumber, fetchAllChannels, autoRefresh])

  return {
    loading,
    empty,
    toggleShow,
    channels,
    timeRange,
    setTimeRange,
    autoRefresh,
    setAutoRefresh,
    fetchAllChannels,
    channelNames,
  }
}

export {channelNames, channelConfig, useChannels}
