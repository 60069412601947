import React from 'react'
import {
  TextField,
  SimpleShowLayout,
  ReferenceField,
  EditButton,
  FunctionField,
} from 'react-admin'
import {Show, ShowToolbar} from '../../components'
import {fullName} from '../part/part-lib'
import {PartCorrelationIdTypeTitle} from './part-correlation-id-type-lib'

const PartCorrelationIdTypeShowActions = ({basePath, data, permissions}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
  </ShowToolbar>
)

const PartCorrelationIdTypeShow = ({permissions, ...rest}) => (
  <Show
    title={<PartCorrelationIdTypeTitle />}
    actions={
      <PartCorrelationIdTypeShowActions permissions={permissions} {...rest} />
    }
    {...rest}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <ReferenceField
        label="Part"
        source="partId"
        reference="parts"
        link="show">
        <FunctionField render={fullName} />
      </ReferenceField>
      <ReferenceField
        label="External Id Type"
        source="correlationIdTypeId"
        reference="correlationIdTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export default PartCorrelationIdTypeShow
