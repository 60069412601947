import React from 'react'
import {MenuItem, makeStyles} from '@material-ui/core'
import {useAuth0} from '@auth0/auth0-react'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  icon: {minWidth: theme.spacing(5)},
}))

const LogoutMenuItem = React.forwardRef(function Logout(props, ref) {
  const {className, ...rest} = props
  const classes = useStyles()
  const {logout} = useAuth0()
  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    })
  }
  return (
    <MenuItem
      {...rest}
      className={classnames('logout', classes.menuItem, className)}
      onClick={handleLogout}
      ref={ref}>
      <ExitIcon className={classes.icon} />
      {'Logout'}
    </MenuItem>
  )
})

export default LogoutMenuItem
