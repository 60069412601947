import React from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  required,
} from 'react-admin'
import {Create} from '../../components'
import {parse} from 'query-string'
import {partyRoleTypeIdDeploymentOperator} from './deployment-party-role-lib'

const DeploymentCoordinatorTitle = () => {
  return <span>Create Deployment Coordinator</span>
}

const DeploymentOperatorTitle = () => {
  return <span>Create Deployment Operator</span>
}

const DeploymentPartyRoleCreate = props => {
  const {
    deploymentId: deploymentIdString,
    partyRoleTypeId: partyRoleTypeIdString,
  } = parse(props.location.search)
  const deploymentId = deploymentIdString
    ? parseInt(deploymentIdString, 10)
    : ''
  const partyRoleTypeId = partyRoleTypeIdString
    ? parseInt(partyRoleTypeIdString, 10)
    : ''
  const redirectUrl = deploymentId
    ? `/deployments/${deploymentId}/show/deploymentPartyRoles`
    : false
  const today = new Date()
  const initialValues = {fromDate: today, deploymentId}
  return (
    <Create
      title={
        partyRoleTypeId === partyRoleTypeIdDeploymentOperator ? (
          <DeploymentOperatorTitle />
        ) : (
          <DeploymentCoordinatorTitle />
        )
      }
      {...props}>
      <SimpleForm initialValues={initialValues} redirect={redirectUrl}>
        <ReferenceInput
          reference="deployments"
          source="deploymentId"
          label="Deployment"
          filterToQuery={searchText => ({name: searchText})}
          validate={required()}
          disabled>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="expandedPartyRoles"
          source="partyRoleId"
          label="Party Role"
          filter={{partyRoleTypeId: partyRoleTypeId}}
          filterToQuery={searchText => ({fullName: searchText})}
          validate={required()}>
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <DateTimeInput
          source="fromDate"
          label="From Date"
          defaultValue={new Date()}
          validate={required()}
        />
        <DateTimeInput source="toDate" label="To Date" />
        <TextInput source="comment" label="Comment" fullWidth={true} />
      </SimpleForm>
    </Create>
  )
}

export default DeploymentPartyRoleCreate
