import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {partyRoleTypeIdDeploymentOperator} from './deployment-party-role-lib'

export const AddDeploymentOperatorButton = props => {
  const {record} = props
  return (
    <Button
      type="button"
      variant="contained"
      size="medium"
      component={Link}
      to={`/deploymentPartyRoles/create?deploymentId=${record.id}&partyRoleTypeId=${partyRoleTypeIdDeploymentOperator}`}
      label="Add operator"
      title="Add operator">
      <AddCircleIcon />
    </Button>
  )
}

export default AddDeploymentOperatorButton
