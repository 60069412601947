import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import {List} from '../../components'

const PartFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by Part Number" source="partNumber" alwaysOn />
    <TextInput label="Search by Revision" source="revision" />
    <TextInput label="Search by Description" source="description" />
    <ReferenceInput
      source="partTypeId"
      label="Search by Type"
      reference="partTypes">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const PartList = props => (
  <List
    title="All Parts"
    filters={<PartFilter />}
    bulkActionButtons={false}
    sort={{field: 'partNumber', order: 'ASC'}}
    {...props}>
    <Datagrid rowClick="show">
      <TextField source="partNumber" label="Part Number" />
      <TextField source="revision" label="Revision" />
      <TextField source="description" label="Description" />
      <ReferenceField
        source="partTypeId"
        label="Type"
        reference="partTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default PartList
