import React from 'react'
import {Button} from 'react-admin'
import {Link} from 'react-router-dom'
import SyncIcon from '@material-ui/icons/Sync'

export const ReplaceInventoryItemConfigurationButton = props => {
  const {record} = props
  return !record.replacementInventoryItemId && !record.toDate ? (
    <Button
      component={Link}
      type="button"
      to={`/inventoryItemConfigurations/create?usedInInventoryItemId=${record.usedInInventoryItemId}&replacedInventoryItemConfigurationId=${record.id}`}
      label="Replace"
      title="Replace config item">
      <SyncIcon />
    </Button>
  ) : null
}

export default ReplaceInventoryItemConfigurationButton
