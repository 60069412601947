const typeWorkerId = 5
const typeSupplierId = 11
const typeConsigneeId = 15
const typeDeploymentCoordinatorId = 16
const typeDeploymentOperatorId = 17
const typeDirectCustomerId = 13
const typeIndirectCustomerId = 18

function isWorker(record) {
  return record && record.partyRoleTypeId === typeWorkerId
}

function isSupplier(record) {
  return record && record.partyRoleTypeId === typeSupplierId
}

function isConsignee(record) {
  return record && record.partyRoleTypeId === typeConsigneeId
}

function isDeploymentPartyRole(record) {
  return (
    record &&
    (record.partyRoleTypeId === typeDeploymentOperatorId ||
      record.partyRoleTypeId === typeDeploymentCoordinatorId)
  )
}

function isCustomer(record) {
  return (
    record &&
    (record.partyRoleTypeId === typeDirectCustomerId ||
      record.partyRoleTypeId === typeIndirectCustomerId)
  )
}

const PartyRoleTitle = ({record}) => {
  return <span>Party Role: {record ? `${record.id}` : ''}</span>
}

export {
  typeWorkerId,
  typeConsigneeId,
  typeSupplierId,
  typeDirectCustomerId,
  typeIndirectCustomerId,
  isSupplier,
  isWorker,
  isDeploymentPartyRole,
  isConsignee,
  PartyRoleTitle,
  isCustomer,
}
