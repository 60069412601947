import React from 'react'
import {TextField, SimpleShowLayout} from 'react-admin'
import {Show} from '../../components'

const UnitOfMeasureTitle = ({record}) => {
  return <span>Unit Of Measure: {record ? `${record.name}` : ''}</span>
}

const UnitOfMeasureShow = props => (
  <Show title={<UnitOfMeasureTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
      <TextField source="symbol" label="Symbol" />
      <TextField source="dimension" label="Dimension" />
    </SimpleShowLayout>
  </Show>
)

export default UnitOfMeasureShow
