import AirportShuttleIcon from '@material-ui/icons/AirportShuttle'
import DeploymentCreate from './deployment-create'
import DeploymentEdit from './deployment-edit'
import DeploymentList from './deployment-list'
import DeploymentShow from './deployment-show'

const resource = {
  options: {label: 'Deployments'},
  create: DeploymentCreate,
  edit: DeploymentEdit,
  list: DeploymentList,
  show: DeploymentShow,
  icon: AirportShuttleIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('Deployment') ? DeploymentCreate : null,
  }
}

export default supplier
