import React, {createElement} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {ComponentPropType} from 'ra-core'
import {
  Error as DefaultError,
  Notification as DefaultNotification,
} from 'react-admin'
import AppBar from './app-bar'
import Sidebar from './sidebar'
import {withRouter} from 'react-router-dom'
import {SidebarToggleProvider} from './sidebar-toggle-provider'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    zIndex: 1,
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}))

function LayoutView(props) {
  const {
    children,
    ErrorComponent,
    hasError,
    error,
    title,
    errorInfo,
    notification,
    ...rest
  } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <SidebarToggleProvider initialSidebarOpen={true}>
        <AppBar {...rest} />
        <Sidebar {...rest} />
      </SidebarToggleProvider>
      <main id="main-content" className={classes.content}>
        <div className={classes.toolbar} />
        {hasError ? (
          <ErrorComponent error={error} errorInfo={errorInfo} title={title} />
        ) : (
          children
        )}
      </main>
      {createElement(notification)}
    </div>
  )
}

class Layout extends React.Component {
  state = {hasError: false, error: null, errorInfo: null}

  constructor(props) {
    super(props)
    const {history} = props
    history.listen(() => {
      if (this.state['hasError']) {
        this.setState({
          hasError: false,
        })
      }
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({hasError: true, error, errorInfo})
  }

  render() {
    const {
      children,
      title,
      error: ErrorComponent,
      notification,
      ...rest
    } = this.props
    const {hasError, error, errorInfo} = this.state
    return (
      <LayoutView
        children={children}
        title={title}
        ErrorComponent={ErrorComponent}
        notification={notification}
        hasError={hasError}
        error={error}
        errorInfo={errorInfo}
        {...rest}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  error: ComponentPropType,
  history: PropTypes.object.isRequired,
  notification: ComponentPropType,
  title: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  error: DefaultError,
  notification: DefaultNotification,
}

export default withRouter(Layout)
