import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PartyRoleList from './party-role-list'
import PartyRoleEdit from './party-role-edit'
import PartyRoleShow from './party-role-show'
import PartyRoleCreate from './party-role-create'

const resource = {
  options: {label: 'Party Roles'},
  list: PartyRoleList,
  create: PartyRoleCreate,
  edit: PartyRoleEdit,
  show: PartyRoleShow,
  icon: PersonAddIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('PartyRole') ? PartyRoleCreate : null,
  }
}

export default supplier
