import React from 'react'
import {PieChart, Pie, Cell, Legend, Tooltip, Label} from 'recharts'
import {useListContext, Loading} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import {NoData} from '../lib'

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography.h5,
    fill: theme.palette.text.secondary,
  },
}))

const COLORS = ['#e25303', '#ef7500', '#f58600', '#fa9600', '#ffa600']

const PartInventoryItemSummaryChart = props => {
  const {data, ids, loading} = useListContext(props)
  const classes = useStyles()
  const pieData = ids.map(id => ({
    name: data[id].inventoryItemStatusTypeName,
    value: parseInt(data[id].count, 10),
  }))
  const pieTotal = pieData.reduce((total, item) => total + item.value, 0)
  return loading ? (
    <Loading />
  ) : pieData.length > 0 ? (
    <PieChart width={480} height={240}>
      <Pie
        data={pieData}
        innerRadius={40}
        outerRadius={80}
        nameKey="name"
        dataKey="value"
        label>
        {ids.map((id, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label value={pieTotal} position="center" className={classes.label} />
      </Pie>
      <Tooltip />
      <Legend layout="horizontal" align="center" />
    </PieChart>
  ) : (
    <NoData />
  )
}

export default PartInventoryItemSummaryChart
