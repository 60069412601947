import React from 'react'
import {TextField, SimpleShowLayout} from 'react-admin'
import {Show} from '../../components'

const InventoryItemConfigurationTypeTitle = ({record}) => {
  return (
    <span>
      Inventory Item Configuration Type: {record ? `${record.name}` : ''}
    </span>
  )
}

const InventoryItemConfigurationTypeShow = props => (
  <Show title={<InventoryItemConfigurationTypeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Id" />
      <TextField source="name" label="Name" />
    </SimpleShowLayout>
  </Show>
)

export default InventoryItemConfigurationTypeShow
