import React from 'react'
import {Card, Avatar} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/BatteryCharging90'
import LoginButton from './login-button'

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 300,
    marginTop: '16em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
  },
}))

function Login() {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <div className={classes.avatar}>
        <Avatar className={classes.avatar}>
          <LockIcon />
        </Avatar>
      </div>
      <LoginButton />
    </Card>
  )
}

export default Login
