import * as React from 'react'
import {cloneElement} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import {Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
    padding: theme.spacing(2),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const Title = ({className, record, title, ...rest}) => {
  const classes = useStyles()

  const titleElement =
    typeof title === 'string' ? (
      <span {...rest}>{title}</span>
    ) : (
      cloneElement(title, {
        record,
        ...rest,
      })
    )

  return (
    <Typography
      variant="h6"
      color="inherit"
      className={clsx(classes.title, className)}>
      {titleElement}
    </Typography>
  )
}

const TitlePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.element])

Title.propTypes = {
  className: PropTypes.string,
  record: PropTypes.any,
  title: TitlePropType,
}

const TitleForRecord = ({className, record, title, ...rest}) =>
  record ? (
    <Title title={title} record={record} className={className} {...rest} />
  ) : null

TitleForRecord.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  title: TitlePropType,
}

export {Title, TitleForRecord, TitlePropType}
