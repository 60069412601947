import React from 'react'
import {
  Filter,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  TextInput,
  TopToolbar,
  ExportButton,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'
import {withStyles} from '@material-ui/core/styles'

const ProductInventoryItemListActions = ({permissions, filters}) => (
  <TopToolbar>
    {cloneElement(filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const ProductInventoryItemFilter = withStyles({productId: {minWidth: 170}})(
  ({classes, ...props}) => (
    <Filter {...props}>
      <ReferenceArrayInput
        source="productId"
        label="Search by Product"
        reference="products"
        className={classes.productId}
        alwaysOn>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput
        label="Search by Serial Number"
        source="serialNumber"
        alwaysOn
      />
      <TextInput label="Search by Part Number" source="partNumber" />
      <TextInput label="Search by Revision" source="partRevision" />
      <ReferenceInput
        source="releaseId"
        label="Search by Release"
        reference="releases">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="inventoryItemStatusTypeId"
        label="Search by Status"
        reference="inventoryItemStatusTypes">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  ),
)

const ProductInventoryItemList = ({permissions, ...rest}) => (
  <List
    title="All Product Inventory Items"
    filters={<ProductInventoryItemFilter />}
    actions={
      <ProductInventoryItemListActions permissions={permissions} {...rest} />
    }
    sort={{field: 'serialNumber', order: 'ASC'}}
    bulkActionButtons={false}
    {...rest}>
    <Datagrid rowClick={(id, basePath, record) => `/inventoryItems/${id}/show`}>
      <TextField source="serialNumber" label="Serial Number" />
      <TextField source="partNumber" label="Part Number" />
      <TextField source="partRevision" label="Revision" />
      <ReferenceField
        source="releaseId"
        label="Release"
        reference="releases"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="inventoryItemStatusTypeId"
        label="Status"
        reference="inventoryItemStatusTypes"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="pastDeploymentId"
        label="Past Deployment"
        reference="deployments"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="deploymentId"
        label="Current Deployment"
        reference="deployments"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="futureDeploymentId"
        label="Future Deployment"
        reference="deployments"
        link="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default ProductInventoryItemList
