import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import FacilityTypeShow from './facility-type-show'

const resource = {
  options: {label: 'Facility Types'},
  show: FacilityTypeShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
