import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import TestProcedureCreate from './test-procedure-create'
import TestProcedureEdit from './test-procedure-edit'
import TestProcedureList from './test-procedure-list'
import TestProcedureShow from './test-procedure-show'

const resource = {
  options: {label: 'Test Procedures'},
  list: TestProcedureList,
  show: TestProcedureShow,
  create: TestProcedureCreate,
  edit: TestProcedureEdit,
  icon: DeveloperBoardIcon,
}

const supplier = permissions => {
  return {
    ...resource,
    create: permissions.canCreate('TestProcedure') ? TestProcedureCreate : null,
  }
}

export default supplier
