import React from 'react'
import {
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  Pagination,
} from 'react-admin'
import {Show, DateTimeField} from '../../components'

const InventoryItemTestExecutionTitle = ({record}) => {
  return (
    <span>Inventory Item Test Execution: {record ? `${record.id}` : ''}</span>
  )
}

const InventoryItemTestExecutionShow = props => (
  <Show title={<InventoryItemTestExecutionTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Detail">
        <TextField source="id" label="Id" />
        <ReferenceField
          reference="testProcedures"
          source="testProcedureId"
          label="Test Procedure"
          link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="inventoryItems"
          source="inventoryItemId"
          label="Tested Item"
          link="show">
          <TextField source="serialNumber" />
        </ReferenceField>
        <ReferenceField
          reference="workEfforts"
          source="workEffortId"
          label="Work Effort"
          link="show">
          <DateTimeField label="Started" source="startedAt" />
        </ReferenceField>
        <DateTimeField label="Executed At" source="executedAt" />
        <BooleanField label="Passed" source="passed" />
      </Tab>
      <Tab label="Test Observations" path="testObservations">
        <ReferenceManyField
          pagination={<Pagination />}
          perPage={10}
          label="Test Observations"
          reference="testObservations"
          sort={{field: 'id', order: 'ASC'}}
          target="inventoryItemTestExecutionId">
          <Datagrid>
            <ReferenceField
              reference="testVariables"
              source="testVariableId"
              label="Test Variable"
              link="show">
              <TextField source="name" />
            </ReferenceField>
            <NumberField label="Value" source="value" />
            <BooleanField label="Passed" source="passed" />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default InventoryItemTestExecutionShow
