import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import UnitOfMeasureShow from './unit-of-measure-show'

const resource = {
  options: {label: 'Unit Of Measures'},
  show: UnitOfMeasureShow,
  icon: DeveloperBoardIcon,
}

const supplier = () => resource

export default supplier
