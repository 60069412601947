import React from 'react'
import {
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  Datagrid,
  TopToolbar,
  ExportButton,
  TextInput,
} from 'react-admin'
import {cloneElement} from 'react'
import {List} from '../../components'

const InventoryItemCorrelationIdListActions = props => (
  <TopToolbar>
    {cloneElement(props.filters, {context: 'button'})}
    <ExportButton />
  </TopToolbar>
)

const InventoryItemCorrelationIdFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="correlationIdTypeId"
      label="Search by Type"
      reference="correlationIdTypes"
      alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Search by Id" source="correlationId" alwaysOn />
  </Filter>
)

const InventoryItemCorrelationIdList = props => (
  <List
    title="All External Ids"
    filters={<InventoryItemCorrelationIdFilter />}
    bulkActionButtons={false}
    actions={<InventoryItemCorrelationIdListActions />}
    {...props}>
    <Datagrid rowClick="show">
      <ReferenceField
        reference="inventoryItems"
        source="inventoryItemId"
        label="Inventory Item"
        link="show">
        <TextField source="serialNumber" />
      </ReferenceField>
      <ReferenceField
        label="External Id Type"
        source="correlationIdTypeId"
        reference="correlationIdTypes"
        link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="correlationId" label="External Id" />
    </Datagrid>
  </List>
)

export default InventoryItemCorrelationIdList
