import StoreIcon from '@material-ui/icons/Store'
import ProductInventoryItemDeploymentStatusList from './product-inventory-item-deployment-status-list'

const resource = {
  options: {label: 'Product Inventory Item Deployment Statuses'},
  list: ProductInventoryItemDeploymentStatusList,
  icon: StoreIcon,
}

const supplier = permissions => resource

export default supplier
