import React from 'react'
import {
  TextField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  DateField,
  EditButton,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  Pagination,
} from 'react-admin'
import {Show, ShowToolbar, DateTimeField} from '../../components'
import {DeploymentTitle} from './deployment-lib'
import {AddDeploymentItemButton} from '../deployment-item/add-deployment-item-button'
import {PartListField} from '../part/part-lib'
import {withRouter} from 'react-router'
import AddDeploymentCoordinatorButton from '../deployment-party-role/add-deployment-coordinator-button'
import AddDeploymentOperatorButton from '../deployment-party-role/add-deployment-operator-button'

const DeploymentActions = ({permissions, basePath, data, location}) => (
  <ShowToolbar>
    {data && permissions.canEdit(data) && location.pathname.endsWith('show') ? (
      <EditButton
        basePath={basePath}
        record={data}
        type="button"
        variant="contained"
        size="medium"
        title="Edit"
      />
    ) : null}
    {data &&
    permissions.canCreate('DeploymentItem') &&
    location.pathname.endsWith('items') ? (
      <AddDeploymentItemButton
        record={data}
        type="button"
        variant="contained"
        size="medium"
      />
    ) : null}
    {data &&
    permissions.canCreate('DeploymentPartyRole') &&
    location.pathname.endsWith('Roles')
      ? [
          <AddDeploymentCoordinatorButton
            key={'addCoordinator'}
            record={data}
            type="button"
            variant="contained"
            size="medium"
          />,
          <AddDeploymentOperatorButton
            key={'addOperator'}
            record={data}
            type="button"
            variant="contained"
            size="medium"
          />,
        ]
      : null}
  </ShowToolbar>
)

const DeploymentShow = props => {
  const {permissions, ...rest} = props
  return (
    <Show
      title={<DeploymentTitle />}
      actions={<DeploymentActions permissions={permissions} {...rest} />}
      {...props}>
      <TabbedShowLayout>
        <Tab label="Detail">
          <TextField source="id" label="Id" />
          <TextField source="name" label="Name" />
          <ReferenceField
            label="Consignee"
            source="partyRoleId"
            reference="expandedPartyRoles"
            link={record => `/partyRoles/${record.partyRoleId}/show`}>
            <TextField source="preferredName" />
          </ReferenceField>
          <DateTimeField label="From Date" source="fromDate" />
          <TextField source="qualifier" label="Qualifier" />
          <DateTimeField label="To Date" source="toDate" />
          <TextField label="Comment" source="comment" />
        </Tab>
        <Tab label="Items" path="items">
          <ReferenceManyField
            label="Deployment Items"
            reference="deploymentItems"
            pagination={<Pagination />}
            perPage={10}
            sort={{field: 'fromDate', order: 'DESC'}}
            target="deploymentId">
            <Datagrid>
              <ReferenceField
                reference="inventoryItems"
                source="inventoryItemId"
                label="Item"
                link="show">
                <TextField source="serialNumber" />
              </ReferenceField>
              <ReferenceField
                reference="inventoryItems"
                source="inventoryItemId"
                label="Part"
                link={false}>
                <PartListField link={false} />
              </ReferenceField>
              <TextField label="Alias" source="alias" />
              <DateField label="From Date" source="fromDate" />
              <DateField label="To Date" source="toDate" />
              <TextField label="Comment" source="comment" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Roles" path="deploymentPartyRoles">
          <ReferenceManyField
            label="Deployment Party Roles"
            reference="expandedDeploymentPartyRoles"
            pagination={<Pagination />}
            perPage={10}
            sort={{field: 'fromDate', order: 'DESC'}}
            target="deploymentId">
            <Datagrid>
              <ReferenceField
                reference="expandedPartyRoles"
                source="partyRoleId"
                label="Party Role"
                link={record => `/partyRoles/${record.partyRoleId}/show`}>
                <TextField source="fullName" />
              </ReferenceField>
              <TextField label="Role Type" source="partyRoleTypeName" />
              <DateField label="From Date" source="fromDate" />
              <DateField label="To Date" source="toDate" />
              <TextField label="Comment" source="comment" />
              <ShowButton
                basePath="/deploymentPartyRoles"
                resource="deploymentPartyRoles"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default withRouter(DeploymentShow)
